import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Copyright from '../../../components/copyright/copyright'
import PageHeading from '../../../components/page-heading/PageHeading'

export default function SubmitPhoto() {
    const history = useHistory()
    var selfie: any = localStorage.getItem('selfieImage')

    const handleRouting = (route: string) => {
        history.push(`/b/lays/${route}`)
    }

    const handleRoutingHome = () => {
        history.push('/b/lays')
    }

    return (
        <div className="selfie-after-page complete">
            <div className='page-wrapper'>
                <div className="overlay" />

                <img
                    alt="frame"
                    src={require('../../../assets/images/Hex_Frame_001 3.png')}
                    className='hex-border' />

                <div className="hexagonal-div flex-centered center-img mt-15 mb-55">

                    <img src={selfie} alt="Pass the Ball Challenge" className="center-img" style={{ maxHeight: '450px' }} />
                </div>

                <PageHeading
                    title="Looking good!"
                    subtitle="Now share with friends for more chances to win" />

                <div className='text-center front mt-40  flex-centered'>
                    <button
                        className="btn btn-gold w-100 fw-bold"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleRouting('find?object_definition_id=vGO5CRHnqK')}>
                        <p className="fs-17 fw-bold">PASS THE BALL</p>
                    </button>
                    <br />
                    <button
                        className="btn btn-outline-gold w-100 fw-bold"
                        onClick={handleRoutingHome}
                        style={{ cursor: 'pointer' }}>
                        <p className="fs-17 fw-bold">SKIP</p>
                    </button>
                </div>
            </div>
        </div>
    )
}