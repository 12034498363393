import PagesActionTypes from './pages.types'
import axios from 'axios';

export const fetchPagesStart = () => ({
    type: PagesActionTypes.FETCH_PAGES_START
})

export const fetchPagesSuccess = pages => ({
    type: PagesActionTypes.FETCH_PAGES_SUCCESS,
    payload : pages
})

export const fetchPagesFailure = errorMessage => ({
    type: PagesActionTypes.FETCH_PAGES_FAILURE,
    payload : errorMessage
})

export const fetchPagesStartAsync = () => {
    return dispatch => {
        dispatch(fetchPagesStart())  //start fetching the data
        axios.get('https://cms.vatom.com/wp-json/wp/v2/pages/')
        .then(res => {
            dispatch(fetchPagesSuccess(res.data)) //once is done, update the state via this action
        })
        .catch(err => dispatch(fetchPagesFailure(err.message))) //if there's an error update the state with error message
    }
}