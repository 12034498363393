import { useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router';
import Webcam from "react-webcam";
import { getAccessToken } from '../../../../auth';
import Loader from '../../../../components/Loader';
import './webcam.styles.scss';
import { useBusiness } from '../../../../App';

const videoConstraints = {
    width: 400,
    height: 400,
    facingMode: "user"
};

interface IndexLayoutProps {
    className: string;
}

export const WebcamCapture = (props: IndexLayoutProps) => {
    const history = useHistory()
    const [result, setResult] = useState('');
    const [image, setImage] = useState('')
    const [displayBtns, setDisplayBtns] = useState(false)
    const [disableKeep, setDisableKeep] = useState(true)
    const [disableRetake, setDisableRetake] = useState(false)
    const [disableError, setDisableError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [displayTake, setDisplayTake] = useState(true)
    const webcamRef = useRef<any>();
    const accessToken = getAccessToken()
    const { className } = props
    const business = useBusiness()

    const handleRouting = () => {
        setLoading(true);
        setDisplayBtns(false)
        saveImage(business?.id || 'gsndxqUVp1', business?.defaultCampaignId || 'YoyAQjcyoJ', result);
    }

    const capture = useCallback(
        async () => {
            if (!webcamRef.current) return
            setLoading(true)
            const imageSrc = webcamRef.current.getScreenshot();

            setImage(imageSrc);
            var faceBlob = await (await fetch(imageSrc)).blob();
            let host = "https://moderation.api.vatominc.com/";
            localStorage.setItem('selfieImage', imageSrc)
            const moderationCallRes = await fetch(host + 'moderation', {
                headers: new Headers({
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': faceBlob.type
                }),
                method: 'POST',
                body: faceBlob
            });
            const moderationResults = await moderationCallRes.json();
            setResult(moderationResults);

            if (moderationCallRes.status !== 200 || moderationResults.status !== "Ok") {
                setDisplayBtns(false)
                setDisableKeep(true)
                setDisableRetake(false)
                setDisableError(true);
                setDisplayTake(true);
                setImage('');

            } else {
                setDisplayBtns(true)
                setDisableKeep(false);
                setDisableRetake(false);
                setDisableError(false);
            }
            setLoading(false)
        }, [webcamRef]);

    const saveImage = async (businessId: string, campaignId: string, moderationResults: any) => {
        const host = "https://moderation.api.vatominc.com/";

        const moderationImageCallRes = await fetch(host + 'reprocess-image', {
            headers: new Headers({
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': `application/json`
            }),
            method: 'POST',
            body: JSON.stringify({
                businessId,
                campaignId,
                imageName: moderationResults.imageName
            }),
        });
        history.push(`/b/lays/selfie/after`)
    }

    return (
        <div className={`webcam-container webcam-div flex-centered ${className}`}>
            <img
                src={require('../../assets/images/coin_render_frame.png')}
                className='hex-img pos-rel'
                alt="hexagonal frame"
            />

            <div className="hexagonal-div flex-centered">
                {image === ''
                    ? <Webcam
                        audio={false}
                        className={"playinline"}
                        width={370}
                        height={280}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints} />
                    : <img
                        alt=''
                        src={image}
                        className="center-img" />}
            </div>
            <div
                className='text-center invalidPhoto'
                style={{ display: `${disableError ? 'block' : 'none'}` }}>
                <div className="front text-white fs-32 fw-900 fw-bold text-center mb-10 mt-0"><h2>Invalid Photo</h2><p className="front text-white fs-16 fw-400 fw-normal text-center">Please retake the photo</p></div>

            </div>

            <div className="webcam-btns mw-415 mb-10">
                <div
                    className='text-center front pl-20 pr-20 mb-25 '
                    style={{ display: `${displayBtns ? 'flex' : 'none'}`, justifyContent: 'space-between' }}>
                    <button
                        className="btn-inline btn-gold w-100 fw-bold me-10"
                        style={{ cursor: 'pointer' }}
                        disabled={disableKeep}
                        onClick={(e) => handleRouting()}>
                        <p className="fs-17 fw-bold">KEEP</p>
                    </button>
                    <button
                        className="btn-inline btn-outline-gold w-100 fw-bold ms-10"
                        style={{ cursor: 'pointer' }}
                        disabled={disableRetake}
                        onClick={(e) => {
                            e.preventDefault();
                            setImage('');
                            setDisplayBtns(false)
                            setDisableKeep(true)
                            setDisableRetake(true)
                            setDisplayTake(true)
                        }}>
                        <p className="fs-17 fw-bold">RETAKE</p>
                    </button>
                </div>

                <div className='webcam-btn-div'
                    style={{ display: `${displayTake ? 'flex' : 'none'}` }}>
                    <button
                        className="webcam-btn center-img"
                        onClick={(e) => {
                            e.preventDefault();
                            setDisableError(false);
                            setDisplayTake(false);
                            capture();
                        }} />
                </div>
            </div>
            <Loader isLoading={loading} />
        </div>
    );
};