import '../prize.styles.scss'
import { useHistory } from 'react-router-dom';
import PageHeading from '../../../components/page-heading/PageHeading'
import MotionDiv from '../../../components/motion-div/motion-div.component';


const PlayGame = () => {
    const history = useHistory()

    const handleRouting = () => {
        history.push('/b/lays/selfie/before')
    }

    return (
        <div className='landing-page  country-page'>
            <div className='page-wrapper'>
                <div className="overlay"></div>

                <PageHeading
                    title="You're in the game"
                    subtitle="By registering you’ve earned one entry for a chance to WIN amazing swag" />

                <div
                    className="gold-frame-1 front mt-24 mb-24">
                    <p className="text-white entry-text">1 Entry</p>
                </div>

                <p className="text-white front fs-24 text-center mt-1">!</p>

                <div className='text-center front flex-centered nextCountry'>
                    <button
                        className="btn btn-gold w-100 fw-bold"
                        style={{ cursor: 'pointer' }}
                        onClick={handleRouting}>
                        <p className="fs-17 fw-bold">LET'S PLAY</p>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default MotionDiv(PlayGame)