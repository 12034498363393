import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import './swiper-nav-buttons.styles.scss'

const SwiperButtons = ({swiper}) => (
    <div className='swiper-nav-buttons'>
        <button className='swiper-button-prev swiper-nav-button' onClick={() => swiper.slidePrev()}><FontAwesomeIcon icon={faAngleLeft} className="swiper-icon"/></button>
        <button className='swiper-button-next swiper-nav-button' onClick={() => swiper.slideNext()}><FontAwesomeIcon icon={faAngleRight} className="swiper-icon"/></button> 
    </div>
)

export default SwiperButtons