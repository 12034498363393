import { callbackify } from 'util';
import './home.scss'
import { Link } from 'react-router-dom';
import { Route } from 'react-router-dom';
import './../home/home.scss';

const HomePage = () => {

    let faqPage = "/b/deloittemwc/FAQ"
    let walletPage = "deloittemwc/wallet"

    const redirectToDeloitte = () => {
        window.open(
            'https://www2.deloitte.com/us/en/pages/consulting/solutions/enterprise-metaverse-consulting.html?nc=42',
            '_blank'
        );
    }

    const redirectToVirtual = () => {
        window.open(
            'https://spaces.vatom.com/gather/7535414001',
            '_blank'
        );
    }

    return (
        <div className='deloitte-home-page'>
            <div className='help' style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                marginTop: 52

            }}>
                <div className='logo-container'>
                    <div className='image-container'>
                        <img src={require('./../../assets/images/virtual_world.png')} style={{ marginBottom: -5 }} onClick={redirectToVirtual} />
                        <p className='logo-text'> VIRTUAL WORLD</p>
                    </div>

                    <div>
                        <Link to={walletPage}>
                            <img src={require('./../../assets/images/wallet.png')} style={{ marginBottom: -10 }} />
                        </Link>
                        <p className='logo-text'>WALLET</p>
                    </div>
                </div>

                <div className='logo-container'>

                    <div className='image-container'>
                        <Link to={faqPage}>
                            <img src={require('./../../assets/images/faq.png')} style={{ marginBottom: -5 }} />
                        </Link>
                        <p className='logo-text'>FAQ</p>

                    </div>


                    <div className='image-container'>
                        <img src={require('./../../assets/images/website.png')} style={{ marginBottom: 1 }} onClick={redirectToDeloitte} />
                        <p className='logo-text'>WEBSITE</p>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default HomePage;