import './slides.scss'

export default function Slide4() {
  return (
    <img
      src={require('../../assets/images/slide4.png')}
      className="center-img mt-15 mb-15 front"
    />

  )
}
