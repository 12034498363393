import { ErrorMsg } from './ErrorMessage'
import { LabelForm } from './LabelForm'
import { TextField } from "./helpers";
import { fieldPropsInterfaceOverride } from '../types';


export const NumberField = (props: fieldPropsInterfaceOverride) => {
	return (
		<div>
			<LabelForm {...props} />
			<TextField
				type="number"
				name={props.field.name}
				required={props.field.required}
				placeholder={props.field.placeholder}
				autoComplete={props.field.autoComplete}
				min={props.field.min}
				max={props.field.max}
				pattern={props.field.pattern}
				value={props.value ?? ''}
				onChange={(e: any) => props.onChange(e.target.value)}
			/>
			<ErrorMsg {...props} />
		</div>
	)
}
