import './video-page.styles.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

import { useHistory, useLocation } from 'react-router-dom'

import PageHeader from '../../components/page-header/page-header.component';
import MotionDiv from '../../components/motion-div/motion-div.component';

const VideoContainerPage = ({videos}) => {
    const history = useHistory();
    console.log(videos)

    const { pathname } = useLocation()

    return (
        <div className='video-page'>
            <PageHeader title="Video Library"/>
            <div className='page-content'>
            {
                videos.map((element, index) => (
                    <div key={index} className='video-element' style={{backgroundImage: `url("${element.post_thumbnail}")`}} onClick={() => history.push(`${pathname}/${element.slug}`)}>                              
                        <span className={element.acf.title_position}>{element.title}</span>
                        <FontAwesomeIcon icon={faPlay} className="icon"/>
                    </div>
                ))
            }
            </div>
        </div>
    )
}

export default MotionDiv(VideoContainerPage)