import { useHistory } from "react-router";
import { useState } from "react";
import { useShareLink } from "../../../../../hooks/business";
import '../pass-2/pass2.styles.scss'
import MotionDiv from '../../../components/motion-div/motion-div.component';
import { useCampaignUserInfo } from '../../../../../auth'
import Share from "../../../components/share";
import { motion, useAnimation } from "framer-motion"
import Loader from '../../../../../components/Loader';
import { useEffect } from "react";
import { useBusiness } from "../../../../../App";
import Analytics from "../../../../../common/Analytics";

const SecondPass = () => {
	const [loading, setLoading] = useState(true)
	const history = useHistory()

	const business = useBusiness();
	const businessId = business!.id;
	const campaignId = business!.defaultCampaignId;
	const { data: userInfo } = useCampaignUserInfo(businessId, campaignId);

	const handleRouting = () => {
		const wizardState = localStorage.getItem('wizardState');
		if (wizardState && wizardState != " ") {
			if (!userInfo?.pepsi_address?.country || userInfo?.pepsi_address?.country === "United States of America") {
				localStorage.setItem('wizardState', '4');
				history.push('/b/lays/pre-daily-game')
			}
			else {
				localStorage.setItem('wizardState', '5');
				history.push('/b/lays/explore/landing')
			}
		}
		else {
			history.push("/b/lays/");
		}

	};
	const [isopenVideo, setIsOpenVideo] = useState("show-video");
	const controlsbutton = useAnimation()
	const controlsbuttonBall = useAnimation()
	const controlsbuttonGlow = useAnimation()
	const control1 = useAnimation()
	const control2 = useAnimation()
	const control3 = useAnimation()
	const control4 = useAnimation()
	const control5 = useAnimation()
	const control6 = useAnimation()
	const [isActive, setActive] = useState("off");
	const [isActiveBtn, setActiveBtn] = useState("off");
	const [isMoving, setMoving] = useState("off");
	const [pagetitle, setTitle] = useState("Pass the ball forward to a friend");
	const [pagesubtitle, setsubTitle] = useState("false");
	const [showShare, setShowShare] = useState(false);
	const [shared, setShared] = useState(false);
	const [ballShow, setBallShow] = useState(true);
	const shareDataFunction = () => {
		setActive("active");
		setTitle("");
		setsubTitle("false");
	}

	const onBallClick = () => {
		setShared(true);
		setActive("off");
		setActiveBtn("active");

		const timer = setTimeout(() => {
			setTitle("Great pass! Now you are golden");
			setsubTitle("true");
			setBallShow(false);
		}, 5000)
		const shareData: ShareData = {
			text: "Join me in the Pass the Ball Challenge for a chance to WIN amazing swag",
			title: "Join me in the Pass the Ball Challenge",
			url: `${shareUrl}?utm_source=native&utm_medium=share&login=1`,
		};

		if (navigator.canShare?.(shareData)) {
			console.log("canshare");
			try {
				setTitle("")
				navigator.share(shareData);

				Analytics.event(
					"selectShareChannel",
					{
						source: "ObjectMenu",
						medium: "linkNative",
						businessId,
						campaignId
					}
				);

				return;
			}
			catch (err) {

			}
		}
		else {
			setShowShare(true);
		}

	}


	const sequence = async () => {

		controlsbuttonGlow.start({
			scale: 1,
			bottom: "-40px",
			transition: { duration: 1, delay: 0.5, type: "spring", stiffness: 900 }
		})
		controlsbuttonBall.start({
			scale: 0.8,
			bottom: "-20px",

			transition: { duration: 1, delay: 0.5, type: "spring", stiffness: 900 }
		})
		setTimeout(function () {
			control1.start({ bottom: "140px", left: "0px", right: "0px", opacity: 1, transition: { duration: 0.2, delay: 0.4, type: "spring", stiffness: 50 } })
			control2.start({ bottom: "140px", left: "0px", right: "0px", opacity: 1, transition: { duration: 0.4, delay: 0.1, type: "spring", stiffness: 50 } })
			control3.start({ bottom: "140px", left: "0px", right: "0px", opacity: 1, transition: { duration: 0.6, delay: 0.2, type: "spring", stiffness: 50 } })
			control4.start({ bottom: "140px", left: "0px", right: "0px", opacity: 1, transition: { duration: 0.5, delay: 0.3, type: "spring", stiffness: 50 } })
			control5.start({ bottom: "140px", left: "0px", right: "0px", opacity: 1, transition: { duration: 0.7, delay: 0.2, type: "spring", stiffness: 50 } })

		}.bind(this), 300)
		setTimeout(function () { //Start the timer
			control1.start({ opacity: 0, transition: { duration: 0 } })
			control2.start({ opacity: 0, transition: { duration: 0 } })
			control3.start({ opacity: 0, transition: { duration: 0 } })
			control4.start({ opacity: 0, transition: { duration: 0 } })
			control5.start({ opacity: 0, transition: { duration: 0 } })

			controlsbuttonGlow.start({
				scaleY: 5,
				bottom: "600px",
				transition: { duration: 0.6, delay: 0, type: "tween", ease: "anticipate" },
			})
			controlsbuttonBall.start({
				scale: 0.5,
				rotate: [0, 270],
				bottom: "900px",
				transition: { duration: 0.6, delay: 0, type: "tween", ease: "anticipate" },
			})
		}.bind(this), 2000)
		setTimeout(function () { //Start the timer
			controlsbuttonGlow.start({
				scaleY: 1,
				opacity: 0.5,
				bottom: "20px",
				transition: { duration: 0.8, delay: 0, type: "tween", ease: "anticipate" },
			})
			controlsbuttonBall.start({
				scale: 1,
				rotate: [0, 40],
				bottom: "00px",
				transition: { duration: 0.8, delay: 0, type: "tween", ease: "anticipate" },

			})
			control6.start({ opacity: 0, transition: { duration: 1 } });
		}.bind(this), 2403)

	}

	const shareUrl = useShareLink();



	const doShare = () => {
		const sequence0 = async () => {
			await controlsbutton.start({

				scale: 1.2,
				rotate: [0, 270],
				transition: { duration: 0.5 },
			})
			return setTimeout(function () { //Start the timer
				controlsbuttonGlow.start({
					scale: 1.4,
					bottom: "-20px",
					transition: { duration: 0.3, type: "bounce", ease: "anticipate" },
				})
				controlsbutton.start({
					scale: 0,
					rotate: [0, 270],
					transition: { duration: 0.6 },
				})
				sequence();
			}.bind(this), 10)
		}
		sequence0();
	};


	useEffect(() => {
		const timer = setTimeout(() => {
			doShare();
		}, 1000)
		return () => { clearTimeout(timer) }
	}, []);


	if (!shareUrl) return (
		<div className='home-page customheight'>
			<div className='page-wrapper'>
				<Loader isLoading={loading} />
			</div>
		</div>
	)

	return (
		<div className="selfie-before-page-black passwrapper">

			<div className="page-wrapper pt-20 animationWrapper">
				<div className="front text-white fs-32 fw-900 fw-bold text-center mb-10 mt-0 zindex"><h2>{`${pagetitle}`}</h2></div>
				<div className={`front text-white fs-16 fw-900 pl-4 pr-4 text-center mb-20 zindex show-${pagesubtitle}`}><p>You've earned one entry for a chance to WIN amazing swag.<br /><br />You can earn up to 4 entries a day through sharing or playing the daily game.</p></div>

				{ballShow
					? <div> <motion.div
						className={`ball center-img mt-76 mb-96 ${isMoving}`}
						animate={controlsbuttonBall} onClick={() => !shared ? onBallClick() : null}></motion.div>
						<motion.div
							className={`glow center-img mt-76 mb-96 ${isMoving}`}
							animate={controlsbuttonGlow}></motion.div>
						<motion.div
							className={`ball-circle center-img mt-76 mb-96 ${isMoving}`}
							animate={controlsbutton} >
						</motion.div>
						<motion.div className={`glowSmall center-img pos1 ${isMoving}`}
							animate={control1}></motion.div>
						<motion.div className={`glowSmall center-img pos2 ${isMoving}`}
							animate={control2}></motion.div>
						<motion.div className={`glowSmall center-img pos3 ${isMoving}`}
							animate={control3}></motion.div>
						<motion.div className={`glowSmall center-img pos4 ${isMoving}`}
							animate={control4}></motion.div>
						<motion.div className={`glowSmall center-img pos5 ${isMoving}`}
							animate={control5}></motion.div>
						<motion.div animate={control6} onAnimationComplete={shareDataFunction} className={`glowSmall center-img pos1 ${isMoving}`}></motion.div>
					</div>
					: null
				}


				<div className="boxbottom">
					{isActive === "active"
						? <div className="tap-text">Tap the ball to share</div>
						: null
					}
					<div className="text-center front flex-centered">
						<button
							className={`btn btn-gold w-100 fw-bold next-${isActiveBtn}`}
							onClick={handleRouting}
							style={{ cursor: "pointer" }}
						>
							<p className="fs-17 fw-bold">NEXT</p>
						</button>
					</div>
				</div>
				<div className="fadeoverlay"></div>
				<video className={isopenVideo} autoPlay playsInline muted>

					<source src={require('../../../assets/videos/Grass-Kick_with-BG_v02.mp4').default} type="video/mp4" />
					Sorry, your browser doesn't support videos.
				</video>
			</div>


			<Share
				shareUrl={shareUrl}
				isOpen={showShare}
				onClose={() => setShowShare(false)}
			/>

		</div>
	);
}
export default MotionDiv(SecondPass)

