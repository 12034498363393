import './music-cta-module.styles.scss'

import Button from '../button/button.component'

const MusicCtaModule = () =>  (
    <div className='module' key="6">
        <div className='music-cta'>
            <h4>Music</h4>
            <a href="https://www.iheart.com/playlist/wango-tango-playlist-312064750-SSzjTg8eunJsqD6mhyg5e7/" target="_blank" rel="noreferrer">
            <Button className="button full-size">WANGO TANGO PLAYLIST</Button>
            </a>
        </div>
    </div>
)

export default MusicCtaModule