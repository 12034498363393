import './video-page.styles.scss';

import { compose } from "redux";
import { Route } from 'react-router';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { selectVideoCollections } from '../../redux/posts/posts.selectors';

import MotionDiv from '../../components/motion-div/motion-div.component';
import VideoPageContainer from './video-page.container';
import VideoSingleComponent from '../video-single/video-single.component';

const VideosPage = ({videos, match}) => (
    <>
        <Route exact path={`${match.path}`}>
            <VideoPageContainer videos={videos}/>
        </Route>
        <Route path={`${match.path}/:slug`}>
            <VideoSingleComponent videos={videos}/>
        </Route>
    </>
)

const mapStateToProps = createStructuredSelector({   //state = the root reducer
    videos: selectVideoCollections,
})

export default compose(
    connect(mapStateToProps),
    MotionDiv
)(VideosPage)