import App from './App';
import { Provider } from 'react-redux';

import store from './redux/store';

const WangoTango = () => (
  <Provider store={store}> 
    <App />
  </Provider>
)

export default WangoTango

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
