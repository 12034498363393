import './video-single.styles.scss';
import { useParams } from 'react-router';

import PageHeader from '../../components/page-header/page-header.component';
import MotionDiv from '../../components/motion-div/motion-div.component';
// import Button from '../../components/button/button.component';

const VideoSinglePage = ({videos}) => {
    const params = useParams();
    const index = videos.findIndex(object => object.slug === params.slug)

    const videoCollection = videos[index]

    return (
    <div className='video-single-page'>

        <PageHeader title={`${videoCollection.title} ${videoCollection.title === "2022" ? "Video Library" : ""}`}/>

        <div className='page-content'>
        {
        videoCollection.acf.videos.map((element, index) => (
            <div className='video-player-holder'>
                <div className='video-player-title'>
                    <h5>{element.video_title}</h5>
                  {/*   <Button className="button">ADD TO WALLET</Button> */}
                </div>
                <div key={index} className="video-player" dangerouslySetInnerHTML={{__html : `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="${element.url}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Ariana Grande Reveals &amp;#039;Sweetener&amp;#039; Album, &amp;#039;The Light is Coming&amp;#039; Single at KIIS FM&amp;#039;s Wango Tango"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>`
            }} />                              
            </div>

        ))
        }
        </div>

    </div>)
}

export default MotionDiv(VideoSinglePage)