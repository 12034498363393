import { fieldPropsInterfaceOverride } from "../types"


export const LabelForm = (props: fieldPropsInterfaceOverride) => {

  let text = props.title ?? props.field?.title ?? props.field?.name


  if ((props.required ?? props.field.required))
    text += `*`

  // Quirk: Jingle Ball 2022
  if (props.field.name === 'jingleball_content_2') text = ''

  // return <Text renderAs="label">{text}</Text>
  // return <Label label={text} />
  return <label style={{ marginTop: "16px", color: "black", fontSize: "13px", fontFamily: "Inter, sans-serif", marginBottom: "3px" }}>{text}</label>
}
