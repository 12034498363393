import PagesActionTypes from './pages.types';

const INITIAL_STATE = {
    pages: null,
    isFetching : false,
    errorMessage: undefined
};

const pagesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PagesActionTypes.FETCH_PAGES_START:
            return{
                ...state,
                isFetching: true
            }
        case PagesActionTypes.FETCH_PAGES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                pages: action.payload
            }
        case PagesActionTypes.FETCH_PAGES_FAILURE:
            return{
                ...state,
                isFetching: false,
                errorMessage: action.payload
            }
        default:
            return state
    }
};

export default pagesReducer;