//
// Draws a particle on a canvas

export default class Particle {
    lifeStart: number
    lifeDuration: any
    lifeEnd: any
    x: any
    y: any
    scale: any
    velocityX: any
    velocityY: any
    dead: boolean
    color: { red: number; green: number; blue: number }

    constructor(lifetime: any, x: any, y: any, scale: any, velocityX: any, velocityY: any) {

        // Properties
        this.lifeStart = Date.now()
        this.lifeDuration = lifetime
        this.lifeEnd = this.lifeStart + this.lifeDuration
        this.x = x
        this.y = y
        this.scale = scale
        this.velocityX = velocityX
        this.velocityY = velocityY
        this.dead = false
        this.color = {
            red: 1,
            green: 1,
            blue: 1
        }

    }

    draw(ctx: any, delta: any) {

        // Get opacity
        var opacity = Math.max(0, (Date.now() - this.lifeStart) / this.lifeDuration)

        // Get lifetime progress (0.0 - 1.0)
        let age = Math.min(1, (Date.now() - this.lifeStart) / this.lifeDuration)

        // Draw confetti
        ctx.fillStyle = `rgba(${Math.floor(this.color.red * 249)}, ${Math.floor(this.color.green * 185)}, ${Math.floor(this.color.blue * 62)}, ${1 - age})`
        ctx.fillRect(this.x, this.y, 3 * this.scale, 3 * this.scale)


        // Move position
        this.x += this.velocityX * delta
        this.y += this.velocityY * delta

        // Check if dead
        if (age >= 1)
            this.dead = true

    }

}
