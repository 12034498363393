/* eslint-disable default-case */
import { useParams } from "react-router";
import { Redirect, Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectHomePageButtons } from '../../redux/posts/posts.selectors';
import { selectLocation } from '../../redux/locations/locations.selectors';
import MotionDiv from "../../components/motion-div/motion-div.component";
import styled from "styled-components";
import { locationExists, basePath } from '../../utils'

import './game-landing.styles.scss'

const StyledGameLandingPage = styled.div` 
    background-image: ${({background}) => background ? `url("${background.url}")` : ''};
    background-position: ${({backgroundPosition}) => backgroundPosition ? backgroundPosition : 'center'};
`;

const GameLandingPage = ({ landingPage }) => {
    const args = landingPage[0].acf;
    const { page_content, nft_link, content_alignment } = args
    const { alignment, width, height } = content_alignment

    const getContentElement = (element, index) => {
        switch (element.acf_fc_layout) {
            case 'subtitle':
                return (
                    <p key={index} className='subtitle' style={{ color: element.color }}>{element.subtitle}</p>
                );
            case 'title':
                return (
                    <p key={index} className={`title ${element.class}`} style={{ color: element.color }} dangerouslySetInnerHTML={{__html: element.title}}></p>
                );
            case 'button':
                return (
                    <Link key={index} to={nft_link} className={`button ${element.button.class}`}style={{ backgroundColor: element.button.background_color }}>
                        <p className="button-text" style={{ color: element.button.title_color }}>{element.button.title}</p>
                    </Link>
                );
            case 'content':
                return (
                    <p key={index} className={`content ${element.class}`} dangerouslySetInnerHTML={{__html: element.content}}></p>
                );
            case 'image':
                return (
                    <img key={index} className={`${element.class}`} src={element.image.url} alt='img' style={{ height: 'auto' }}></img>
                );
            case 'spacing':
                const flexShrinkProp = element.value.indexOf('%') > -1 ? {} : { flexShrink: 0 }
                return (
                    <div key={index} style={{ height: element.value, ...flexShrinkProp }}></div>
                )
            default:
                return null
        }
    }

    return (
        <StyledGameLandingPage className="game-landing" background={args.page_background} backgroundPosition={args.page_background_position}>
            <div 
                className={`content-container ${alignment.horizontal}`} 
                style={{ 
                    width: width.full === true ? "100%" : width.custom, 
                    height: height.full === true ? "100%" : height.custom,
                    alignSelf: 'center'
                }}
            >
                {page_content
                    ? page_content.map((element, index) => getContentElement(element, index))
                    : null
                }
            </div>
        </StyledGameLandingPage>
    )
}

const GameLandingPageWrapper = ({ gameLandingPages, locationGameLandingPages }) => {
    const params = useParams()

    let landingPage = gameLandingPages
    let redirectLink = basePath

    if (locationExists(params)) {
        landingPage = locationGameLandingPages.home_page_buttons
        redirectLink = `${basePath}/live/${params.location}`
    }

    landingPage = landingPage ? landingPage.filter(page => page.slug === params.landingSlug) : []

    return (
        <>
        {landingPage.length > 0
            ? <GameLandingPage landingPage={landingPage}/>
            : <Redirect to={redirectLink}/>
        }
        </>
    )
}

const mapStateToProps = createStructuredSelector({
    gameLandingPages: selectHomePageButtons,
    locationGameLandingPages: selectLocation,
});

export default compose(
    connect(mapStateToProps),
    MotionDiv
)(GameLandingPageWrapper)