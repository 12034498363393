import { useState } from "react"
import { Route, Switch, useLocation, Redirect, useHistory } from "react-router-dom"
import { useBusiness } from "../../App";

import BusinessHeader from "../../routes/BusinessPage/BusinessHeader";
import Footer from "./components/footer/footer.component";

import HomePageContainer from "./pages/home/home.container"
import GameLandingPage from './pages/game-landing/game-landing.component'
import Live from "./pages/live/live.component";

import { basePath } from './utils'

import './App.scss'

const App = () => {
	const [paddTop, setPaddTop] = useState(0);
	const [paddBottom, setPaddBottom] = useState(0)
    const business = useBusiness();
	const { pathname } = useLocation();
	const history = useHistory();

	const doGoBack = () => {
		if (history.location.pathname === '/b/jingleball') {
			history.push("/")
		} else {
			history.goBack();
		}
	}

	return (
		<div className="jingle-ball" style={{ paddingTop: `${paddTop}px`, paddingBottom: `${paddBottom}px`, '--to-be-removed': `${paddTop + paddBottom}px` }}>
			<BusinessHeader business={business} setMarginTop={setPaddTop} doGoBack={doGoBack}/>
			<div className="app-holder">
				<Switch>
					<Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
					<Route exact path={`${basePath}`} component={HomePageContainer} />
					<Route exact path={`${basePath}/live`} component={Live} />
					<Route path={`${basePath}/live/:location`} component={HomePageContainer} />
					<Route path={`${basePath}/:landingSlug`} component={GameLandingPage} />
				</Switch>
			</div>
			<Footer setPaddBottom={setPaddBottom} style={{ flex: "0 0" }} tab="home" bussinesId={business.id} history={history} />
		</div>
	)
}

export default App;

