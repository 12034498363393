import './ar-shop.styles.scss'
import { Route } from 'react-router-dom'
import ArShopPageContainer from './ar-shop.container';
import ArShopSignlePage from '../ar-shop-single/ar-shop-single.component';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { selectArGames } from '../../redux/posts/posts.selectors';

const ArShopPage = ({arGames, match}) => (
    <>
        <Route exact path={`${match.path}`}>
            <ArShopPageContainer arGames={arGames}/>
        </Route>
        <Route path={`${match.path}/:slug`}>
            <ArShopSignlePage arGames={arGames}/>
        </Route>
    </>
)

const mapStateToProps = createStructuredSelector({   //state = the root reducer
    arGames: selectArGames
})

export default connect(mapStateToProps)(ArShopPage)