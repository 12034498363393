
import { countries, regions } from "./countries";
import { LabelForm } from "./LabelForm";
import { StyledSelect } from "./helpers";
import { fieldPropsInterfaceOverride } from "../types";




export const CountryAndRegionField = (props: fieldPropsInterfaceOverride) => {

	const country = props.value?.country;
	const region = props.value?.region;

	const onChangeField = (name: string, value: string) =>
		props.onChange({ ...props.value, [name]: value });

	const regs = regions[countries.find((c: any) => c.name === country)?.code] ?? [];

	return (
		<div>
			<LabelForm {...props} title="Country" />
			<StyledSelect
				name={props.field.name + ".country"}
				value={country ?? ""}
				onChange={(e) => onChangeField("country", e.target.value)}
				required={props.field.required}

			>
				<option value="">
					{""}
				</option>
				{countries.map((c: any) => (
					<option key={c.code} value={c.name}>
						{c.name}
					</option>
				))}
			</StyledSelect>

			<LabelForm {...props} title="State" />
			<StyledSelect
				name={props.field.name + ".region"}
				value={region ?? ""}
				onChange={(e) => onChangeField("region", e.target.value)}
				required={props.field.required}
				disabled={regs.length === 0}
			>
				<option value="">
					{""}
				</option>
				{regs.map((r: any) => (
					<option key={r} value={r}>
						{r}
					</option>
				))}
			</StyledSelect>
		</div>
	);
};

