import { useEffect } from 'react'
import { useHistory } from 'react-router'
import './journey.scss'
const JourneyPage = () => {
    const history = useHistory();
    useEffect(() => {
        const timeout = setTimeout(() => {
            history.push('/b/6xuDGWJ7fO/thanks')
        }, 3000)

        return () => { clearTimeout(timeout) }
    }, [])


    return (
        <div className='journey-page'>
            <img
                src={require('../../assets/gifs/fam-car.gif').default}
                alt="car moving gif"
                style={{ width: '100%' }}
            />
        </div>
    )
}

export default JourneyPage;

