import { Text } from '@varius.io/wombo'
import { fieldPropsInterfaceOverride } from '../types'

export const ErrorMsg = (props: fieldPropsInterfaceOverride) => {
	const { error } = props


	if (!error?.message) return null
	return (
		<Text
			renderAs="span"
			style={{
				margin: 0,
				marginTop: '0.2rem',
				fontWeight: 'normal',
				color: "red"
			}}
		>
			{error?.message || " "}
		</Text>
	)
}
