import React from "react"

type Props = {
  activeClass: number
  title: string
  index: number
  setSelectedTab: (index: number) => void
}

const TabTitle: React.FC<Props> = ({ activeClass, title, setSelectedTab, index }) => {
  let activeClassName = "Active"
  if (activeClass == index) activeClassName = "Active"; else activeClassName = "Inactive";
  return (
    <li>
      <button className={activeClassName} onClick={() => setSelectedTab(index)}>{title}</button>
    </li>
  )
}

export default TabTitle