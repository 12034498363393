import { connect } from 'react-redux';
import HomePage from "./home.component";
import { fetchLocationStartAsync } from '../../redux/locations/locations.actions'
import { fetchPostsStartAsync } from "../../redux/posts/posts.actions"
import { useParams, useHistory } from 'react-router-dom';
import { useEffect } from 'react';

import WithSpinner from "../../components/with-spinner/with-spinner.component";
import { createStructuredSelector } from 'reselect'
import { selectArePostsLoaded } from '../../redux/posts/posts.selectors'
import { selectIsLocationLoaded } from '../../redux/locations/locations.selectors'
import { basePath } from '../../utils'
import Cookies from "js-cookie";

const HomeWithSpinner = WithSpinner(HomePage)

const HomeContainer = ({fetchLocationStartAsync, fetchPostsStartAsync, isLocationLoaded, arePostsLoaded}) => {
    const { location } = useParams();
    const history = useHistory()
    const isLoading = location ? isLocationLoaded : arePostsLoaded

    useEffect(() => {
        const locationCookie = Cookies.get('location')
        if (locationCookie) {
            if (location) {
                if (location !== locationCookie) {
                    history.push(`${basePath}/live/${locationCookie}`)
                    return
                }
            } else {
                history.push(`${basePath}/live/${locationCookie}`)
                return
            }
        } else {
            if (location) {
                history.push(`${basePath}`)
                return
            }
        }
        
        location ? fetchLocationStartAsync(location) : fetchPostsStartAsync()
    }, [location, fetchLocationStartAsync, history, fetchPostsStartAsync])

	return <HomeWithSpinner isLoading={isLoading}/>;
}

const mapStateToProps = createStructuredSelector({
	isLocationLoaded: selectIsLocationLoaded,
    arePostsLoaded: selectArePostsLoaded
});

const mapDispatchToProps = (dispatch) => ({
    fetchPostsStartAsync: () => dispatch(fetchPostsStartAsync()),
	fetchLocationStartAsync: (location) => dispatch(fetchLocationStartAsync(location))
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer)