import './highlights.styles.scss'

import { useHistory, useLocation } from 'react-router-dom'

import PageHeader from '../../components/page-header/page-header.component';
import MotionDiv from '../../components/motion-div/motion-div.component';

const HighlightsContainerPage = ({highlights}) => {
    const history = useHistory();
    const { pathname } = useLocation()

    return (
        <div className='highlights-page'>

            <PageHeader title={highlights.title}/>

            <div className='page-content'>
                <div className='image-holder' style={{backgroundImage: `url("${highlights.header_image.url}")`}} />

                {
                highlights.highlights.map((element, index) => (
                    <div key={index} className="highlights-element" onClick={() => history.push(`${pathname}/${element.title.toLowerCase().replaceAll(' ', '-')}`)}>
                        <h5>{element.title}</h5>
                    </div>
                )) 
                }
            </div>
        </div>
    )
}

export default MotionDiv(HighlightsContainerPage)