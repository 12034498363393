import FlagImage from './flagImage'
import './selectCountry.styles.scss'

export default function SelectCountry({ selected, onSelect }: any) {
  let flags = [{ number: 0, name: "No Team" },
  { number: 7, name: "USA" },
  { number: 22, name: "CANADA" },
  { number: 11, name: "MEXICO" },
  { number: 9, name: "ARGENTINA" },
  { number: 14, name: "AUSTRALIA" },
  { number: 21, name: "BELGIUM" },
  { number: 25, name: "BRAZIL" },
  { number: 28, name: "CAMEROON" },
  { number: 18, name: "COSTA RICA" },
  { number: 24, name: "CROATIA" },
  { number: 15, name: "DENMARK" },
  { number: 2, name: "ECUADOR" },
  { number: 5, name: "ENGLAND" },
  { number: 13, name: "FRANCE" },
  { number: 19, name: "GERMANY" },
  { number: 30, name: "GHANA" },
  { number: 6, name: "IRAN" },
  { number: 20, name: "JAPAN" },
  { number: 23, name: "MOROCCO" },
  { number: 4, name: "NETHERLANDS" },
  { number: 12, name: "POLAND" },
  { number: 29, name: "PORTUGAL" },
  { number: 1, name: "QATAR" },
  { number: 10, name: "SAUDI ARABIA" },
  { number: 3, name: "SENEGAL" },
  { number: 26, name: "SERBIA" },
  { number: 32, name: "SOUTH KOREA" },
  { number: 17, name: "SPAIN" },
  { number: 27, name: "SWITZERLAND" },
  { number: 16, name: "TUNISIA" },
  { number: 31, name: "URUGUAY" },
  { number: 8, name: "WALES" },

  ];

  return (
    <div className='country-selectror-container front mb-40 mt-15'>
      {flags.map((flag) =>
        <FlagImage
          src={require(`../../assets/images/flags/Ball-Flag-${flag.number}.png`)}
          countryName={flag.name}
          selected={flag.name === selected}
          onSelect={() => onSelect(flag.name)} />)}
    </div>
  )
}