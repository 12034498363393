import { useHistory } from 'react-router'
import '../pass-1/pass1.styles.scss'
import { useCampaignUserInfo } from '../../../../../auth'
import { useBusiness } from "../../../../../App";
import MotionDiv from '../../../components/motion-div/motion-div.component'

const FirstPass = () => {

  const business = useBusiness();
  const businessId = business!.id;
  const campaignId = business!.defaultCampaignId;
  const { data: userInfo } = useCampaignUserInfo(businessId, campaignId);
  const history = useHistory()

  const handleRoutingHome = () => {
    if (!userInfo?.pepsi_address?.country || userInfo?.pepsi_address?.country === "United States of America") {
      localStorage.setItem('wizardState', '4');
      history.push('/b/lays/pre-daily-game')
    }
    else {
      localStorage.setItem('wizardState', '5');
      history.push('/b/lays/explore/landing')
    }
  }

  const handleRoutingNext = () => {
    history.push('/b/lays/pass/2')
  }

  return (
    <div className='selfie-before-page customheight'>
      <div className="overlay customheight"></div>
      <div className='page-wrapper' >
        <img
          src={require('../../../assets/images/pass-asset.png')}
          alt=""
          className="center-img mt-10 img-height front" />
        <div className="mustContent">
          <div className="front text-white fs-32 fw-900 fw-bold text-center mb-10 mt-0"><h2>Share the Excitement.<br></br>Pass the Ball.</h2></div>
          <div className="front text-white fs-16 fw-900 pl-4 pr-4 text-center mb-20"><p>Now forward the ball to a friend for one more chance to WIN amazing swag.</p></div>
          <div className='text-center front flex-centered'>
            <button
              className="btn btn-gold w-100 fw-bold"
              onClick={handleRoutingNext}
              style={{ cursor: 'pointer' }}>
              <p className="fs-17 fw-bold">SHARE</p>
            </button>
            <br />
            <button
              className="btn btn-outline-gold w-100 fw-bold"
              onClick={handleRoutingHome}
              style={{ cursor: 'pointer' }}>
              <p className="fs-17 fw-bold">SKIP</p>
            </button>
            <div className="swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal bottom-pagination mt-10 mb-0">
              <span className="swiper-pagination-bullet"></span>
              <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
              <span className="swiper-pagination-bullet"></span>
              <span className="swiper-pagination-bullet"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default MotionDiv(FirstPass)