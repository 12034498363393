export const dates =
    [
        {
            label: "20/11/2022",
            value: "2022-11-20"
        },
        {
            label: "21/11/2022",
            value: "2022-11-21"
        },
        {
            label: "22/11/2022",
            value: "2022-11-22T00:00"
        },
        {
            label: "23/11/2022",
            value: "2022-11-23"
        },
        {
            label: "24/11/2022",
            value: "2022-11-24"
        },
        {
            label: "25/11/2022",
            value: "2022-11-25"
        },
        {
            label: "26/11/2022",
            value: "2022-11-26"
        },
        {
            label: "27/11/2022",
            value: "2022-11-27"
        },
        {
            label: "28/11/2022",
            value: "2022-11-28"
        },
        {
            label: "29/11/2022",
            value: "2022-11-29"
        },
        {
            label: "30/11/2022",
            value: "2022-11-30"
        },
        {
            label: "01/12/2022",
            value: "2022-12-01"
        },
        {
            label: "02/12/2022",
            value: "2022-12-02"
        },
        {
            label: "03/12/2022",
            value: "2022-12-03"
        },
        {
            label: "04/12/2022",
            value: "2022-12-04"
        },
        {
            label: "05/12/2022",
            value: "2022-12-05"
        },
        {
            label: "06/12/2022",
            value: "2022-12-06"
        },
        {
            label: "07/12/2022",
            value: "2022-12-07"
        },
        {
            label: "08/12/2022",
            value: "2022-12-08"
        },
        {
            label: "09/12/2022",
            value: "2022-12-09"
        },
        {
            label: "10/12/2022",
            value: "2022-12-10"
        },
        {
            label: "11/12/2022",
            value: "2022-12-11"
        },
        {
            label: "12/12/2022",
            value: "2022-12-12"
        },
        {
            label: "13/12/2022",
            value: "2022-12-13"
        },
        {
            label: "14/12/2022",
            value: "2022-12-14"
        },
        {
            label: "15/12/2022",
            value: "2022-12-15"
        },
        {
            label: "16/12/2022",
            value: "2022-12-16"
        },
        {
            label: "17/12/2022",
            value: "2022-12-17"
        },
        {
            label: "18/12/2022",
            value: "2022-12-18"
        }

    ]

