import React from 'react'
import { Link } from 'react-router-dom'
import './congestion.scss'

const CongestionPage = () => {
    return (
        <div className='congestion-page'>
            <img
                className='small-bottom-margin'
                alt="warning sign"
                style={{ width: 92, height: 82, marginTop: `var(--congestion-top-margin)`, verticalAlign: "middle" }}
                src={require("../../assets/images/warning.png")}
            />
            <p className='small-title small-bottom-margin'>CONGESTION <br /> AHEAD!</p>
            <p>Would you like to take a<br /> smarter route and receive <br /> a $5 Starbucks giftcard?</p>

            <Link className='white-outline-button small-top-margin' to='/b/6xuDGWJ7fO/choices'>
                <p className='button-text'>Yes</p>
            </Link>

            <Link className='white-outline-button' to='/b/6xuDGWJ7fO/choices'>
                <p className='button-text'>No</p>
            </Link>
        </div>
    )
}

export default CongestionPage;

