import { Link } from "react-router-dom"
import './mystuff.styles.scss'

import { basePath } from '../../utils'

const MyStuff = (props) =>  (
    <Link 
        className="my-stuff" 
        style={{
            backgroundImage: "url(" + props.background.url + ")",
            cursor: 'pointer'
        }}
        to={`${basePath}/wallet`}
    >
        <p className='title'>{props.title}</p>
        <img 
            alt="button logo"
            src={require('./../../assets/images/button-logo.png')}
            style={{ 
                height: 20, 
                width: 13, 
                marginRight: 16 
            }}
        />
    </Link>
)


export default MyStuff;