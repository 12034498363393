// VatomInc-Module-Identifier: UserInfoForm
//
// NOTE: Before making a change to this file, please use the above line to
// perform a code search across all VatomInc repositories:
//
// https://github.com/search?q=VatomInc-Module-Identifier%3A+UserInfoForm
//
// The above allows us to compare different implementations of this module
// across all of our products. When possible, try to make your changes as
// generalized and consistent across the products, and think deliberately before
// adding new dependencies (ie. imports of npm packages).

import { Button, Text } from '@varius.io/wombo'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import VatomInc, { BusinessFields, BusinessTerms } from '../../common/VatomInc'
import { FullscreenLoader } from '../../components/Loader'
import Sheet, { SheetTitle } from '../../components/Sheet'
import "./partials/markdown-styles.module.css"
import { Terms } from './partials/TermsList'
import { withErrorHandling } from './partials/helpers'
import { errorsType, userInforInterface } from './types'
import { Field, FIELDS } from './partials/FormList'
import { useIsAuthed } from '../../auth'


export const UserInfoForm = (props: userInforInterface) => {
  const { businessId, campaignId } = props
  const history = useHistory()
  const isLoggedIn = useIsAuthed()

  const [values, setValues] = useState<Record<string, any>>({})
  const [fields, setFields] = useState<BusinessFields[]>([])
  const [terms, setTerms] = useState<BusinessTerms[]>([])
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<errorsType>({})
  const [open, setOpen] = useState(true)
  const [submitting, setSubmitting] = useState(false)

  const [termsDecisions, setTermsDecisions] = useState<any>({})
  const [reqUnknowns, setReqUnknowns] = useState<Record<string, any>>({})
  const [gettingFields, setFettingFields] = useState(true)
  const [gettingTerms, setFettingTerms] = useState(true)

  const onChangeField = (name: string, value: any) =>
    setValues((v) => ({ ...v, [name]: value }))

  const onChangeTerm = (name: string, checked: boolean) =>
    setTermsDecisions((v: any) => ({ ...v, [name]: checked }))

  useEffect(() => {
    const fieldsRequired = fields.filter(f => f.required)
    if (!businessId)
      return

    if (!gettingFields && !gettingTerms && fieldsRequired.length === 0 && terms.length === 0) {
      props.onSubmitSuccess?.()
    }
  }, [terms, fields, gettingFields, gettingTerms, props])


  const getTerms = () => {
    if (!isLoggedIn && !businessId && !campaignId) return null
    setFettingTerms(true)
    VatomInc.getBusinessTerms(businessId)
      .then((res: BusinessTerms[]) => {
        setTerms(res.filter((u) => u.prompt))
        setFettingTerms(false)
      })
      .catch((e) => new Error('UserInfo terms get failed.'))
  }

  const getForms = () => {
    if (!isLoggedIn && !businessId && !campaignId) return null
    setFettingFields(true)
    VatomInc.getBusinessForms(campaignId, businessId)
      .then((res: any) => {
        setValues(res.values)
        setFields(res.fields)
        setFettingFields(false)
      })
      .catch((e) => new Error('UserInfo form get failed.'))
  }

  useEffect(() => {
    withErrorHandling(props.onError, async () => {
      setLoading(true)
      getTerms()
      getForms()
      setLoading(false)
    })()
  }, [])

  useEffect(
    () =>
      setReqUnknowns(fields.filter(({ type, required }: BusinessFields) => !FIELDS[type as keyof typeof FIELDS] && required)),
    [fields],
  )

  const onSubmitError = useCallback(
    (e: any) => {
      setSubmitting(false)
      props.onError?.(e)
    },
    [setSubmitting, props],
  )

  const onSubmit = useCallback(
    withErrorHandling(onSubmitError, async (evt: any) => {
      evt.preventDefault()

      setSubmitting(true)
      setErrors({})

      const formRes = await VatomInc.decideBusinessForms(
        values,
        campaignId,
        businessId,
      )

      if (formRes.ok) {
        setErrors({})
      } else if (formRes.status === 400) {
        const data = await formRes.json()
        if (data.errors) setErrors(data.errors)
        setSubmitting(false)
        return
      } else {
        throw new Error(`UserInfo form submit failed. status=${formRes.status}`)
      }

      for (const term of terms.filter(($) => $.prompt)) {
        await VatomInc.decideBusinessTerms(term.name, termsDecisions[term.name] ?? false)
      }

      getForms()
      getTerms()
      setSubmitting(false)
      setOpen(false)
      props.onSubmitSuccess?.(values)
    }),
    [
      onSubmitError,
      values,
      termsDecisions,
      props.businessId,
      props.campaignId,
      props.onSubmitSuccess,
      setErrors,
    ],
  )
  const fieldsRequired = fields.filter(f => f.required)
  const isOpen = (terms.length !== 0 || fieldsRequired.length !== 0) && open

  if (loading) return <FullscreenLoader />
  return (
    <Sheet isOpen={isOpen} close={() => { }}>
      <UserInfoStyles>
        <SheetTitle style={{ textAlign: "left", maxWidth: "90%", margin: "auto", color: "#FC500E", marginBottom: "16px", marginTop: "19px", fontSize: "1.5rem" }}>Complete your profile</SheetTitle>
        <Text
          style={{
            color: '#3F4A55',
            fontSize: '13px',
            textAlign: "left",
            maxWidth: "90%",
            margin: "auto"
          }}
        >
          You are almost done! To secure your account please provide additional information before continuing.
        </Text>
        <form
          onSubmit={onSubmit}
          style={{ maxWidth: '90%', margin: 'auto', marginBottom: '15px' }}
        >
          {fields.map((field: BusinessFields) => (
            <Field
              key={field.name}
              field={field}
              error={errors[field.name]}
              value={values[field.name] || ''}
              onChange={onChangeField.bind(this, field.name)}
            />
          ))}

          <Terms terms={terms} onChange={onChangeTerm} />
          <div style={{ display: 'flex', paddingBottom: "15px" }}>
            <Button
              className="primary"
              style={{ width: '100%', marginTop: '2rem', border: "1px solid #E9ECEF", marginRight: "5px" }}
              onClick={() => {
                history.push("/")
                setOpen(false)
              }}
            >
              Cancel
            </Button>

            <Button
              buttonStyle="primary"
              type="submit"
              className="primary"
              disabled={submitting || reqUnknowns.length > 0}

              style={{ width: '100%', marginTop: '2rem', marginLeft: "5px", background: "linear-gradient(156.85deg, #FC500E 9.39%, #FF7A00 101.2%)" }}

            >
              Continue
            </Button>
          </div>
        </form>
      </UserInfoStyles>
    </Sheet>

  )
}

const UserInfoStyles = styled.div`
  max-height: 60vh;
  label{
      padding-left: 0px !important;
      margin-top: '19px';
  }

  a{
    color: var(--color-blue) !important;
  }

     input[type^='checkbox'] {
     position: absolute;
     opacity: 0;
     z-index: 10;
     height: 20px;
     width: 20px !important;
   }
   .checkmark {
     height: 20px;
     width: 20px;
     min-width: 20px;
     border: 2px solid #ced4da;
     border-radius: 2px;
     margin-right: 10px;
     background-color: transparent;
     position: relative !important;
     right: 0;
     top: 0;
   }
   .checkmark i {
     color: white;
     display: none;
     font-size: 12px;

     svg {
       position: absolute;
       margin: auto;
       top: 0;
       left: 0;
       right: 0;
       bottom: 0;
       width: 10px;
     }
   }

   input:checked ~ .checkmark {
     background-color: #fa4e0f;
     border: 2px solid #fa4e0f;
     i {
       display: block;
     }
   }
   a {
     color: #2ea7ff;
   }
   p {
     margin: 0;
     margin-left: 30px;
   }
   .button-container {
     display: flex;
     padding: 20px;
     button {
       flex: 1;
       margin: 0 5px;
     }
   }
`
