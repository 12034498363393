/* eslint-disable react/function-component-definition */
import { useHistory } from 'react-router';
import PageHeading from '../../../components/page-heading/PageHeading'
import MotionDiv from '../../../components/motion-div/motion-div.component';
import { ChangeEvent, useState} from 'react';


const SelfieMiddle = () => {

  const history = useHistory()
  const [file, setFile] = useState('');
  const handleRouting = () => {
    history.push(`/b/lays/selfie/camera`)
  }

  const handleSetImage = (e: ChangeEvent<HTMLInputElement>, index: number) => {

    if (!e.target.files || e.target.files.length === 0) {
      // you can display the error to the user
      console.error("Select a file");
      return;
    }
    setFile(URL.createObjectURL(e!.target!.files[0]!));
  }

  return (
    <div className='selfie-after-page selfie-before-page customheight customheight-fixed'>
      <div className="overlay-ball  customheight"></div>
      <div className='page-wrapper'>

        <PageHeading
          title="Add Profile Image"
          subtitle="Take a selfie"
        />

        <img className="coin-image mb-50 mt-50" src={file ? file : require('../../../assets/images/layscoin_placeholder_rotate.gif').default} alt="Coin" />

        <div className='text-center front flex-centered'>
          <button
            className="btn btn-gold w-100 fw-bold"
            onClick={handleRouting}
            style={{ cursor: 'pointer' }}>
            <p className="fs-17 fw-bold">TAKE SELFIE</p>
          </button>
          <br />
        </div>
      </div>
    </div>
  )
}

export default MotionDiv(SelfieMiddle)