/* eslint-disable react/function-component-definition */
import '../explore.styles.scss'

import { useHistory } from 'react-router-dom';
import MotionDiv from "../../../components/motion-div/motion-div.component";

const ExploreLanding = () => {
    const history = useHistory()

    const handleRouting = () => {
        localStorage.setItem('wizardState', ' ');
        history.push('/b/lays/explore/animation')
    }
    const handleRoutingHome = () => {
        localStorage.setItem('wizardState', ' ');
        history.push('/b/lays')
    }

    return (
        <div className='explore-landing-page customheight'>
            <div className="overlay"></div>
          
               
                <img
                    src={require('../../../assets/images/gold-ball.png')}
                    alt="Pass the Ball Challenge"
                    className="center-img gold-ball front flex-centered" />
				<div className='mustContent'>
                <div className="front text-white fs-32 fw-900 fw-bold text-center mb-10 mt-10"><h2>Follow the Ball</h2></div>
				<div className="front text-white fs-16 fw-900 pl-4 pr-4 text-center mb-10"><p>Would you like to see the Golden World<br></br>Soccer Ball?</p></div>

                <div className='text-center front flex-centered'>
                    <button
                        className="btn btn-gold w-100 fw-bold"
                        onClick={handleRouting}
                        style={{ cursor: 'pointer' }}>
                        <p className="fs-17 fw-bold">YES</p>
                    </button>
                    <br />
                    <button
                        className="btn btn-outline-gold w-100 fw-bold"
                        onClick={handleRoutingHome}
                        style={{ cursor: 'pointer' }}>
                        <p className="fs-17 fw-bold">SKIP</p>
                    </button>
					<div className="swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal bottom-pagination mt-10 mb-0">
							<span className="swiper-pagination-bullet"></span>
							<span className="swiper-pagination-bullet"></span>
							<span className="swiper-pagination-bullet"></span>
							<span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>

			</div>
                </div>
           
        </div>
	</div>
    )
}
export default MotionDiv(ExploreLanding)
