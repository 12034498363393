import './ar-shop-single.styles.scss'
import { useParams } from 'react-router-dom';

import { compose } from "redux";
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect'
import { selectCheck } from '../../redux/ar-shop-single/ar-shop-single.selectors';
import { checkPrivacyPolicy } from '../../redux/ar-shop-single/ar-shop-single.actions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import Button from '../../components/button/button.component'
import MotionDiv from '../../components/motion-div/motion-div.component';

const ArShopSignlePage = ({arGames, checked, checkPrivacyPolicy}) => {
    const params = useParams();
    const arGame = arGames.filter(ar => ar.slug === params.slug)[0]

    return (
        <div className='ar-shop-single-page'>
            <div className='ar-shop-header image-holder' style={{backgroundImage: `url("${arGame.acf.large_image.url}")`}}>
                <h2>{arGame.title}</h2>
                <h5>{arGame.acf.subtitle}</h5>
            </div>

            <div className='page-content'>
                <p>{arGame.post_content}</p>
                <Button className="button">OPEN MY CAMERA</Button>
                <div className='check-box-holder'>
                    <input type="checkbox" id="privacy-policy" name="NFTs" />
                    <span className={`check-box ${checked ? 'checked' : ''}`}>
                        <FontAwesomeIcon icon={faCheck} className="icon"/>
                    </span>
                    <label htmlFor="privacy-policy" onClick={checkPrivacyPolicy}>I agree to share my information with iHeart and partners</label>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({   //state = the root reducer
    checked: selectCheck,
})
const mapDispatchToProps = dispatch => ({
    checkPrivacyPolicy: () => dispatch(checkPrivacyPolicy()),
})

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    MotionDiv
)(ArShopSignlePage)