import { useState } from "react"
import { Route, Switch, useLocation, Redirect, useHistory} from "react-router-dom"
import CongestionPage from "./pages/congestion/CongestionPage";
import ChoicesPage from "./pages/choices/ChoicesPage";
import JourneyPage from "./pages/journey/JourneyPage";
import ThanksPage from "./pages/thanks/ThanksPage";
import FinalPage from "./pages/final/FinalPage";
import { useBusiness } from "../../App";
import BusinessHeader from "../../routes/BusinessPage/BusinessHeader";
import './App.scss'

const App = () => {
	const [paddTop, setPaddTop] = useState(0);
    const path="/b/6xuDGWJ7fO"
	const { pathname } = useLocation();
    const history = useHistory();
	const business = useBusiness();

	const doGoBack = () => {
		if (history.location.pathname === '/b/6xuDGWJ7fO') {
			history.push("/")
		} else {
			history.goBack();
		}
	}

	const backgroundColor = pathname.includes('journey') ? '#FFCA52' : '#2356A2';

	return (
		<div className="goodneighbor" style={{'--header-height': `${paddTop}px`}}>
			<img
                src={require('./assets/gifs/fam-car.gif').default}
                alt="car moving gif"
                style={{ width: '100%', display: 'none' }}
            />
			<BusinessHeader business={business} setMarginTop={setPaddTop} doGoBack={doGoBack}/>
			<div className="app-holder" style={{paddingTop: paddTop, backgroundColor: backgroundColor}}>
				<Switch>
					<Route exact path={`${path}`} component={CongestionPage} />
					<Route path={`${path}/choices`} component={ChoicesPage} />
					<Route path={`${path}/journey`} component={JourneyPage} />
                    <Route path={`${path}/thanks`} component={ThanksPage} />
                    <Route path={`${path}/community`} component={FinalPage} />
				</Switch>
			</div>
		</div>
	)
}

export default App;

