import { createSelector } from "reselect";

const location = (state) => state.location

export const selectIsLocationLoaded = createSelector(  //check if collections are loaded
    [location],
    location => !!location.location //shorthand to convert any value to boolean
)

export const selectLocation = createSelector(
    [location],
    (location) => location.location
)
