import './photo-collections.styles.scss'

import { useHistory, useLocation } from 'react-router-dom'

import PageHeader from '../../components/page-header/page-header.component';
import MotionDiv from '../../components/motion-div/motion-div.component';

const PhotoCollectionsContainerPage = ({photoCollection}) => {
    const history = useHistory();

    const { pathname } = useLocation()

    return (
        <div className='photo-collections'>
            <PageHeader title="Past Photos"/>
            <div className='page-content'>
            {
            photoCollection.map((element, index) => (
                <div key={index} className={`collection image-holder ${element.acf.title_position}`} style={{backgroundImage: `url("${element.post_thumbnail}")`}} onClick={() => history.push(`${pathname}/${element.slug}`)}>
                    <h1>{element.title}</h1>
                </div>
            ))
            }
            </div>

        </div>
    )
}

export default MotionDiv(PhotoCollectionsContainerPage)