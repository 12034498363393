import { useHistory } from 'react-router-dom';
import { useCampaignUserInfo, useUpdateUserInfo } from '../../../../auth'
import { useBusiness } from "../../../../App";
import Loader from '../../../../components/Loader';
import './form.styles.scss'
import { useState, useEffect } from 'react';
import Select from 'react-select'
import { states } from './states'
import '../popup/popup.styles.scss'
const FormPage = () => {

	const [loading, setLoading] = useState(false)
	const history = useHistory()
	const business = useBusiness();
	const businessId = business!.id;
	const campaignId = business!.defaultCampaignId;
	const { data: userInfo, isValidating } = useCampaignUserInfo();

	const updateUserInfo = useUpdateUserInfo({ businessId, campaignId });
	const [name, setName] = useState("");

	const [nameError, setNameError] = useState("");
	const [email, setEmail] = useState("");
	const [picture, setPicture] = useState("");
	const [country, setCountry] = useState("");
	const [birthdate, setBirthdate] = useState("");
	const [pepsi_team, setpepsiTeam] = useState("");

	const [emailError, setEmailError] = useState("");
	const [phone, setPhone] = useState("");
	const [phoneError, setPhoneError] = useState("");
	const [address, setAddress] = useState("");
	const [addressError, setAddressError] = useState("");
	const [city, setCity] = useState("");
	const [cityError, setCityError] = useState("");
	const [state, setState] = useState("");
	const [stateError, setStateError] = useState("");
	const [zip, setZip] = useState("");
	// const [stateLabel, setStateLabel] = useState({});
	const [zipError, setZipError] = useState("");
	const [modalview, setModal] = useState("false");



	useEffect(() => {
		console.log(userInfo)
		setName(userInfo?.name)
		setPhone(userInfo?.phone)
		setEmail(userInfo?.email)
		setZip(userInfo?.pepsi_address?.zip)
		setCity(userInfo?.pepsi_address?.city)
		setCountry(userInfo?.pepsi_address?.country)
		setAddress(userInfo?.pepsi_address?.address)
		setPicture(userInfo?.picture)
		setpepsiTeam(userInfo?.pepsi_team)
		setBirthdate(userInfo?.birthdate)
		setState(userInfo?.pepsi_address?.region)
	}, [userInfo]);

	// console.log(stateLabel)
	const validateEmail = (email: string) => {
		return email.match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	};

	const customStyles = {
		option: (provided: any) => ({
			...provided,
			maxHeight: '135px',
			color: '#fff',
			background: "#353535",
		}),
		menu: (provided: any) => ({
			...provided,
			height: '35px',
			color: '#fff',
			background: "#353535",
			zIndex: 33,
			borderRadius: "4px"
		}),
		input: (provided: any) => ({
			...provided,
			height: '35px',
			padding: '0px',

		}),
		ValueContainer: (provided: any) => ({
			...provided,
			height: '38px',
			padding: '0px',

		}),
		singleValue: (provided: any) => ({
			...provided,
			color: 'white',
			background: "#353535",
			height: '25px'

		}),
		placeholder: (provided: any) => ({
			...provided,
			background: "#353535",
			height: '25px'

		}),
		IndicatorsContainer: (provided: any) => ({
			...provided,
			height: '35px',

		}),
		dropdownIndicator: (provided: any) => ({
			...provided,
			padding: '0px',
			height: '27px',

		}),

		control: (base: any, state: any) => ({
			...base,
			height: '38px',
			padding: '0 6px',

			background: "#353535",
			color: "#ffffff",
			borderColor: state.isFocused ? "#353535" : "#353535",
			boxShadow: state.isFocused ? null : null,
			"&:hover": {
				borderColor: state.isFocused ? "#353535" : "#353535"
			}
		}),

	};

	console.log(userInfo)
	const selectedValue = () => {
		const usState = { label: state, value: state };
		return usState;
	}
	const handleChange = (options: any) => {
		setState(options.label);
	};
	const handleRouting = () => {
		history.push(`/b/lays`);
	};

	const handleSkip = () => {
		setModal("true")
	};

	const handleLeave = () => {
		localStorage.setItem("formOk", "");
		setModal("false");
		history.push("/b/lays")
	}

	const handleStay = () => {
		setModal("false");
	}

	const handleSubmit = () => {
		if (!validateEmail(email)) setEmailError("errorcolor"); else setEmailError("");
		if (name == "") setNameError("errorcolor"); else setNameError("");
		if (phone == "") setPhoneError("errorcolor"); else setPhoneError("");
		if (address == "") setAddressError("errorcolor"); else setAddressError("");
		if (city == "") setCityError("errorcolor"); else setCityError("");
		if (zip == "") setZipError("errorcolor"); else setZipError("");
		if (state == "") setStateError("errorcolor"); else setStateError("");

		if ((!validateEmail(email)) || (name == "") || (phone == "") || (address == "") || (city == "") || (zip == "") || (state == "")) {
			let Errors = "1";
		}
		else {
			updateUserInfo({
				"name": name,
				"phone": phone,
				"email": email,
				"picture": picture,
				"pepsi_team": pepsi_team,
				"birthdate": birthdate,
				pepsi_address: {
					"full name": name,
					"email": email,
					"phone": phone,
					"address": address,
					"city": city,
					"zip": zip,
					"region": state,
					"country": country
				},
				"form_submitted": "true"
			});
			setLoading(true);
			setTimeout(function () {
				// '1' means complete
				localStorage.setItem("formOk", "1");
				handleRouting();
			}.bind(this), 500)

		}
	}
	if (!isValidating && pepsi_team)
		return (
			<>
				<div className={`popup-page pr-10 pl-10 customheight show-${modalview}`} style={{ left: 0 }}>
					<div className='popup-container pb-24'>
						<img alt="warning" src={require("../../assets/images/warning.png")} className="warning-img" />
						<p className='popup-text'>You're about to leave the Pass the Ball Challenge and enter the Vatom wallet, do you want to proceed?<br /><br />If you go ahead, you can always return through the Frito-Lay icon</p>
						<button
							className="btn btn-gold w-100 fw-bold mb-20 top-button"
							onClick={handleStay}
							style={{ cursor: 'pointer' }}>
							<p className="fs-17 fw-bold text-center">TAKE ME BACK</p>
						</button>
						<button
							className="btn btn-outline-gold w-100 fw-bold"
							onClick={handleLeave}
							style={{ cursor: 'pointer' }}>
							<p className="fs-17 fw-bold text-center">I'LL GO AHEAD</p>
						</button>
					</div>
				</div>

				<div className='personalInfo landing-page pl-10 pr-10 pt-20 formheight'>
					<form>
						<img alt="header" className="center-img mt-10 mb-10 img-height form-img front" src={require('../../assets/images/form-header.png')} />
						<div className='form-container bg-dark-form'>
							<div className='form-element'>
								<label className={nameError}>Full Name</label>
								<input type="text" placeholder="Full Name" name="firstName" value={name} onChange={(e: any) => { setName(e.target.value) }} />
							</div>

							<div className='form-element'>
								<label className={emailError}> Email</label>
								<input type="text" placeholder="Enter Email" name="email" value={email} onChange={(e: any) => { setEmail(e.target.value) }} />
							</div>

							<div className='form-element'>
								<label className={phoneError}>Phone</label>
								<input type="text" placeholder="Enter Phone #" name="phone" value={phone} onChange={(e: any) => { setPhone(e.target.value) }} />
							</div>

							<div className='form-element'>
								<label className={addressError}>Street Address</label>
								<input type="text" placeholder="Enter Street" name="address" value={address} onChange={(e: any) => { setAddress(e.target.value) }} />
							</div>

							<div className='form-element halfboxfirst'>
								<label className={cityError}>City</label>
								<input type="text" placeholder="Enter City" value={city} name="city" onChange={(e: any) => { setCity(e.target.value) }} />
							</div>

							<div className="form-element halfboxsecond">
								<label className={stateError}>State</label>

								<Select
									placeholder={<div>State</div>}
									options={states}
									maxMenuHeight={150}
									defaultValue={selectedValue()}
									components={{
										IndicatorSeparator: () => null
									}}
									onChange={handleChange}
									styles={customStyles}
								/>

							</div>


							<div className='form-element'>
								<label className={zipError}>Zip Code</label>
								<input type="text" placeholder="Zip Code" value={zip} name="zip" onChange={(e: any) => { setZip(e.target.value) }} />
							</div>

						</div>
					</form>
					<div className="text-center front flex-centered mb-100 mt-15">
						<Loader isLoading={loading} />
						<button
							type="button"
							className="btn btn-gold"
							onClick={handleSubmit}
							style={{ cursor: 'pointer' }}>
							<p className="fs-17 ml-6 mr-6 fw-bold">SUBMIT</p>
						</button>
						<button
							className="btn btn-outline-gold w-100 fw-bold"
							onClick={handleSkip}
							style={{ cursor: 'pointer', marginTop: '15px' }}>
							<p className="fs-17 fw-bold text-center">SKIP</p>
						</button>
					</div>
				</div>
			</>
		)
	else
		return (
			<div></div>
		)
}

export default FormPage;
