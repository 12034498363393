import { motion } from "framer-motion";

const pageVariants = {
	initial: {
		x: 400,
		position: "rel",

	},
	in: {
		x: 0,
		position: "absolute",
	},
	out: {
		opacity: 1,
		x: -400,

		position: "absolute",

	}
};

const pageTransition = {
	duration: 0.3, type: "tween", ease: "anticipate"
};

const pageStyle = {
	position: "absolute",
	outerWidth: 400,
	width: "100%",
	top: 0
};


const MotionDiv = WrappedComponent => ({ isLoading, ...otherProps }) => (


	<motion.div
		style={pageStyle}
		initial="initial"
		animate="in"
		exit="out"
		variants={pageVariants}
		transition={pageTransition}
		key={otherProps.key}
	>
		<WrappedComponent {...otherProps} />

	</motion.div>

)

export default MotionDiv 