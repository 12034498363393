import React from 'react'
import './finalpage.scss'
const FinalPage = () => {
    return (
        <div className='final-page'>
            <p className='small-title top-margin'>Community <br />Improvements</p>
            <div className='image-container'>
                <img
                    src={require("../../assets/images/improvement1.png")}
                    alt="$2.5M cost saved"
                    style={{ height: 160, width: 130 }}
                />

                <img
                    src={require('../../assets/images/improvement2.png')}
                    alt="3467 hours saved"
                    style={{ height: 155, width: 112 }}
                />
            </div>

            <div className='image-container'>
                <img
                    src={require('../../assets/images/improvement3.png')}
                    alt="2516 cars off road"
                    style={{ height: 95, width: 150 }}
                />

                <img
                    src={require('../../assets/images/improvement4.png')}
                    alt="-24% CO2"
                    style={{ height: 145, width: 100 }}

                />
            </div>
        </div>
    )
}

export default FinalPage;