import LocationActionTypes from './location.types';

const INITIAL_STATE = {
    location: null,
    isFetching: false,
    errorMessage: undefined
};

const locationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LocationActionTypes.FETCH_LOCATION_START:
            return {
                ...state,
                isFetching: true
            }
        case LocationActionTypes.FETCH_LOCATION_SUCCESS:
            return {
                ...state,
                isFetching: false,
                location: action.payload
            }
        case LocationActionTypes.FETCH_LOCATION_FAILURE:
            return {
                ...state,
                isFetching: false,
                errorMessage: action.payload
            }
        default:
            return state
    }
};

export default locationReducer;