import {useEffect, useRef, useState} from 'react';
import './swiper-nav-button.styles.scss'
import { useHistory  } from "react-router-dom";

const SwiperNavButton = ( {title, post_thumbnail, titlePosition, type, url, slug, gradient} ) => {

    const history = useHistory();

    let postion = {};
    if ( type === 'experience' ) {
        switch(titlePosition){
            case 'top-left':
                postion = {textAlign: 'left', alignSelf: 'flex-start'}
                break
            case 'top-right':
                postion = {textAlign: 'right', alignSelf: 'flex-start'}
                break
            case 'bottom-left':
                postion = {textAlign: 'left', alignSelf: 'flex-end'}
                break
            case 'bottom-right':
                postion = {textAlign: 'right', alignSelf: 'flex-end'}
                break
            default:
                break
        }
    }
    let url_slug = url;
    if ( type === 'offer' ) {
        postion = {display: "none"}
        url_slug = `/b/wango-tango/${slug}`
    }

    const [width, setWidth] = useState(0)
    const ref = useRef(null);
    useEffect(() => {
        setWidth(ref.current ? ref.current.offsetWidth : 0)
    }, [ref]);

    return (
    <div className={`nav-button ${type} ${title.toLowerCase()}`} onClick={() => history.push(url_slug)} ref={ref} style={type === 'offer' ? {height: width} : null}>
        <div className='inner'>
            <div className='nav-button-background image-holder' style={{backgroundImage: `url("${post_thumbnail}")`}}>
                <h3 style={{...postion}}>{title}</h3>
            </div>
        </div>
    </div>
    )
}

export default SwiperNavButton