import './hexbutton.styles.scss'

export default function HexButton({ show = "true", height, width, title, onClick, icon, className, margin = '2px', customClass = '' }: any) {

  return (
    <div
      onClick={onClick}
      className={`hex-container text-center mb-30 ${className} show-${show}`}
      style={{ maxWidth: '160px' }}>
      <div className={`hex-button h-${height} w-${width}`}>
        {icon &&
          <img className={`hex-btn-img ${customClass}`} src={require(`../../assets/images/${icon}`).default} />
        }
        <p style={{ bottom: margin }}
          className={`hex-btn-text text-center fs-16 w-140`}>{title}</p>
      </div>
    </div>
  )



}
