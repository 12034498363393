import React, { useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom'
import './App.scss';
import './pages/popup/popup.styles.scss'
import LandingPage from './pages/landing/landing.component';
import BusinessHeader from "../../routes/BusinessPage/BusinessHeader";
import Footer from "../../components/Footer";
import SelfieBefore from './pages/selfie/before/selfiebefore.component';
import SelfieAfter from './pages/selfie/after/selfieafter.component';
import SelfieMiddle from './pages/selfie/middle/selfiemiddle.component';
import PlayGame from './pages/prize/play/play.component';
import ExitGame from './pages/prize/exit/exit.component';
import FirstPass from './pages/pass/pass-1/pass1.component';
import CountrySelector from './pages/country-selector/countrySelector.component';
import SelfieCamera from './pages/selfie/camera/selfiecamera.component';
import FormPage from './pages/form/form.component';
import SubmitPhoto from './pages/selfie/submit/submitphoto.coponent';
import ExploreLanding from './pages/explore/landing/explorelanding.component';
import ExploreFollow from './pages/explore/follow/explorefollow.component';
import ExploreAnimation from './pages/explore/animation/exploreanimation.component';
import HomeNew from './pages/home/new/homenew.component';
import FAQPage from './pages/faq/faq.component';
import INFOPage from './pages/info/info.component';
import DailyGame from './pages/daily-game/daily-game.component';
import PreDailyGame from './pages/pre-daily-game/pre-daily-game.component';
import { useBusiness } from '../../App';
import SecondPass from './pages/pass/pass-2/pass2.component';
import PrizesPage from './pages/prizes/prizes.component';
import { Helmet } from 'react-helmet'

const AppContentHolder = () => {
	let customPadding = "100";
	document.documentElement.style.setProperty('--custompadding', `${customPadding}px`)
	const convertStyle = () => {
		document.documentElement.style.setProperty('--custompadding', `${customPadding}px`)
	};
	window.addEventListener("resize", convertStyle);
	window.addEventListener("DOMContentLoaded", convertStyle);
	const [padd, setPadd] = useState(180);
	const [modalview, setModal] = useState("false");
	const business = useBusiness();

	const history = useHistory();
	const path = '/b/lays';

	const handleLeave = () => {
		setModal("false");
		history.push("/")
	}

	const handleStay = () => {
		setModal("false");
	}

	if (!business) return null

	const footerProps = {
		history,
	};

	let optionalTitle = "";
	const hash = history.location.hash;
	if (history.location.pathname == "/b/lays/countries/select") optionalTitle = "Choose Your Country";
	if (hash == "#game") optionalTitle = "";
	if (history.location.pathname == "/b/lays/faq") optionalTitle = "FAQ";
	if (optionalTitle !== "") {
		customPadding = "145";
		document.documentElement.style.setProperty('--custompadding', `${customPadding}px`);
	}
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`)

	const doGoBack = (): boolean => {
		console.log(history.location, localStorage.getItem("wizardState"));

		switch (history.location.pathname) {
			case '/b/lays/countries/select':
				if (localStorage.getItem("wizardState") === "0" && hash === "#game")
					window.location.href = '/b/lays/countries/select';
				else if (hash !== "#game")
					setModal("true")
				// history.goBack();
				return true;
			case '/b/lays':
				setModal("true")
				return true
			case '/b/lays/form':
				localStorage.setItem("formOk", "") // Allow cancellation of form
				history.push('/b/lays')
				return true
			case '/b/lays/info':
			case '/b/lays/explore/animation':
				history.push('/b/lays')
				return true
			case '/b/lays/daily-game':
				if (localStorage.getItem("wizardState") !== " ")
					history.push('/b/lays/explore/landing')
				else
					history.push('/b/lays')
				return true;
			case '/b/lays/selfie/before':
				history.push('/b/lays/countries/select');
				return true
			default:
				if (hash !== "#game")
					history.goBack();
				return true;
		}
	}

	return (
		<div className="lays custompadding customheight">
			<Helmet>
				<title>Vatom - Lays</title>
				{/* <meta name="apple-itunes-app" content={`app-id=1508499844, app-argument=/b/${business.name}`} /> */}
				{/* <meta name="apple-itunes-app" /> */}
			</Helmet>
			<BusinessHeader doGoBack={doGoBack} business={business} setMarginTop={setPadd} optionalTitleText={optionalTitle} />
			<div className={`popup-page pr-10 pl-10 customheight show-${modalview}`}>
				<div className='popup-container pb-24'>
					<img alt="warning" src={require("./assets/images/warning.png")} className="warning-img" />
					<p className='popup-text'>You're about to leave the Pass the Ball Challenge and enter the Vatom wallet, do you want to proceed?<br /><br />If you go ahead, you can always return through the Frito-Lay icon</p>
					<button
						className="btn btn-gold w-100 fw-bold mb-20 top-button"
						onClick={handleStay}
						style={{ cursor: 'pointer' }}>
						<p className="fs-17 fw-bold text-center">TAKE ME BACK</p>
					</button>
					<button
						className="btn btn-outline-gold w-100 fw-bold"
						onClick={handleLeave}
						style={{ cursor: 'pointer' }}>
						<p className="fs-17 fw-bold text-center">I'LL GO AHEAD</p>
					</button>
				</div>
			</div>
			<div className='app-holder customheight'>

				<Switch location={history.location} key={history.location.pathname}>
					<Route exact path={path} component={HomeNew} />
					<Route path={`${path}/faq`} component={FAQPage} />
					<Route path={`${path}/info`} component={INFOPage} />
					<Route path={`${path}/pre-daily-game`} component={PreDailyGame} />
					<Route path={`${path}/daily-game`} component={DailyGame} />
					<Route path={`${path}/form`} component={FormPage} />
					<Route path={`${path}/landing`} component={LandingPage} />
					<Route path={`${path}/selfie/before`} component={SelfieBefore} />
					<Route path={`${path}/selfie/after`} component={SelfieAfter} />
					<Route path={`${path}/selfie/middle`} component={SelfieMiddle} />
					<Route path={`${path}/selfie/camera`} component={SelfieCamera} />
					<Route path={`${path}/selfie/submit`} component={SubmitPhoto} />
					<Route path={`${path}/prize/play`} component={PlayGame} />
					<Route path={`${path}/prize/exit`} component={ExitGame} />
					<Route path={`${path}/pass/1`} component={FirstPass} />
					<Route path={`${path}/pass/2`} component={SecondPass} />
					<Route path={`${path}/countries/select`} component={CountrySelector} />
					<Route path={`${path}/explore/landing`} component={ExploreLanding} />
					<Route path={`${path}/explore/follow`} component={ExploreFollow} />
					<Route path={`${path}/explore/animation`} component={ExploreAnimation} />
					<Route path={`${path}/prizes`} component={PrizesPage} />
				</Switch>
			</div>
			<Footer style={{ flex: "0 0" }} {...footerProps} bussinesId={business.id} />
		</div>
	)
}
class App extends React.Component {
	render() {
		return <AppContentHolder />;
	}
}
export default App;