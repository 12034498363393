import './nft-module.styles.scss'

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { selectOfficialNft } from '../../redux/posts/posts.selectors';

import { useHistory } from "react-router-dom";
import Button from '../button/button.component'

const NftModule = ({ pathname, officialNft }) =>  {
    const history = useHistory();
    const { cards } = officialNft

    return (
        <div className='module nft-module' key="1">
            <div className='nft-image-holder image-holder' onClick={() => history.push(`${pathname}/nft`)} style={{backgroundImage : `url("${cards.card_background.url}")`}}>
                <div className='nft-inner'>
                    <h4>{cards.card_title}</h4>
                    <Button className="button inside-button blue">{cards.card_button_title}</Button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({   //state = the root reducer
    officialNft: selectOfficialNft
})

export default connect(mapStateToProps)(NftModule)