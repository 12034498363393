import './home.styles.scss'

import { compose } from "redux";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { selectArGamesCheck, selectOffers, selectOfficialNftCheck } from '../../redux/posts/posts.selectors';
import { useHistory, useLocation  } from "react-router-dom";

import SwiperHolder from '../../components/swiper/swiper-holder.component';
import Button from '../../components/button/button.component';
import EventsModule from '../../components/events-module/events-module.component'
import ExperiencesModule from '../../components/experiences-module/experiences-module.component';
import ArShopModule from '../../components/ar-shop-module/ar-shop-module.component';
import MusicCtaModule from '../../components/music-cta-module/music-cta-module.component';
import NftModule from '../../components/nft-module/nft-module.component';

import MotionDiv from '../../components/motion-div/motion-div.component';

const HomePage = ({arGamesCheck, offers, officialNftCheck}) => {
    const history = useHistory();
    const { pathname } = useLocation()

    offers = !!offers.length ? offers.map(offer => ({...offer, type: "offer"})) : offers;

    return (
        <div className='home-page'>

            { arGamesCheck ? <ArShopModule pathname={pathname}/> : null }

            <ExperiencesModule />

            <div className='module' key="2">
                <Button className="button full-size wallet" onClick={() => history.push('/b/wango-tango/wallet')}>MY WALLET</Button>
            </div>

            { officialNftCheck ? <NftModule pathname={pathname}/> : null }

            <EventsModule />

            <MusicCtaModule pathname={pathname}/>

            {
            !!offers.length ? 
            <div className='module' key="7">
                <SwiperHolder spaceBetween={14} slidesPerView={4} elements={offers} title="Special Offers"/>
            </div> 
            : null
            }
        </div>
    )

}

const mapStateToProps = createStructuredSelector({   //state = the root reducer
    offers: selectOffers,
    arGamesCheck: selectArGamesCheck,
    officialNftCheck : selectOfficialNftCheck
})

export default compose(
    connect(mapStateToProps),
    MotionDiv
)(HomePage)