import Loader from '../../../../components/Loader'

const WithSpinner = WrappedComponent => ({ isLoading, ...otherProps }) => {
    return !isLoading ? (
        <Loader isLoading />
    ) : (
        <WrappedComponent {...otherProps} />
    )
}

export default WithSpinner 