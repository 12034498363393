import { useState } from 'react';
import './guide.styles.scss'
import { useHistory } from 'react-router';

import { compose } from "redux";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { selectGuide } from '../../redux/posts/posts.selectors';

import { Swiper, SwiperSlide } from 'swiper/react';
import Button from '../../components/button/button.component'
import MotionDiv from '../../components/motion-div/motion-div.component';

const GuidePage = ({guide}) => {
    const [firstSwiper, setFirstSwiper] = useState(null);
    const [secondSwiper, setSecondSwiper] = useState(null);
    const [index, setIndex] = useState(0);
    const history = useHistory()

    console.log(guide)

    return (
        <div className='guide-page'>
            <div className='guide-header image-holder' style={{backgroundImage: `url("${guide.header_image.url}")`}}>
                <img src="http://cms.vatom.com/wp-content/uploads/2022/05/iheart-logo.png"  alt=""/>
                <h3>06.04</h3>
                <a href={guide.button_link} target="_blank" rel="noreferrer">
                    <Button className="button inside-button">GET TICKETS</Button>
                </a>
            </div>

            <div className='page-content'>

                <div className='main-info'>
                    <h3>{guide.title}</h3>
                    <h4 dangerouslySetInnerHTML={{__html: guide.date}} />
                    <h6>{guide.location}</h6>
                    <div dangerouslySetInnerHTML={{__html: guide.content}} />
                </div>

                <div className='top-swiper'>
                    <Swiper
                    slideToClickedSlide={true}
                    spaceBetween={48}
                    slidesPerView={'auto'}
                    onSwiper={setFirstSwiper}
                    >
                    {
                    guide.slider.map((element, i) => (
                        <SwiperSlide 
                        key={i} 
                        onClick={() => {
                            secondSwiper.slideTo(firstSwiper.clickedIndex)
                            setIndex(firstSwiper.clickedIndex)
                        }}
                        >
                            <span className={index === i ? 'active' : ''}>{element.title}</span>
                        </SwiperSlide>
                    ))
                    }
                    </Swiper>

                </div>

                <div className='bottom-swiper'>
                    <Swiper
                    spaceBetween={15}
                    slidesPerView={1}
                    onSwiper={setSecondSwiper}
                    onSlideChange={() => {
                        firstSwiper.slideTo(secondSwiper.activeIndex)
                        setIndex(secondSwiper.activeIndex)
                    }}
                    >
                    {
                    guide.slider.map((element, index) => (
                        <SwiperSlide key={index} onClick={() => history.push(element.route)}>
                            <div className={`swiper-content image-holder ${element.title_position}`} style={{backgroundImage: `url("${element.background_image.url}")`}}>
                                <h2>{element.title}</h2>
                            </div>
                        </SwiperSlide>
                    ))
                    }
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({   //state = the root reducer
    guide: selectGuide,
})

export default compose(
    connect(mapStateToProps),
    MotionDiv
)(GuidePage)