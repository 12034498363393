/* eslint-disable react/function-component-definition */
import './slides.scss'

export default function Slide2() {

  return (
    <div className="info-slide2-container">
      <img
        alt="trophy"
        src={require('../../assets/images/Frame.png')}
        className="center-img mt-25 mb-40 mw-75 front"
      />
      <p className="main-text">Participate in each game's challenge and you will be entered for a chance to WIN amazing swag.</p>

      <a href="https://resources.vatom.com/gsndxqUVp1/pass-the-ball-iwg-online-entry-rules.pdf" target="_blank">
        <div className="colored-text-container">
          <p className='main-text'>See the</p>
          <p className="main-text colored-text">Official Rules</p>
        </div>
      </a>




      <p className="sub-text mb-52">Plus, pass the ball to a friend, and earn:
        One (1) additional entry to the sweepstakes for a chance to WIN amazing swag.</p>
      <p className="sub-text entries-notice-text">*Max. 4 entries per day</p>
    </div>

  )
}
