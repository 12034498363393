import './tickets.styles.scss'
import { compose } from "redux";
import Button from '../../components/button/button.component'

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { selectTickets } from '../../redux/posts/posts.selectors';

import MotionDiv from '../../components/motion-div/motion-div.component';

const TicketsPage = ({tickets}) => {
    const { heading } = tickets

    return (
        <div className='tickets-page'>
            <div className='tickets-header'>
                <div className='swiper-content image-holder' style={{backgroundImage: `url("${heading.background_image.url}")`}}>
                    <img src="https://cms.vatom.com/wp-content/uploads/2022/06/IHEART-LOGO.png" alt="iHeartLogo"/>
                    <h1>{heading.date}</h1>
                </div>
            </div>

            <div className='page-content'>
                <div className='main-info'>
                    <h2>{tickets.title}</h2>
                    <h5 dangerouslySetInnerHTML={{__html: tickets.date}} />
                    <h6 dangerouslySetInnerHTML={{__html: tickets.time}} />
                    <div dangerouslySetInnerHTML={{__html: tickets.location}} />
                </div>
                <div className='tickets-buttons'>
                    <a href={tickets.button_link} target="_blank" rel="noreferrer">
                        <Button className="button purple">GET TICKETS</Button>
                    </a>
                    {/* <a href='https://market.vatom.com/collections/issue-9-sustainability/' target="_blank" rel="noreferrer" className='cta-stub'><h6>CLAIM YOUR NFT STUB</h6></a> */}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({   //state = the root reducer
    tickets: selectTickets,
})

export default compose(
    connect(mapStateToProps),
    MotionDiv
)(TicketsPage)