import React from 'react'

type Props = {
  title: string
  children?: any
}

const Tab: React.FC<Props> = ({ children }) => {
  return <div className='images-container'>{children}</div>
}

export default Tab