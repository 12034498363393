import './pre-daily-game.styles.scss'
import { useHistory } from 'react-router-dom';
import MotionDiv from '../../components/motion-div/motion-div.component'

let customHeight = window.innerHeight;
const convertStyle = () => {
	customHeight = window.innerHeight;
};
window.addEventListener("resize", convertStyle);
window.addEventListener("DOMContentLoaded", convertStyle);

const PreDailyGame = () => {
	const history = useHistory()
	const handleRouting = () => {
		localStorage.setItem('wizardState', '5');
		history.push('/b/lays/explore/landing')
	}

	const handlePlayGame = () => {
		localStorage.setItem('wizardState', '5');
		history.push('/b/lays/daily-game')
	}

	return (
		<div className='landing-page  pregame customheight'>
			<div className='page-wrapper'>


				<img className="center-img mt-10 img-height-daily front" src={require('../../assets/images/Group 1171274958.png')} alt="" />
				<div className='mustContent'>
					<div className="front text-white fs-32 fw-900 fw-bold text-center mb-10 mt-0"><h2>Daily Score</h2></div>
					<div className="front text-white fs-16 fw-900 pl-4 pr-4 text-center mb-20"><p>Ready to play for a chance to win<br></br>amazing prizes?</p></div>
					<div className='text-center front flex-centered'>
						<button
							onClick={handlePlayGame}
							className="btn btn-gold w-100 fw-bold"
							style={{ cursor: 'pointer' }}>
							<p className="fs-17 fw-bold">PLAY</p>
						</button>
						<br />
						<button
							onClick={handleRouting}
							className="btn btn-outline-gold w-100 fw-bold"
							style={{ cursor: 'pointer' }}>
							<p className="fs-17 fw-bold">SKIP</p>
						</button>
						<div className="swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal bottom-pagination mt-10 mb-0">
							<span className="swiper-pagination-bullet"></span>
							<span className="swiper-pagination-bullet"></span>
							<span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
							<span className="swiper-pagination-bullet"></span>

						</div>

					</div>
				</div>
			</div>
		</div>

	)
}
export default MotionDiv(PreDailyGame)