
import { Link } from 'react-router-dom'
import './thankspage.scss'
import Analytics from '../../../../common/Analytics'
import { useBusiness } from '../../../../App'

const ThanksPage = () => {
    const business = useBusiness();

    const handleExternalLink = (url: string) => {
        Analytics.event("openURL", {
            url,
            businessId: business?.id,
            campaignId: business?.defaultCampaignId,
        });
    }

    const nftLink = 'https://6xudgwj7fo.share.vatominc.com/emit/eyJvYmplY3REZWZpbml0aW9uSWQiOiJTNUlZNmt0bGxvIiwiY2FtcGFpZ25JZCI6IjRnUWhCNG5LUkciLCJkaXN0cmlidXRpb25JZCI6InF5TEI1OE8zbk4ifQ%3D%3D?from=TxDOT&msg=&login=1'

    return (
        <div className='thanks-page'>
            <p className='title top-margin bottom-margin'>Thank you!</p>
            <p className='raleway-text'>Smarter route completed.</p>
            <p className='raleway-text'>Claim your gift card below.</p>

            <a
                className="white-outline-button button-top-margin"
                href={nftLink}
                rel="noopener noreferrer"
                onClick={() => handleExternalLink(nftLink)}
            >
                <p className='button-text'>Claim</p>
            </a>

            <Link className="white-outline-button" to='/b/6xuDGWJ7fO'>
                <p className="button-text"> Claim Later</p>
            </Link>
        </div>
    )
}

export default ThanksPage;

