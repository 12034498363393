import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { selectVideoCollectionsCheck, selectArtistToMeet, selectSelectedArGame, selectPhotoCollectionsCheck, selectHighlightsCheck } from '../../redux/posts/posts.selectors';

import SwiperHolder from '../swiper/swiper-holder.component';

const ExperiencesModule = ({highlightsCheck, videosCheck, artistToMeet, selectedArGame, photoCollectionsCheck}) => {

    const experiences = (title, post_thumbnail, titlePosition, url, gradient) => {
        return {
            type: "experience",
            title,
            post_thumbnail,
            titlePosition,
            url: `/b/wango-tango${url}`,
            gradient
        }
    }

    const experienceNavButtons = []
    if ( highlightsCheck ) {
        let highlights = experiences("Event Updates", 'http://cms.vatom.com/wp-content/uploads/2022/05/news.jpg', 'top-left', '/highlights');
        experienceNavButtons.splice(0, 0, highlights)
    }
    if ( videosCheck ) {
        let video = experiences("Videos", 'http://cms.vatom.com/wp-content/uploads/2022/05/videos.jpg', 'bottom-right', '/videos');
        experienceNavButtons.splice(1, 0, video)
    }
    if ( photoCollectionsCheck ) {
        let topTenShots = experiences("Past Photos", 'http://cms.vatom.com/wp-content/uploads/2022/05/photos.jpg', 'bottom-left', '/photo-collection');
        experienceNavButtons.splice(2, 0, topTenShots)
    }
   

    return (
        <div className='module' key="4">
            <SwiperHolder spaceBetween={14} slidesPerView={1} elements={experienceNavButtons} title="The Experience"/>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({   //state = the root reducer
    videosCheck: selectVideoCollectionsCheck,
    artistToMeet: selectArtistToMeet,
    selectedArGame: selectSelectedArGame,
    photoCollectionsCheck: selectPhotoCollectionsCheck,
    highlightsCheck: selectHighlightsCheck
})

export default connect(mapStateToProps)(ExperiencesModule)