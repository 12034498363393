import { BusinessTerms } from "../../../common/VatomInc";
import { TermsListTypes, TermType } from "../types";
import { mdToHtml } from "./helpers"

export const Term = (props: TermType) => {
  return (
    <div style={{ display: 'flex', marginTop: '1rem', position: "relative" }}>
      <input
        type="checkbox"
        required={props.term.required}
        onChange={(e) => props.onChange(e.target.checked)}
        style={{ width: 'auto' }}
      />
      <span className="checkmark">
        <i className="far fa-check"></i>
      </span>


      <span
        dangerouslySetInnerHTML={{
          __html: mdToHtml(props.term.markdown ?? props.term.name),
        }}
        style={{ flex: 1, marginLeft: '0.5rem', verticalAlign: 7 }}
      />
    </div>
  )
}


export const Terms = (props: TermsListTypes) => {
  return (
    <div>
      {props.terms
        .filter(($: BusinessTerms) => $.prompt)
        .map((term: BusinessTerms) => (
          <Term
            key={term.name}
            term={term}
            onChange={(checked: any) => props.onChange(term.name, checked)}
          />
        ))}
    </div>
  )
}