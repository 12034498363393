/* eslint-disable jsx-a11y/alt-text */
import './prizes.styles.scss'
import { useHistory } from 'react-router';
import MotionDiv from '../../components/motion-div/motion-div.component';
import Tabs from "../../components/tab/Tabs.component"
import Tab from "../../components/tab/tab.component"

const PrizesPage = () => {
    const history = useHistory()
    const onSubmit = () => {
        history.push(`/b/lays`);
    }
    return (
        <div className='prizes-page landing-page pl-16 pr-16 pt-16 pb-24 customheight'>
            <div className='front text-white bg-dark-prizes'>

                <div className='images-container'>

                    <img className="prize-img mw-200" src={require("../../assets/images/prizes/FIFA_prizes_22_0901_yahei.png")} />
                    <Tabs>
                        <Tab title="FRITO LAY">
                            <div className='category-title'>DUFFELS</div>
                            <img className="prize-img mw-171" src={require("../../assets/images/prizes/bag1.png")} />
                            <img className="prize-img mw-200" src={require("../../assets/images/prizes/bag2.png")} />
                            <img className="prize-img mw-180" src={require("../../assets/images/prizes/bag3.png")} />
                            <div className='category-title'>JERSEYS</div>
                            <img className='autoheight' src={require("../../assets/images/prizes/lays_shirts.png")} />
                            <div className='category-title'>SCARVES</div>
                            <img className="prize-img mw-180" src={require("../../assets/images/prizes/scarf1.png")} />
                            <img className="prize-img mw-180" src={require("../../assets/images/prizes/scarf2.png")} />
                            <img className="prize-img mw-180" src={require("../../assets/images/prizes/scarf3.png")} />
                            <img className="prize-img mw-180" src={require("../../assets/images/prizes/scarf4.png")} />
                            <div className='category-title'>SOCCER BALLS</div>
                            <img className="prize-img mw-165" src={require("../../assets/images/prizes/ball1.png")} />
                            <img className="prize-img mw-165" src={require("../../assets/images/prizes/ball2.png")} />
                            <img className="prize-img mw-165" src={require("../../assets/images/prizes/ball3.png")} />
                            <img className="prize-img mw-165" src={require("../../assets/images/prizes/ball4.png")} />
                            <div className='notice-text'>*Max 4 entries per day <br /> Prizes are for US participants only <br /> Prizes may not be exactly as shown</div>
                        </Tab>
                        <Tab title="ADIDAS">
                            <div className='category-title'>DUFFEL</div>
                            <img className="prize-img mw-151" src={require("../../assets/images/prizes/adidas_duffel.png")} />
                            <div className='category-title'>WATER BOTTLE</div>
                            <img className="prize-img mw-151" src={require("../../assets/images/prizes/adidas_bottle.png")} />
                            <div className='category-title'>SOCCER BALL</div>
                            <img className="prize-img mw-151" src={require("../../assets/images/prizes/adidas_ball.png")} />
                            <div className='category-title'>SHIRTS</div>
                            <img className='prize-img' src={require("../../assets/images/prizes/FL-FIFA WC Adidas Pullovers.png")} />
                            <img className='prize-img' src={require("../../assets/images/prizes/FL-FIFA WC Adidas T-Shirts.png")} />
                            <div className='category-title'>HATS</div>
                            <img className='prize-img' src={require("../../assets/images/prizes/hats.png")} />
                            <div className='notice-text'>*Max 4 entries per day <br /> Prizes are for US participants only <br /> Prizes may not be exactly as shown</div>
                        </Tab>
                    </Tabs>
                </div>
            </div>

            <div className='text-center front mt-8 flex-centered '>
                <button
                    className="btn btn-gold w-100 fw-bold"
                    onClick={onSubmit}
                    style={{ cursor: 'pointer' }}>
                    <p className="fs-17 fw-bold">EXIT</p>
                </button>
            </div>
        </div>
    )
}

export default MotionDiv(PrizesPage);
