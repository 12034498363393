import './nft-page.styles.scss'

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { selectOfficialNft } from '../../redux/posts/posts.selectors';

import { compose } from "redux";
import MotionDiv from '../../components/motion-div/motion-div.component'
// import Button from '../../components/button/button.component';

const NftPage = ({officialNft}) => {

    const { page } = officialNft

    return (
        <div className='nft-page'>

            <div className='page-content'>
                <div className='nft'>
                    <div className='image-holder' style={{backgroundImage : `url("${page.nft_picture.url}")`}}>
                        <h3>{page.page_title}</h3>
                    </div>
                    <div className='additional-info'>
                        <h5>{page.nft_additional_info}</h5>
                    </div>
                </div>
                {/* {page.button_link.length > 0 && page.page_button_title.length > 0 ? 
                    <a href={page.button_link}>
                        <Button className="button">{page.page_button_title}</Button>
                    </a> : null
                } */}
            </div>
        </div>
    )

} 

const mapStateToProps = createStructuredSelector({   //state = the root reducer
    officialNft: selectOfficialNft
})

export default compose(
    connect(mapStateToProps),
    MotionDiv
)(NftPage)