import { createSelector } from "reselect"; 

const selectPosts = state => state.posts

export const selectArePostsLoaded = createSelector(  //check if collections are loaded
    [selectPosts],
    posts => !!posts.posts //shorthand to convert any value to boolean
)

export const selectPostss = createSelector(
    [selectPosts],
    (posts) => posts.posts
)

export const selectPhotoCollections = createSelector(
    [selectPosts],
    (posts) => posts.posts.photo_collections
)

export const selectPhotoCollectionsCheck = createSelector(
    [selectPosts],
    (posts) => !!posts.posts.photo_collections.length
)

export const selectVideoCollections = createSelector(
    [selectPosts],
    (posts) => posts.posts.video_collections
)

export const selectVideoCollectionsCheck = createSelector(
    [selectPosts],
    (posts) => !!posts.posts.video_collections.length
)

export const selectArtists = createSelector(
    [selectPosts],
    (posts) => posts.posts.artists
)

export const selectArtistsCheck = createSelector(
    [selectPosts],
    (posts) => !!posts.posts.artists.length
)

export const selectArtistToMeet = createSelector(
    [selectPosts],
    (posts) => posts.posts.artists.filter(artist => artist.acf.meet_the_artist)[0]
)

export const selectOffers = createSelector(
    [selectPosts],
    (posts) => posts.posts.offers
)

export const selectArGames = createSelector(
    [selectPosts],
    (posts) => posts.posts.ar_games
)

export const selectArGamesCheck = createSelector(
    [selectPosts],
    (posts) => !!posts.posts.ar_games.length
)

export const selectSelectedArGame = createSelector(
    [selectPosts],
    (posts) => posts.posts.ar_games.filter(ar => ar.acf.selected)[0]
)

// export const selectPlaylist = createSelector(
//     [selectPosts],
//     (posts) => posts.posts.playlists
// )

// export const selectPlaylistCheck = createSelector(
//     [selectPosts],
//     (posts) => !!posts.posts.playlists.length
// )

export const selectFaq = createSelector(
    [selectPosts],
    (posts) => posts.posts.faq
)

export const selectFaqCheck = createSelector(
    [selectPosts],
    (posts) => {
        let checkFaq = true;
        for (const value of Object.values(posts.posts.faq)) {
            checkFaq = checkFaq && !!value
        }
        return checkFaq
    }
)

export const selectGuide = createSelector(
    [selectPosts],
    (posts) => posts.posts.guide
)

export const selectGuideCheck = createSelector(
    [selectPosts],
    (posts) => {
        let checkGuide = true;
        for (const value of Object.values(posts.posts.guide)) {
            checkGuide = checkGuide && !!value
        }
        return checkGuide
    }
)

// export const selectVideo = createSelector(
//     [selectPosts],
//     (posts) => posts.posts.video
// )

// export const selectVideoCheck = createSelector(
//     [selectPosts],
//     (posts) => {
//         let checkVideo = true;
//         for (const value of Object.values(posts.posts.video)) {
//             checkVideo = checkVideo && !!value
//         }
//         return checkVideo
//     }
// )

// export const selectVideos = createSelector(
//     [selectPosts],
//     (posts) => posts.posts.videos
// )

// export const selectVideosCheck = createSelector(
//     [selectPosts],
//     (posts) => {
//         let checkVideos = true;
//         for (const value of Object.values(posts.posts.videos)) {
//             checkVideos = checkVideos && !!value
//         }
//         return checkVideos
//     }
// )

export const selectTickets = createSelector(
    [selectPosts],
    (posts) => posts.posts.tickets
)

export const selectTicketsCheck = createSelector(
    [selectPosts],
    (posts) => {
        let checkTickets = true;
        for (const value of Object.values(posts.posts.tickets)) {
            checkTickets = checkTickets && !!value
        }
        return checkTickets
    }
)

export const selectHighlights = createSelector(
    [selectPosts],
    (posts) => posts.posts.highlights
)

export const selectHighlightsCheck = createSelector(
    [selectPosts],
    (posts) => {
        let checkHighlights = true;
        for (const value of Object.values(posts.posts.highlights)) {
            checkHighlights = checkHighlights && !!value
        }
        return checkHighlights
    }
)

export const selectOfficialNft = createSelector(
    [selectPosts],
    (posts) => posts.posts.official_nft
)

export const selectOfficialNftCheck = createSelector(
    [selectPosts],
    (posts) => {
        let checkOfficialNft = true;
        for (const value of Object.values(posts.posts.official_nft)) {
            checkOfficialNft = checkOfficialNft && !!value
        }
        return checkOfficialNft
    }
)