import PostsActionTypes from './posts.types'
import axios from 'axios';

export const fetchPostsStart = () => ({
    type: PostsActionTypes.FETCH_POSTS_START
})

export const fetchPostsSuccess = posts => ({
    type: PostsActionTypes.FETCH_POSTS_SUCCESS,
    payload : posts
})

export const fetchPostsFailure = errorMessage => ({
    type: PostsActionTypes.FETCH_POSTS_FAILURE,
    payload : errorMessage
})

export const fetchPostsStartAsync = () => {
    return dispatch => {
        dispatch(fetchPostsStart())  //start fetching the data
        axios.get('https://cms.vatom.com/wp-json/event/v1/event-posts/wango-tango')
        .then(res => {
            dispatch(fetchPostsSuccess(res.data)) //once is done, update the state via this action
        })
        .catch(err => dispatch(fetchPostsFailure(err.message))) //if there's an error update the state with error message
    }
}