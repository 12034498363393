import { useHistory } from 'react-router-dom';
import MotionDiv from '../../components/motion-div/motion-div.component';

import './faq.styles.scss'
let customHeight = window.innerHeight;
const convertStyle = () => {
  customHeight = window.innerHeight;
};
window.addEventListener("resize", convertStyle);
window.addEventListener("DOMContentLoaded", convertStyle);

const FAQPage = () => {
  const history = useHistory()
  const handleRouting = () => {
    localStorage.setItem('wizardState', ' ');
    history.push('/b/lays')
  }
  return (
    <div className='landing-page pl-10 pr-10 pt-20 faqlanding' >
      <div className="overlay-light"></div>
      <div className='page-wrapper center-img flex-centered pt-10 mb-0' >
        <div className='front text-white bg-dark-faq mb-20 faqfront'>
          <div className='faq-container fs-16'>
            <p className='mt-12 boldfont'> Q: How long will the Pass the Ball Challenge Campaign be offered?</p>
            <p className='mt-0'> A: The Sweepstakes begins on October 17, 2022 at 10:00:00 a.m. Central Time and ends on December 17, 2022 at 11:59:59 p.m Central Time. The grand prize ticket giveaway ends on 11/11 at 11:59 PM CST, after all entries will be for secondary prizes.<br/>
              <a href="https://resources.vatom.com/gsndxqUVp1/pass-the-ball-iwg-online-entry-rules.pdf" target={"_blank"}>You can see the official rules here</a></p>
            <p className='mt-12 boldfont'> Q: When will the Grand Prize be awarded?</p>
            <p className='mt-0'> A: The Trip-Grand Prize winner will be selected in a random drawing on or about November 11, 2022 from among all eligible entries received by 11:59:59 p.m. Central Time on November 10, 2022.<br/>
            <a href="https://resources.vatom.com/gsndxqUVp1/pass-the-ball-iwg-online-entry-rules.pdf" target={"_blank"}>You can see the official rules here</a></p>
            <p className='mt-12 boldfont'> Q: Is the Pass the Ball Challenge available to people outside the US?</p>
            <p className='mt-0'> A: Only those in the US are eligible to win prizes or participate in daily games. Those outside the US can join and explore the ball</p>
            <p className='mt-12 boldfont'> Q: How do I see my Digital prizes?</p>
            <p className='mt-0'> A: On the Pass the Ball homepage you will see in the navigation bar, “wallet”, it's the icon on the bottom right. Once there, you will see your digital prizes.</p>
            <p className='mt-12 boldfont'> Q: Why isn't my AR camera working?</p>
            <p className='mt-0'> A: This game uses your location and camera to determine what digital soccer balls are around you. If your permissions are turned off for your browser (safari, chrome, etc.), the game will not work.
              <br></br>To fix this:
              <br></br>iPhone (iOS): make sure you are using iOS 11.0 or higher. Go to Settings -&gt; scroll to safari -&gt; scroll down to “settings for websites” -&gt; open “camera”, select “ask”, location, select “ask”, try again (may need to refresh)
              <br></br>Android: make sure your are using android 11 or higher. Go to Settings -&gt; Apps → Chrome -&gt; Permissions -&gt; Camera “only while app is in use -&gt; location “only when app is in use” -&gt; try again (may need to refresh)</p>
            <p className='mt-12 boldfont'> Q: How Will I know if I've won?</p>
            <p className='mt-0'> A: Potential winners will be notified by email and must respond to the email notification within 48 hours for secondary prizes or 72 hours for the grand prize. Failure to respond in the required window and the prize may be forfeited and awarded to an alternate winner.</p>
            <p className='mt-12 boldfont'> Q: How do I play the Daily Game?</p>
            <p className='mt-0'> A: Select a game. Play each fun challenge. Win or lose you are entered for a chance to WIN amazing swag, plus other great prizes . Want to earn additional chances to WIN? Share the challenge with friends.
              <br></br>*Max. 4 entries per day </p>
            <p className='mt-12 boldfont'> Q: How do I Win Daily Game?</p>
            <p className='mt-0'> A: Participate in each game's challenge and you will be entered for a chance to WIN amazing swag, plus other great prizes The ticket giveaway ends on 11/11 at 11:59 PM CST, after all entries will be for secondary prizes.
              <br></br>*Max. 4 entries per day.</p>
            <p className='mt-12 boldfont'> Q: How do I receive my physical prizes?</p>
            <p className='mt-0'> A: If you have won a physical prize you will be notified by email and we will ship the prize to you in the address fields you filled out. If you have not filled out an address form, we will request it.</p>
            <p className='mt-12 boldfont'> Q: When will I receive my physical prize?</p>
            <p className='mt-0'> A: Prizes will be shipped within eight (8) to twelve (12) weeks of winner verification.</p>
            <p className='mt-12 boldfont'> Q: What is an NFT?</p>
            <p className='mt-0'> A: NFT stands for “Non-fungible token”, which is a unique digital asset stored on the Blockchain (such as Ethereum, Polygon or others). NFTs can verify that a digital piece of art, an event ticket, or memorabilia is one-of-a-kind. These can be experienced, purchased, shared, traded, redeemed from your digital wallet.</p>
            <p className='mt-12 boldfont'> Q: How can I tell how many times I have entered the Sweepstakes?</p>
            <p className='mt-0'> A: You can see this in the top right corner of the homepage, next to entries</p>
            <p className='mt-12 boldfont'> Q: How do I contact support?</p>
            <p className='mt-0'> A: <a className='whitelink' href="mailto:fritolay-support@vatom.com" target={"_blank"}>fritolay-support@vatom.com</a></p>

          </div>
        </div>
        <div className='text-center front flex-centered pb-8'>
          <button
            onClick={handleRouting}
            className="btn btn-gold w-100 fw-bold"
            style={{ cursor: 'pointer' }}>
            <p className="fs-17 fw-bold">EXIT</p>
          </button>
        </div>
      </div>
    </div>
  )
}
export default MotionDiv(FAQPage)
