import { useHistory } from 'react-router-dom';
import MotionDiv from "../../components/motion-div/motion-div.component";
import './landing.styles.scss'

const LandingPage = () => {
    const history = useHistory();
    const handleRouting = () => {
        history.push('/b/lays/selfie/before')
    }
    return (
        <div className='landing-page'>
            <div className='page-wrapper'>
                <img
                    src={require('../../assets/images/Polygon 1.png')}
                    alt="Pass the Ball Challenge"
                    className="center-img pt-8"
                    style={{ position: "absolute" }} />

                <img
                    src={require('../../assets/images/Logo_Lockup_0811_for_C$D 1.png')}
                    alt="Pass the Ball Challenge"
                    className="center-img pt-55" />

                <p className='text-white fs-14 text-center mt-54 fw-light'>Presented by</p>

                <img
                    src={require('../../assets/images/presentors.svg').default}
                    alt="Challange initiators"
                    className="center-img mt-11" />

                <img
                    src={require('../../assets/images/Soccer_ball_Solo_00000 1.png')}
                    alt="Game ball"
                    className="center-img mt-161" />

                <div className='text-center mt-0'>
                    <button
                        className="btn btn-gold"
                        onClick={handleRouting}
                        style={{ cursor: 'pointer' }}>
                        <p className="fs-17 ml-6 mr-6 fw-bold">LET'S GO</p>
                    </button>
                </div>
            </div>
        </div>
    )
}
export default MotionDiv(LandingPage)