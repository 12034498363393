import './lineup.styles.scss'
import { Route } from 'react-router-dom'
import LineupPageContainer from './lineup.container';
import LineupSinglePage from '../lineup-single/lineup-single.component';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { selectArtists } from '../../redux/posts/posts.selectors';

const LineupPage = ({artists, match}) => (
    <>
        <Route exact path={`${match.path}`}>
            <LineupPageContainer lineupElements={artists}/>
        </Route>
        <Route path={`${match.path}/:slug`}>
            <LineupSinglePage lineupElements={artists}/>
        </Route>
    </>
)


const mapStateToProps = createStructuredSelector({   //state = the root reducer
    artists: selectArtists,
})

export default connect(mapStateToProps)(LineupPage)
