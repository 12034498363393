import { useRef } from "react";
import { ErrorMsg } from "./ErrorMessage";
import { LabelForm } from "./LabelForm";
import { TextField } from "./helpers";
import { fieldPropsInterfaceOverride } from "../types";


export const DateField = (props: fieldPropsInterfaceOverride) => {
	// const nanToUndef = (num) => (Number.isNaN(num) ? undefined : num);
	const KEY_BKSP = 8;
	const KEY_DEL = 46;

	const monthRef = useRef<HTMLInputElement>(null);
	const dayRef = useRef<HTMLInputElement>(null);
	const yearRef = useRef<HTMLInputElement>(null);

	const [, year = "", month = "", day = ""] =
		props.value?.match?.(/^([^-]*)-([^-]*)-([^-]*)/) ?? [];

	const focus = (ref: any) => {
		ref.current!.focus();

		// Move cursor to end.
		const value = ref.current!.value;
		ref.current!.value = "";
		ref.current!.value = value;
	};

	const onChangeMonth = (e: any) => {
		const val = e.target.value.substring(0, 2);
		props?.onChange(`${year}-${val}-${day}`);
		if (e.target.value.length >= 2) dayRef.current!.focus();
	};

	const onChangeDay = (e: any) => {
		const val = e.target.value.substring(0, 2);
		props?.onChange(`${year}-${month}-${val}`);
		if (e.target.value.length >= 2) yearRef.current!.focus();
	};

	const onKeyDownDay = (e: any) => {
		if (day === "" && (e.keyCode === KEY_BKSP || e.keyCode === KEY_DEL))
			focus(monthRef);
	};

	const onChangeYear = (e: any) => {
		const val = e.target.value.substring(0, 4);
		props?.onChange(`${val}-${month}-${day}`);
	};

	const onKeyDownYear = (e: any) => {
		if (year === "" && (e.keyCode === KEY_BKSP || e.keyCode === KEY_DEL))
			focus(dayRef);
	};

	return (
		<div>
			<LabelForm {...props} />
			<div style={{ display: "flex", flexDirection: "row" }}>
				<TextField
					ref={monthRef}
					type="number"
					required={props.field.required}
					placeholder="MM"
					value={month}
					onChange={onChangeMonth}
					min={1}
					max={12}
					maxLength={2}
					style={{ flex: 1, marginRight: "0.5rem" }}
				/>

				<TextField
					ref={dayRef}
					type="number"
					required={props.field.required}
					placeholder="DD"
					value={day}
					onChange={onChangeDay}
					onKeyDown={onKeyDownDay}
					min={1}
					max={31}
					maxLength={2}
					style={{ flex: 1, marginRight: "0.5rem" }}
				/>

				<TextField
					ref={yearRef}
					type="number"
					required={props.field.required}
					placeholder="YYYY"
					value={year}
					onChange={onChangeYear}
					onKeyDown={onKeyDownYear}
					min={1000}
					max={9999}
					style={{ flex: 1 }}
				/>
			</div>
			<ErrorMsg {...props} />
		</div>
	);
};
