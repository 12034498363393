import { useHistory } from 'react-router-dom';
import PageHeading from '../../components/page-heading/PageHeading'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper";
import Slide3 from '../../components/slides/slide3'
import Slide0 from '../../components/slides/slide0'
import Slide1 from '../../components/slides/slide1'
import Slide2 from '../../components/slides/slide2'
import Slide4 from '../../components/slides/slide4'
import 'swiper/swiper.min.css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import './info.styles.scss'
import MotionDiv from '../../components/motion-div/motion-div.component';
import { useState } from "react";

localStorage.setItem('wizardState', ' ');

const INFOPage = () => {
  const [optionalTitle, setOptionalTitle] = useState("Game Schedule");
  const history = useHistory();
  const changeTitle = (route: any) => {
    if (route == "0") setOptionalTitle("Game Schedule");
    if (route == "1") setOptionalTitle("How do I play?");
    if (route == "2") setOptionalTitle("How to win");
    if (route == "3") setOptionalTitle("Support");
    if (route == "4") setOptionalTitle("Brands");
  }

  const homeFirstSwiper = [
    {
      id: "0",
      title: "pass-to-win",
      className: "info-slide fullMaxwidth",
      content: <Slide0></Slide0>
    },
    {
      id: "1",
      title: "pass-to-win",
      className: "info-slide fullMaxwidth",
      content: <Slide1></Slide1>
    },
    {
      id: "2",
      title: "pass-to-win",
      className: "info-slide fullMaxwidth",
      content: <Slide2></Slide2>
    },
    {
      id: "3",
      title: "pass-to-win",
      className: "info-slide fullMaxwidth",
      content: <Slide3></Slide3>
    },
    {
      id: "4",
      title: "pass-to-win",
      className: "info-slide fullMaxwidth",
      content: <Slide4></Slide4>
    }
  ]

  return (
    <div className='landing-page  infopage pl-10 pr-10 customheight'>
      <div className="overlay-light"></div>
      <div className="swiper-button-prev"></div>
      <div className="swiper-button-next"></div>
      <div className='page-wrapper center-img flex-centered pt-10 mb-0' >
        <div className='first-swiper-holder customheight-swiper'>
          <PageHeading
            title={optionalTitle}
          />

          <Swiper
            simulateTouch={false}
            spaceBetween={8}
            loop={false}
            allowTouchMove={true}
            slidesPerView={1}
            scrollbar={{ draggable: false }}
            pagination={{
              dynamicBullets: false,
            }}
            modules={[Pagination, Navigation]}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            onSlideChange={(swiperCore) => {
              const {
                activeIndex,
                previousIndex,
                realIndex,
              } = swiperCore;
              changeTitle(realIndex);
            }}
          >
            {
              homeFirstSwiper.map((element) => (
                <SwiperSlide className={`card ${element.className}`} id={`id${element.id}`} key={element.id} style={{ height: "178px" }}>
                  {element.content}
                </SwiperSlide>
              ))
            }
          </Swiper>

        </div>
      </div>
    </div>
  )
}
export default MotionDiv(INFOPage)