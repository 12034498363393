import './ar-shop-module.styles.scss'

import { useHistory } from "react-router-dom";

import Button from '../button/button.component'

const ArShopModule = ({ pathname }) => {
    const history = useHistory();

    return (
        <div className='module full' key="5">
            <div 
                className='ar-swag-shop image-holder' 
                style={{backgroundImage: `url("https://cms.vatom.com/wp-content/uploads/2022/06/ar-splash.jpg")`}}
                onClick={() => history.push(`${pathname}/ar-shop`)}
            >
                <h2>AR Swag Shop</h2>
               <Button className="button inside-button black-grey black-grey-icon">LET’S GO</Button>
            </div>
        </div>
    )
}

export default ArShopModule