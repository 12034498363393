import './lineup.styles.scss'
import { useHistory, useLocation } from 'react-router-dom'

import MotionDiv from '../../components/motion-div/motion-div.component';
import PageHeader from '../../components/page-header/page-header.component';

const LineupPageContainer = ({lineupElements}) => {
    const history = useHistory();

    const { pathname } = useLocation()

    return (
        <div className='lineup-page'>
            
            <PageHeader title="Artist Line Up"/>

            <div className='page-content'>
            {
            lineupElements.map((element, index) => (
                <div key={index} className='lineup-element image-holder' style={{backgroundImage: `url("${element.post_thumbnail}")`}} onClick={() => history.push(`${pathname}/${element.slug}`)}>
                    <h4>{element.title}</h4>
                </div>
            ))
            }
            </div>

        </div>
    )
}

export default MotionDiv(LineupPageContainer)