/* eslint-disable react/function-component-definition */
import { useHistory } from 'react-router';
import PageHeading from '../../../components/page-heading/PageHeading'
import MotionDiv from '../../../components/motion-div/motion-div.component';

const SelfieAfter = () => {
  const history = useHistory()
  localStorage.setItem('wizardState', '3');
  const handleRouting = () => {
    if (localStorage.getItem('wizardState') != " ")
      history.push(`/b/lays/pass/1`)

    else
      history.push(`/b/lays`)
  }

  const handleWallet = () => [
    history.push('/b/lays/wallet')
  ]

  return (
    <div className='selfie-after-page selfie-before-page customheight customheight-fixed'>
      <div className="overlay-ball customheight"></div>
      <div className='page-wrapper'>
        <img className="coin-image" src={require('../../../assets/images/layscoin_placeholder_rotate.gif').default} alt="Coin" />

        <PageHeading
          title="Looking great"
          subtitle="We are making you a one-of-a-kind NFT collectible. You'll get a notification in our wallet when it's ready."
        />

        <img className="wallet-icon" src={require('../../../assets/images/wallet.png')} alt="Wallet" />

        <div className='text-center front flex-centered'>
          <button
            className="btn btn-gold w-100 fw-bold"
            onClick={handleRouting}
            style={{ cursor: 'pointer' }}>
            <p className="fs-17 fw-bold">NEXT</p>
          </button>
          <br />
        </div>
      </div>
    </div>
  )
}

export default MotionDiv(SelfieAfter)