import HomePage from "./pages/home/HomePage";
import { Route, Switch, useHistory } from "react-router-dom"
import FAQ from "./pages/faq/FAQ";
import './App.scss'
import BusinessHeader from "../BusinessPage/BusinessHeader";
import { useBusiness } from '../../App';
import { useState } from "react";
import Footer from "../../components/Footer";
const App = () => {
	const path = "/b/deloittemwc"

	const [padd, setPadd] = useState(180);


	const history = useHistory();

	const business = useBusiness();

	const footerProps = {
		history,
	};

	if (!business) return null


	return (
		<div className="deloitte">
			<BusinessHeader business={business} setMarginTop={setPadd} />
			<div className="app-holder">
				<Switch>
					<Route exact path={`${path}`} component={HomePage} />
					<Route exact path={`${path}/FAQ`} component={FAQ} />
				</Switch>
			</div>
			<Footer style={{ maxWidth: 416, left: "50%", transform: "translateX(-50%)" }} {...footerProps} bussinesId={business.id} />
		</div>
	)
}

export default App;