import './slides.scss'

export default function Slide1() {

  return (
    <img
      src={require('../../assets/images/slide1.png')}
      className="center-img mt-15 mb-15 front"
    />

  )
}
