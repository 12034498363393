// Production config
module.exports = {
    //
    //  Project: Generic Web Viewer
    //
    // Your developer key. You can get one from developer.blockv.io
    appID: 'c46ddedf-bce3-4a3f-b69c-2312162bd549',
    
    // MapBox API Key. See https://docs.mapbox.com/mapbox-gl-js/overview/#quickstart to obtain a key
    mapBoxKey: 'pk.eyJ1IjoiYXNoaXNoYXR2YXRvbWluYyIsImEiOiJjazlpdXR3M2YwMGNjM2xwcGE1ZDhqMm5jIn0.xBI8uwwTY1H-NOAgtTBvOg',
    // Google Maps Key
    googleMapsKey: 'AIzaSyAh5AGRqFcNj06Il0zeRd_huqDhPab11OI',
    // googleContactsClientID: '3679106328-shu3ek38ruqhii8nqusbjsj77b4on7d5.apps.googleusercontent.com',
    googleContactsClientID: '676578017431-8c4hnqbltbci6nv0j1moijhsl5s4u784.apps.googleusercontent.com',
    // Google Analytics Tracking ID
    analyticsTrackingID: 'G-BJ3MJ7C38R',
    // Catchoom Token for image recognition. See https://catchoom.com/image-recognition/ to obtain a token
    catchoomToken: '569feb87e2e94c6a',
    
    // defaultCampaignId: 'DJVCwpz2hK',

    geoLocationApi: "https://api.ipstack.com/check?access_key=f6c1392b737ae773c32e839ccd03e87f&fields=country_code,latitude,longitude",

    algoliaApiKey: "be2a4102cb0d9d9d6e3cadd46dbf24dc",
    algoliaAppId: "1WB4E7CA90",

    // Default root for the web app router
    baseName: '/',

    // Firebase configuration options
    firebase: {
        apiKey: 'AIzaSyCyc4TsIcjIn4b8qDSOxM_CPpkd7Yaq0Qk',
        authDomain: 'generic-viewer.firebaseapp.com',
        databaseURL: 'https://generic-viewer.firebaseio.com',
        projectId: 'generic-viewer',
        storageBucket: 'generic-viewer.appspot.com',
        messagingSenderId: '73499331835',
        appId: '1:73499331835:web:f8f2e8b5688efe79'
    },
    // Firebase messaging VAPID key. See https://firebase.google.com/docs/cloud-messaging/js/client#generate_a_new_key_pair
    firebaseVapidKey: 'BKVxCEXAz6fv-PP0B6ThsF56acxr4bJ14ku-LDZR-vb2LH4ejeeAvCaxGvq2YPpuVX6JWw80_6EE6XeLPwTiMGs',

    // Optional 8th wall app key, provides 6DoF movement in AR
    appKey8thWall: "q6f9KSydWOgg0tZAqX7lKHT1kXBa8GNvgQqwxk6UGTadk8Io1pqPBUMt9HTlTLJx4d49lR",
    
}

