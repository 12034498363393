import './button.styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

const Button = ({children, ...props}) => (
    <button {...props}>
        <div className='inner'>
            <span>{children}</span>
            <FontAwesomeIcon icon={faAngleRight} className="icon"/>
        </div>
    </button>
)

export default Button