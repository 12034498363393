import App from "./App"

const GoodNeighbor = () => {

	return (
		<App />
	)
};

export default GoodNeighbor;
