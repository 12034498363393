import { createSelector } from "reselect";

const selectPosts = state => state.posts

export const selectArePostsLoaded = createSelector(  //check if collections are loaded
    [selectPosts],
    posts => !!posts.posts //shorthand to convert any value to boolean
)

export const selectPostss = createSelector(
    [selectPosts],
    (posts) => posts.posts
)

export const selectHomePageButtons = createSelector(
    [selectPosts],
    (posts) => posts.posts ? posts.posts.home_page_buttons : null
)

export const selectHomePageButtonsCheck = createSelector(
    [selectPosts],
    (posts) => !!posts.posts
)

export const selectWalletButton = createSelector(
    [selectPosts],
    (posts) => posts.posts ? posts.posts.wallet_button : null
)

export const selectWalletButtonCheck = createSelector(
    [selectPosts],
    (posts) => !!posts.posts.wallet_button
)