import './ar-shop.styles.scss'

import PageHeader from '../../components/page-header/page-header.component';

import Button from '../../components/button/button.component';
import MotionDiv from '../../components/motion-div/motion-div.component';

const ArShopPageContainer = ({arGames}) => {

    return (
        <div className='ar-shop-page'>

            <PageHeader title='AR Swag Shop' subtitle='Play games to win prizes!'/>

            <div className='ar-games-holder page-content'>
            {
            arGames.map((ar, index) => (
            <a href={ar.acf.button.button_link ? ar.acf.button.button_link : '#'}>
                <div id={index} key={index} className='ar-game image-holder' style={{backgroundImage: `url("${ar.post_thumbnail}")`}}>

                    <h4 
                    style={{
                        color: ar.acf.card_title.color,
                        alignSelf: ar.acf.card_title.position === 'right' ? 'flex-end' : 'flex-start',
                        textAlign: ar.acf.card_title.position === 'right' ? 'end' : 'start',
                        display: ar.acf.card_title.text ? 'block': 'none'
                    }}
                    dangerouslySetInnerHTML={{__html: ar.acf.card_title.text}}
                    />
                    <Button className="button inside-button"
                        style={{ alignSelf: ar.acf.card_title.position === 'right' ? 'flex-end' : 'flex-start' ,
                        display: ar.acf.button.text ? 'block': 'none' }}
                    >
                        {ar.acf.button.text}
                    </Button>
                </div>
                </a>
            ))
            }
            </div>
        </div>
    )
}

export default MotionDiv(ArShopPageContainer)