import './FAQ.scss'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';


const FAQ = () => {

    const rotateArrow = (e) => {
        let allArrows = document.getElementsByClassName('arrow')

        for (let i = 0; i < allArrows.length; i++) {
            if (allArrows[i].classList.contains('rotated')) {
                allArrows[i].classList.remove('rotated')
            }
        }
        for (let i = 0; i < e.length; i++) {
            let element = document.getElementById(`accordion__heading-${e[i]}`)

            let child = element.querySelector('img')

            if (!(child?.classList.contains('rotated'))) {
                child?.classList.add('rotated')
            }
        }
    }

    return (
        <div className="faq-page">
            <img src={require('./../../assets/images/FAQ_header.png')} style={{ height: 27, width: 66, marginTop: 150, marginBottom: 26 }} />

            <div className="faq-holder" style={{ boxSizing: 'border-box', width: "100%", paddingBottom: 50 }}>
                <Accordion allowZeroExpanded allowMultipleExpanded
                    onChange={(e:any) => {
                        rotateArrow(e)
                    }}>
                    <AccordionItem style={{ marginBottom: 26 }}>
                        <AccordionItemHeading>
                            <AccordionItemButton className="question">
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <img className="arrow" src={require('./../../assets/images/arrow.png')} style={{ height: 16, width: 10, marginRight: 18 }} />
                                    <p className='question' style={{ margin: 0 }}>Where can I find more out about Deloitte’s Unlimited Reality practice?</p>
                                </div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p className='answer'>
                                Visit our <a href="https://www2.deloitte.com/us/en/pages/consulting/solutions/enterprise-metaverse-consulting.html?nc=42%20">Unlimited Reality</a> website to learn about Deloitte’s offerings and thought leadership in this emerging area.
                                To learn more, connect with an Unlimited Reality leader at <a href="mailto:unlimitedreality@deloitte.com">unlimitedreality@deloitte.com </a>.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem style={{ marginBottom: 26 }}>
                        <AccordionItemHeading>
                            <AccordionItemButton className="question">
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <img className="arrow" src={require('./../../assets/images/arrow.png')} style={{ height: 16, width: 10, marginRight: 18 }} />
                                    <p className='question' style={{ margin: 0 }}>What is “Mirror World”?</p>
                                </div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p className='answer'>
                                Mirror World is an immersive experience which acts as a companion to the 2023 Mobile World Congress. Deloitte’s Unlimited team invites you and your clients to join us at our virtual booth, where you can engage with demos and use cases and view real-time live streams of MWC presentations. You can also have in one-on-one conversations with peers and Deloitte subject matter experts. Finally, you will have the ability to collect a series of AI-generated artwork, via digital collectibles, that increase your odds of winning cool prizes via a sweepstakes (see below for eligibility rules).
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem style={{ marginBottom: 26 }}>
                        <AccordionItemHeading>
                            <AccordionItemButton className="question">
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <img className="arrow" src={require('./../../assets/images/arrow.png')} style={{ height: 16, width: 10, marginRight: 18 }} />
                                    <p className='question' style={{ margin: 0 }}>During what dates is this immersive world open?</p>
                                </div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p className='answer'>
                                The immersive MWC event will last from 12:00 a.m. Eastern Time on Monday, February 27th, and end on Thursday, March 2nd at 11:59 p.m. Eastern Time.                        </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem style={{ marginBottom: 26 }}>
                        <AccordionItemHeading>
                            <AccordionItemButton className="question">
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <img className="arrow" src={require('./../../assets/images/arrow.png')} style={{ height: 16, width: 10, marginRight: 18 }} />
                                    <p className='question' style={{ margin: 0 }}>What is The Vatom Wallet?</p>
                                </div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p className='answer'>
                                It’s a digital wallet where you can store the digital collectibles that you collect. They provide easy, safe, secure storage across devices. Your digital collectibles are kept safe using a unique address that only your wallet has.                         </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem style={{ marginBottom: 26 }}>
                        <AccordionItemHeading>
                            <AccordionItemButton className="question">
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <img className="arrow" src={require('./../../assets/images/arrow.png')} style={{ height: 16, width: 10, marginRight: 18 }} />
                                    <p className='question' style={{ margin: 0 }}>Who is eligible to participate in the sweepstakes?</p>
                                </div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p className='answer'>
                                All users can participate in the scavenger hunt to find digital collectibles but only legal U.S. residents 18 and over who are participating virtually in the United States are eligible to win a prize*.Each digital collectible includes beautiful custom artwork and insights into Deloitte’s Unlimited Reality practice.
                            </p>
                            <p className='answer'><i>
                                Some restrictions apply.
                            </i>
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem style={{ marginBottom: 26 }}>
                        <AccordionItemHeading>
                            <AccordionItemButton className="question">
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <img className="arrow" src={require('./../../assets/images/arrow.png')} style={{ height: 16, width: 10, marginRight: 18 }} />
                                    <p className='question' style={{ margin: 0 }}>What are the rules of the Mirror World sweepstakes?</p>
                                </div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p className='answer'>
                                Eligible participants who register for the Vatom wallet account, will automatically receive one (1) entry  into the sweepstakes. Once you register and enter the sweepstakes, you’ll want to complete the following tasks to increase your odds of a winning a prize:
                                <br /><br />1. Explore the virtual space and collect all (6) digital collectibles
                                <br />2. Finding the (3) collectibles located in the Unlimited Reality, 3D Content Factory, and Advanced Connectivity rooms will unlock access to a secret room, where you can then collect the (1) special Easter Egg digital collectible
                                <br />Each additional digital collectible you collect awards you one additional entry, whereas the Easter Egg counts as two additional entries. The Unlimited Reality-themed prizes will include: a Magic Leap® 2 headset, a Meta Quest™ Pro headset, and two Meta Quests 2 headsets.
                                <br /><br />Learn more about the official rules for the Mirror World Sweepstakes <a href="https://resources.vatom.com/a8BxS4bNj9/Sweepstakes_rules_Form_Non-employee_5-4-20_MWC_Event.pdf" target="_blank">here</a> (PDF)
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem style={{ marginBottom: 26 }}>
                        <AccordionItemHeading>
                            <AccordionItemButton className="question">
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <img className="arrow" src={require('./../../assets/images/arrow.png')} style={{ height: 16, width: 10, marginRight: 18 }} />
                                    <p className='question' style={{ margin: 0 }}>Am I able to sell or trade my digital collectibles?</p>
                                </div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p className='answer'>
                                At this time, digital collectibles cannot be sold or traded. The digital collectibles are for personal, non-commercial use only.                        </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem style={{ marginBottom: 26 }}>
                        <AccordionItemHeading>
                            <AccordionItemButton className="question">
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <img className="arrow" src={require('./../../assets/images/arrow.png')} style={{ height: 16, width: 10, marginRight: 18 }} />
                                    <p className='question' style={{ margin: 0 }}>Who do I contact if I have issues?</p>
                                </div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p className='answer'>
                                Please visit <a href="https://support.vatom.com/hc/en-us">https://support.vatom.com/hc/en-us</a> for troubleshooting support.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        </div>
    )
}

export default FAQ;