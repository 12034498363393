import './homebutton.styles.scss'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import Analytics from '../../../../common/Analytics'
import { useBusiness } from '../../../../App'

const Button = (props) => {
    const history = useHistory()
    const business = useBusiness();

    const handleExternalLink = (url) => {
        const { id, defaultCampaignId } = business
        Analytics.event("openURL", {
            url,
            businessId: id,
            campaignId: defaultCampaignId,
        });
    }

    const { nftLink, isExternal, newTab, style, slug, children } = props

    return (
        <>
        {nftLink && isExternal
            ? <a 
                className='post'  
                href={nftLink} 
                target={newTab ? '_blank' : '_self'} 
                rel="noopener noreferrer" 
                style={style} 
                onClick={() => handleExternalLink(nftLink)}
            >{children}</a>
            : <Link 
                className='post' 
                style={style}
                to={`${history.location.pathname}/${slug}`}
            >{children}</Link>
        }
        </>
    )
}

const HomeButton = (props) => {
    const { acf: {button, nft_link, is_external, new_tab}, slug } = props.homeButton
    const { title, title_position, sub_title, sub_title_position, cta, cta_position, cta_class, background } = button

    return (
        <Button
            style={{
                height: '134px',
                backgroundImage: `url("${background.url}")`,
                cursor: 'pointer'
            }}
            slug={slug}
            nftLink={nft_link}
            isExternal={is_external}
            newTab={new_tab}
        >
            {sub_title !== "" 
                ? <p className={`subtitle ${sub_title_position} ${cta_class}`}>{sub_title}</p> 
                : null
            }
            <p className={`title ${title_position}`} dangerouslySetInnerHTML={{ __html: title }} />
            <div className={`cta ${cta_position} ${cta_class}`}>
                <p className='button-text' dangerouslySetInnerHTML={{ __html: cta }} />
                <img alt="button logo"
                    src={require(cta_class === 'big' ? './../../assets/images/Arrow-Right-Big.png' : './../../assets/images/button-logo.png')}
                    className="small-arrow"
                />
            </div>
        </Button>
    )
}

export default HomeButton;