import { LazyMotion, domAnimation, m } from "framer-motion"

const MotionDiv = WrappedComponent => ({ isLoading, ...otherProps }) => (
    <LazyMotion features={domAnimation} strict>
        <m.div 
            initial={{scale: .9, opacity: 0}} 
            animate={{scale: 1, opacity: 1}} 
            transition={{ ease: "easeInOut", duration: .25 }}
        >
            <WrappedComponent {...otherProps} />
        </m.div>
    </LazyMotion>
)

export default MotionDiv 