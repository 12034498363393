//
// Displays pixie dust animation during loading

import React from 'react'
import Particle from './Particle'

type ConfettiLoaderProps = {
    particleInterval: number,
}

type ConfettiLoaderState = {

}

export default class ConfettiLoader extends React.Component<ConfettiLoaderProps, ConfettiLoaderState> {
    particleInterval: any
    particleSway: number
    particleLifetime: number
    particleSpeed: number
    canvas: any
    ctx: any
    particles: Particle[]
    lastParticleTime: number
    lastFrameTime: number
    CanvasW: 300
    CanvasH: 300
    constructor(props: any) {

        super(props)

        // Properties
        this.particleInterval = this.props.particleInterval
        this.particleSway = 100
        this.particleLifetime = 10 * 500
        this.particleSpeed = 300
        this.canvas = null
        this.ctx = null
        this.particles = []
        this.lastParticleTime = 0
        this.lastFrameTime = 0
        this.state = {}
        this.CanvasW = 300
        this.CanvasH = 300

        // Pre-bind some functions
        this.renderCanvas = this.renderCanvas.bind(this)
        this.onResize = this.onResize.bind(this)

    }

    /** Render the react component */
    render() {

        return <canvas width={this.CanvasW} height={this.CanvasH} ref='canvas' style={{ display: 'absolute', top: 0, left: 0, width: '417px !important', height: '380px !important' }} />

    }

    /** On React component mount */
    componentDidMount() {
        // Fetch canvas element
        this.canvas = this.refs['canvas']
        this.ctx = this.canvas.getContext('2d')

        // Add resize listener
        window.addEventListener('resize', this.onResize)
        this.onResize()

        // Start render loop
        this.renderCanvas()

    }

    /** On React component remove */
    componentWillUnmount() {

        // Clear canvas element
        this.canvas = null
        this.ctx = null

        // Remove listeners
        window.removeEventListener('resize', this.onResize)

    }

    /** Called when the view size changes */
    onResize() {

        // Stop if not loaded yet
        if (!this.canvas)
            return

        // Set canvas size
        let scale = 1.5// window.devicePixelRatio || 1
        let size = this.canvas.getBoundingClientRect()
        this.canvas.width = size.width * scale
        this.canvas.height = size.height * scale

    }

    renderCanvas() {
        // Stop if no canvas

        if (!this.canvas)
            return


        // Do again on next render loop
        requestAnimationFrame(this.renderCanvas)

        // Calculate delta
        let delta = Math.min(0.5, (Date.now() - this.lastFrameTime) / 1000)
        this.lastFrameTime = Date.now()

        // Clear canvas
        this.ctx!.clearRect(0, 0, this.canvas.width, this.canvas.height)

        this.canvas.width = 600;
        this.canvas.height = 600;
        // Draw each particle
        for (var i = 0; i < this.particles.length; i++) {

            // Draw particle
            var particle = this.particles[i]
            particle.draw(this.ctx, delta)



            // Remove dead particles
            if (particle.dead)
                this.particles.splice(i--, 1)

        }

        // Check if it's time to create a new particle
        if (Date.now() - this.lastParticleTime > this.particleInterval) {


            // Create a particle
            var particle2 = new Particle(
                Math.random() * this.particleLifetime,
                Math.random() * this.canvas.width, -50, 1, // window.devicePixelRatio || 1,
                Math.random() * this.particleSway - this.particleSway / 2, Math.random() * this.particleSpeed
            )

            // Add it
            for (var j = 0; j < 10; j++) {
                this.particles.push(new Particle(
                    Math.random() * this.particleLifetime,
                    Math.random() * this.canvas.width, -50, 1, // window.devicePixelRatio || 1,
                    Math.random() * this.particleSway - this.particleSway / 2, Math.random() * this.particleSpeed
                ))
            }


        }

    }

}
