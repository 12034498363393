import './photo-collections.styles.scss'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { selectPhotoCollections } from '../../redux/posts/posts.selectors';

import PhotoCollectionsContainerPage from './photo-collections.container';
import PhotoCollectionSinglePage from '../photo-collections-single/photo-collections-single.component';

const PhotoCollectionsPage = ({photoCollections, match}) => (
    <>
        <Route exact path={`${match.path}`}>
            <PhotoCollectionsContainerPage photoCollection={photoCollections}/>
        </Route>
        <Route path={`${match.path}/:slug`}>
            <PhotoCollectionSinglePage photoCollection={photoCollections}/>
        </Route>
    </>
)

const mapStateToProps = createStructuredSelector({   //state = the root reducer
    photoCollections: selectPhotoCollections,
})

export default connect(mapStateToProps)(PhotoCollectionsPage)