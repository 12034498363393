import "./daily-game.styles.scss";
import MotionDiv from "../../components/motion-div/motion-div.component";
import { useCallback, useEffect, useState } from "react";
import { getAccessToken, useCampaignUserInfo } from "../../../../auth";
import { useBusiness } from "../../../../App";
import { Redirect, useHistory } from "react-router-dom";
import { useOS } from "../../../../hooks/responsive";
import useQuery from "../../../../hooks/query";

const games = [
	{
		type: 'ar',
		detail: '/b/lays/find?object_definition_id=Y5zipwogPx&auto_claim=true&sync=true'
	},
	{
		type: 'cataboom',
		detail: 'world-cup-quiz'
	},
	{
		type: 'cataboom',
		detail: 'world-cup-scratch'
	},
	{
		type: 'cataboom',
		detail: 'world-cup-quiz'
	}
]
const DailyGame = () => {
	const history = useHistory();
	const [gameUrl, setGameUrl] = useState<string>();
	const accessToken = getAccessToken()
	const business = useBusiness()
	const os = useOS();
	const query = useQuery();
	const { data: userInfo, isValidating } = useCampaignUserInfo();

	const businessId = business?.id
	const campaignId = business?.defaultCampaignId

	if (localStorage.getItem("wizardState") != " ")
		localStorage.setItem("wizardState", "5");

	const getGameUrl = useCallback(async (cataboomCampaign: string) => {

		if (!accessToken || !businessId || !campaignId) return

		let host = "https://providers.api.vatominc.com";
		const callRes = await fetch(`${host}/me/cataboom/url?cataboomCampaignId=${cataboomCampaign}&campaignId=${campaignId}&businessId=${businessId}`, {
			headers: new Headers({
				Authorization: `Bearer ${accessToken}`,
			}),
			method: "GET",
		});
		const resp = await callRes.json();
		setGameUrl(resp.url);

	}, [accessToken, businessId, campaignId]);

	const processMessage = (msg: any) => {
		if (msg.origin !== window.origin) return;
      	if (msg.data === "close") {
			history.push('/b/lays')
		}
	}

	// let customHeight = window.innerHeight;
	// const convertStyle = () => {
	// 	customHeight = window.innerHeight;
	// };

	useEffect(() => {

		if (isValidating)
			return 

		// Set it up so that the form renders once the game has finished - this needs to be more robust
		if(!userInfo?.form_submitted)
			localStorage.setItem('formOk', 'tocheck');

		const chicagoDate = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' } ))	
		const dayOfYearInChicago = (Date.UTC(chicagoDate.getFullYear(), chicagoDate.getMonth(), chicagoDate.getDate()) - Date.UTC(chicagoDate.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000
		const dayOfCampaign = dayOfYearInChicago - 286
		const prizeDay = dayOfCampaign % 4

		console.info("GAME CALC", chicagoDate, dayOfYearInChicago, dayOfCampaign, prizeDay)

		const selectedGame = query.get('game')
		if (selectedGame) {
			if (selectedGame === 'ar' && (os === 'Android' || os === 'iOS')) {
				history.push("/b/lays/find?object_definition_id=Y5zipwogPx&auto_claim=true&sync=true")
			} else if (selectedGame === 'scratch') {
				getGameUrl("world-cup-scratch")
			} else {
				getGameUrl("world-cup-quiz")
			}
		} else {
			if (prizeDay === 0) {
				if (os === 'Android' || os === 'iOS')
					history.push("/b/lays/find?object_definition_id=Y5zipwogPx&auto_claim=true&sync=true")
				else 
					getGameUrl("world-cup-scratch")
			} else if (prizeDay === 1) {
				getGameUrl("world-cup-quiz")
			} else if (prizeDay === 2) {
				getGameUrl("world-cup-scratch")
			} else if (prizeDay === 3) {
				getGameUrl("world-cup-quiz")
			}
		}
		
		window.addEventListener("message", processMessage)
		// window.addEventListener("resize", convertStyle);
		// window.addEventListener("DOMContentLoaded", convertStyle);


		return () => {
			window.removeEventListener("message", processMessage)
			// window.removeEventListener("resize", convertStyle);
			// window.removeEventListener("DOMContentLoaded", convertStyle);
		}
	}, [isValidating, userInfo, getGameUrl]);
	
	if (!gameUrl) return null;

	return (
		<div className="landing-page  animationpage">
			<div className="overlay-light"></div>
			

			<iframe src={gameUrl} className="explore-animation-frame imframeheight" />
		</div>
	);
};
export default MotionDiv(DailyGame);
