import { combineReducers } from 'redux';

import postsReducer from './posts/posts.reducer';
import pagesReducer from './pages/pages.reducer';
import arShopSingleReducer from './ar-shop-single/ar-shop-single.reducer';

export default combineReducers({
    pages: pagesReducer,
    posts: postsReducer,
    arShopSingle: arShopSingleReducer,
});