import axios from 'axios'
import { useEffect, useCallback } from 'react'
import { useHistory } from 'react-router'
import Cookies from "js-cookie";

import { basePath } from '../../utils'

const Live = () => {
    const history = useHistory();

    const redirectToLiveLocation = useCallback((locations) => {
        if (!locations) {
            history.push(basePath) 
            return
        }
        const redirectLink = getRedirectLink(locations, history.location.pathname)
        history.push(redirectLink) 
    }, [history])

    const getLocations = useCallback(async () => {
        const response = await axios.get('https://cms.vatom.com/wp-json/event/v1/event-locations/jingle-ball/locations')
        .then(({data}) => data)
        .catch(() => null)

        return response
    }, [])

    useEffect(() => {
        if (Cookies.get('location')) {
            history.push(`${basePath}/live/${Cookies.get('location')}`)
            return
        }
        const startAsync = async () => {
            const locations = await getLocations()
            redirectToLiveLocation(locations)
        }
        startAsync()
    }, [getLocations, redirectToLiveLocation, history])

    return null
}

const getRedirectLink = (locations, path) => {
    let redirectLink = basePath
    const nowDate = new Date().getTime();   //get current UTC timestamp
    
    for (let location of locations) {
        const { live_dates, post_slug } = location

        if (live_dates === null) {
            continue
        }

        const startDate = getUTCTimestamp(live_dates.start);
        const endDate = getUTCTimestamp(live_dates.end);

        if (!isLive(nowDate, startDate, endDate)) {
            continue
        }

        const deleteDate = new Date(new Date().getTime() + (endDate - nowDate));
        Cookies.set('location', post_slug, {
            expires: deleteDate
        });

        redirectLink = `${path}/${post_slug}`
        break
    }

    return redirectLink
}

const getUTCTimestamp = (date) => {
    const convertDate = new Date(date.replace(/-/g, "/"))
    const timeZoneOffset = convertDate.getTimezoneOffset() * 60000
    return convertDate - timeZoneOffset
}

const isLive = (nowDate, startDate, endDate) => {
    return nowDate >= startDate && nowDate <= endDate
}

export default Live;