import { useState } from 'react';
import './photo-collections-single.styles.scss'

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper';
import { useParams } from 'react-router-dom';

import PageHeader from '../../components/page-header/page-header.component';
import MotionDiv from '../../components/motion-div/motion-div.component';
// import Button from '../../components/button/button.component';

const PhotoCollectionSinglePage = ({photoCollection}) => {
    const params = useParams();
    const [newIndex, setIndex] = useState(photoCollection.findIndex(object => object.slug === params.slug));
    const [yearIndex, setyearIndex] = useState(photoCollection.map((c, i) => ({ yearInd: 0 })));

    const handleChange = ind => {
        setyearIndex(prevState => ({
            ...prevState,
            [newIndex] : {
                yearInd: ind
            }
        }));
    };

    const gallery = photoCollection[newIndex]
    const { acf : { section_gallery } } = gallery;

    return (
        <div className='photo-collections-single-page'>
            <PageHeader title="Past Photos"/>
            <div className='collection-sections page-content'>
                <Swiper
                    slideToClickedSlide={true}
                    grabCursor={true}
                    slidesPerView={'auto'}
                    spaceBetween={8}
                    onClick={(swiper) => setIndex(swiper.clickedIndex)}
                >
                {
                photoCollection.map((collection, i) => (
                    <SwiperSlide key={i}>
                        <div className={`section ${newIndex === i ? 'active' : ''}`}>
                            {collection.title}
                        </div>
                        <div className='years'>
                            {
                            collection.acf.section_gallery ?
                            collection.acf.section_gallery.map((year, index) => (
                                <span 
                                    key={index} 
                                    className={yearIndex[i].yearInd === index ? 'year-active' : ''}
                                    onClick={() => handleChange(index)}
                                >‘{year.gallery_year.toString().slice(2)}</span>
                            ))    
                            : null
                            }
                        </div>
                    </SwiperSlide>
                ))
                }
                </Swiper>
            </div>

            <div className='selected-collection'>
                {
                section_gallery.map((yearCol, index) => (
                    yearIndex[newIndex].yearInd === index ? 
                    <Swiper
                        modules={[EffectCoverflow]}
                        pagination = { {type: 'bullets'} }
                        effect={"coverflow"}
                        direction={'verical'}
                        coverflowEffect= {
                            {
                                rotate: 0,
                                stretch: 100,
                                depth: 90,
                                modifier: 1,
                                slideShadows: true
                            }
                        }
                        loop={true}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={'auto'}
                        key={index}
                    >
                    {
                    yearCol.gallery.map((img, index) => (
                        <SwiperSlide key={index}>
                            <div className='image-holder' style={{backgroundImage: `url("${img.url}")`}} alt={img.alt}>
                                <div className='image-caption'>
                                    <p>{img.alt} &bull; Wango Tango {yearCol.gallery_year}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))
                    }
                    </Swiper>
                    : null
                    ))    
                }
                {/* <Button className="button">ADD COLLECTION TO WALLET</Button> */}
            </div>
        </div>
    )
}

export default MotionDiv(PhotoCollectionSinglePage)