import './slides.scss'

export default function Slide3() {

    const sendEmail = () => {
        window.location.href = "mailto:fritolay-support@vatom.com";
    }
    return (
        <img
            src={require('../../assets/images/Frame 275.png')}
            className="center-img mt-15 mb-15 front"
            onClick={sendEmail}
        />

    )
}
