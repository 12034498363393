import './countrySelector.styles.scss'
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import SelectCountry from '../../components/select-country/SelectCountry'
import { useCampaignUserInfo, useUpdateUserInfo } from '../../../../auth'
import { useBusiness } from "../../../../App";
import MotionDiv from '../../components/motion-div/motion-div.component';
import { motion, useAnimation } from "framer-motion"
import ConfettiLoader from "../../pages/animations/ConfettiLoader"
import Analytics from '../../../../common/Analytics';
import { Link } from "react-router-dom";


const CountrySelector = () => {
	const history = useHistory()
	const business = useBusiness();
	const businessId = business!.id;
	const campaignId = business!.defaultCampaignId;
	const { data: userInfo } = useCampaignUserInfo(businessId, campaignId);
	const updateUserInfo = useUpdateUserInfo({ businessId, campaignId });
	const [flag, setFlag] = useState(userInfo?.pepsi_team);
	const [isopen, setIsOpen] = useState(false);
	const [isopentwo, setIsOpenTwo] = useState(false);
	const [isopenVideo, setIsOpenVideo] = useState("hide-video");
	useEffect(() => { setFlag(userInfo?.pepsi_team) }, [userInfo]);
	localStorage.setItem('wizardState', '0');

	const MoveNext = () => {
		localStorage.setItem('wizardState', '2');
		history.push(`/b/lays/selfie/before`);
	}

	const onSubmit = () => {

		if (flag) {
			userInfo.pepsi_team = flag;
			console.log(userInfo);
			updateUserInfo(userInfo);
			setIsOpen(true);

			Analytics.event('buttonClick', {
				name: 'team',
				value: flag
			})

			controls.start({
				opacity: 0,
				transition: { duration: 0.5, delay: 1 },
			})

			controlsbutton.start({
				opacity: 1,
				transition: { duration: 0.5, delay: 3 },
			})

			localStorage.setItem('wizardState', '2');

			history.push('/b/lays/countries/select#game');
			setTimeout(function () { //Start the timer
				setIsOpenTwo(true);
				setIsOpenVideo("show-video");
			}.bind(this), 2000)

		}
	}

	const pageTransition2 = {
		type: "tween",
		ease: "anticipate",
		duration: 1
	};
	const variants = {
		closed: { opacity: 0, y: "800px", transition: { duration: 0.1, type: "bounce", ease: "anticipate" } },
		open: { opacity: 1, x: 0, height: "100%" },
	}
	const controls = useAnimation();
	const controlsbutton = useAnimation();



	return (

		<div className='country-page'>
			<div className={isopenVideo}>
				<ConfettiLoader particleInterval={5} />
			</div>
			<div className="overlay"></div>
			<div className='page-wrapper customheight z4'>

				<motion.div
					layout
					data-isopen={isopen}
					className="parent"
					transition={pageTransition2}
					animate="loaded">
					<SelectCountry selected={flag} onSelect={(name: any) => setFlag(name)} />
				</motion.div>
				<motion.div
					layout
					data-isopentwo={isopentwo}
					className="screentwo"
					animate={isopentwo ? "open" : "closed"}
					variants={variants}>
					<div className="front text-white fs-32 fw-900 fw-bold text-center mb-10 mt-20"><h2>You’re in the Game</h2></div>
					<div className="front text-white fs-16 fw-900 pl-4 pr-4 text-center"><p>By registering you’ve earned one entry for a chance to WIN amazing swag.</p>
						<Link to="/b/lays/prizes"><p className='gold-color'>See prizes</p></Link></div>
					<img alt="game icon" className="mw-300 center-img mt-20 img-height front" src={require('../../assets/images/Group 1171274929.png')} />

					<motion.div
						className="hiddenButton"
						animate={controlsbutton}>

						<div className='text-center front flex-centered nextCountry buttonBut'>
							<p className="text-white front fs-24 text-center mt-1">Welcome to the team!</p>
							<button
								className="btn btn-gold w-100 fw-bold"
								style={{ cursor: 'pointer' }}
								onClick={MoveNext}>
								<p className="fs-17 fw-bold">LET'S PLAY</p>
							</button>
						</div>
					</motion.div>
				</motion.div>

				<motion.div
					layout
					data-isopen={isopentwo}
					className="ChildButton"
					animate={controls}>
					<div className='text-center front flex-centered nextCountry'>
						<button
							className="btn btn-gold w-100 fw-bold"
							style={{ cursor: 'pointer' }}
							onClick={onSubmit}>
							<p className="fs-17 fw-bold">NEXT</p>
						</button>
					</div>
				</motion.div>
			</div>
		</div>

	)
}
export default MotionDiv(CountrySelector)
