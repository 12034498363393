import { useHistory } from "react-router-dom";
import { getAccessToken, useCampaignUserInfo, useUserId } from "../../../../../auth";
import "../explore.styles.scss";
import MotionDiv from "../../../components/motion-div/motion-div.component";
import { useEffect, useState } from "react";
import { useBusiness } from "../../../../../App";


const ExploreAnimation = () => {
	// const [customHeight, setCustomHeight] = useState(window.innerHeight - 179); //all height - footer and header

	// const convertStyle = () => {
	// 	setCustomHeight(window.innerHeight - 179)
	// };

	const [keyboardState, setKeyboardState] = useState("closed");
	const mystyle = {
		height: window.innerHeight - 179
	}

	const keyboardShowHandler = () => {
		setKeyboardState('open')
	}

	const keyboardHideHandler = () => {
		console.log('inchis')
	}

	// window.addEventListener("resize", convertStyle);
	// window.addEventListener("DOMContentLoaded", convertStyle);

	window.addEventListener('native.showkeyboard', keyboardShowHandler);

	window.addEventListener('native.hidekeyboard', keyboardHideHandler);

	const history = useHistory();
	const userId = useUserId();
	const business = useBusiness();
	const accessToken = getAccessToken()
	const { data, isValidating } = useCampaignUserInfo();

	const [frameSource, setFrameSource] = useState<string>();

	const campaignId = business?.defaultCampaignId

	useEffect(() => {
		if (isValidating || !userId || !campaignId) return;

		if (data.picture) {
			setFrameSource(
				`https://vatom-ball.vatom.com/?user=${userId}&campaign=${campaignId}&token=${accessToken}`
			);
		} else {
			setFrameSource(`https://vatom-ball.vatom.com/?campaign=${campaignId}&token=${accessToken}`);
		}
	}, [data, isValidating, userId]);



	if (!frameSource) return null;

	return (
		<div className="landing-page animationpage">
			<div className="overlay-light"></div>
			{/* <p>{customHeight}</p> */}
			<iframe
				title="Follow Ball"
				src={frameSource}
				className="explore-animation-frame"
				style={mystyle}
			/>
		</div>
	);
};
export default MotionDiv(ExploreAnimation);
