import './footer.styles.scss'
import { useRef, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom";

import FooterHomeIcon from '../../assets/images/footer_home_icon.svg'
import FooterHomeIconActive from '../../assets/images/footer_home_icon_active.svg'

import FooterWalletIcon from '../../assets/images/footer_wallet_icon.svg'
import FooterWalletIconActive from '../../assets/images/footer_wallet_icon_active.svg'

import { basePath } from '../../utils'

const Footer = (props) => {
    const { pathname }  = useLocation()
    const footer = useRef()

    useEffect(() => {
		props.setPaddBottom(footer.current.clientHeight);
	}, [props, pathname]);

    let homeButtonClass = ''
    let homeButtonLink = basePath
    const splitPathname = pathname.split('/')
    if (splitPathname.length === 3) {
        homeButtonClass = 'active'
    } 
    if (pathname.search('live') !== -1) {
        homeButtonClass = splitPathname.length === 5 ? 'active' : ''
        homeButtonLink = splitPathname.length > 5 ? splitPathname.slice(0, -1).join('/') : pathname
    }

    return (
        <div className='footer' ref={footer}>
            <ul>
                <li>
                    <Link to={homeButtonLink} className={homeButtonClass}>
                        <img className="inactive" src={FooterHomeIcon} alt=""/>
                        <img className="active" src={FooterHomeIconActive} alt=""/>
                        <span>Home</span>
                    </Link>
                </li>
                <li>
                    <Link to={`${basePath}/wallet/`}>
                        <img className="inactive" src={FooterWalletIcon} alt=""/>
                        <img className="active" src={FooterWalletIconActive} alt=""/>
                        <span>Wallet</span>
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default Footer