import { WebcamCapture } from '../../../components/webcam-capture/WebcamCapture';
import MotionDiv from '../../../components/motion-div/motion-div.component';
let customHeight = window.innerHeight;
const convertStyle = () => {
  customHeight = window.innerHeight;
};
window.addEventListener("resize", convertStyle);
window.addEventListener("DOMContentLoaded", convertStyle);


const SelfieCamera = () => {
  return (
    <div className="selfie-after-page align-top">
      <div className='page-wrapper w-100'>
        <div className="overlay customheight"></div>

        <WebcamCapture
          className="center-img front" />

      </div>
    </div>
  )
}

const videoConstraints = {
  width: 200,
  height: 200,
  facingMode: "user"
};

export default MotionDiv(SelfieCamera)