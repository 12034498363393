import './highlights.styles.scss'

import { Route } from 'react-router-dom'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { selectHighlights } from '../../redux/posts/posts.selectors';

import HighlightsContainerPage from './highlights.container';
import HighlightsSinglePage from '../highlights-single/highlights-single.component'

const HighlightsPage = ({highlights, match}) => (
    <>
        <Route exact path={`${match.path}`}>
            <HighlightsContainerPage highlights={highlights}/>
        </Route>
        <Route path={`${match.path}/:slug`}>
            <HighlightsSinglePage highlights={highlights}/>
        </Route>
    </>
)

const mapStateToProps = createStructuredSelector({   //state = the root reducer
    highlights: selectHighlights,
})

export default connect(mapStateToProps)(HighlightsPage)