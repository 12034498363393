import { useHistory } from 'react-router-dom';
import Copyright from '../../../components/copyright/copyright';

import PageHeading from '../../../components/page-heading/PageHeading';

import '../explore.styles.scss'

export default function ExploreFollow() {
    const history = useHistory()

    const handleRouting = () => {
      history.push('/b/lays/explore/animation')
    }
return (
        <div className='landing-page lays'>
          <div className="overlay-light"></div>
          <div className='page-wrapper-explore center-img flex-centered pt-10 mb-0' >

              <PageHeading 
                title="Follow the Ball"/>
          </div>

            <div 
                className='text-center front flex-centered'
                style={{marginTop:'-20px', borderRadius:'100'}}>
                <button
                    className="btn btn-gold w-100 mw-342 fw-bold"
                    onClick={handleRouting}
                    style={{cursor:'pointer'}}>
                    <p className="fs-17 fw-bold">CONTINUE</p>
                </button>
            </div>
        </div>
      )
}
