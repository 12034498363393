import '../selfie.styles.scss'
import { useHistory } from 'react-router-dom';
import PageHeading from '../../../components/page-heading/PageHeading'
import MotionDiv from '../../../components/motion-div/motion-div.component';

let customHeight = window.innerHeight;
const convertStyle = () => {
  customHeight = window.innerHeight;
};
window.addEventListener("resize", convertStyle);
window.addEventListener("DOMContentLoaded", convertStyle);

const SelfieBefore = () => {
  const history = useHistory()

  const handleRouting = () => {
    history.push('/b/lays/selfie/middle')
  }
  const handleRoutingHome = () => {
    localStorage.setItem('wizardState', '3');
    history.push('/b/lays/pass/1')
  }

  return (
    <div className='selfie-before-page customheight customheight-fixed'>
      <div className="overlay-ball customheight"></div>
      <div className='page-wrapper'>
        <img
          src={require('../../../assets/images/Soccer_Ball_v02 1.png')}
          alt="Pass the Ball Challenge"
          className="center-img mt-20 img-height front" />
        <div className="mustContent">
          <PageHeading
            title="Join the Team"
            subtitle="Be part of the Golden World Soccer Ball uniting people together. Are you ready for your selfie?" />


          <div className='text-center front flex-centered mt-20'>
            <button
              className="btn btn-gold w-100 fw-bold"
              onClick={handleRouting}
              style={{ cursor: 'pointer' }}>
              <p className="fs-17 fw-bold">ENTER</p>
            </button>
            <br />
            <button
              className="btn btn-outline-gold w-100 fw-bold"
              onClick={handleRoutingHome}
              style={{ cursor: 'pointer' }}>
              <p className="fs-17 fw-bold">SKIP</p>
            </button>
            <div className="swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal bottom-pagination mt-10 mb-0">
              <span className="swiper-pagination-bullet  swiper-pagination-bullet-active"></span>
              <span className="swiper-pagination-bullet"></span>
              <span className="swiper-pagination-bullet"></span>
              <span className="swiper-pagination-bullet"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default MotionDiv(SelfieBefore)