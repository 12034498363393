import LocationActionTypes from './location.types'
import axios from 'axios';

export const fetchLocationStart = () => ({
    type: LocationActionTypes.FETCH_LOCATION_START
})

export const fetchLocationSuccess = (posts) => ({
    type: LocationActionTypes.FETCH_LOCATION_SUCCESS,
    payload: posts
})

export const fetchLocationFailure = (errorMessage) => ({
    type: LocationActionTypes.FETCH_LOCATION_FAILURE,
    payload: errorMessage
})

export const fetchLocationStartAsync = (location) => {
    return (dispatch) => {
        dispatch(fetchLocationStart())  //start fetching the data
        axios.get(`https://cms.vatom.com/wp-json/event/v1/event-posts/jingle-ball/${location}`)
            .then(res => {
                dispatch(fetchLocationSuccess(res.data)) //once is done, update the state via this action
            })
            .catch(err => dispatch(fetchLocationFailure(err.message))) //if there's an error update the state with error message
    }
}