export default function FlagImage({ src, countryName, selected, onSelect }: any) {
  return (
    <div
      style={{ display: 'inline' }}
      className="buttoner">
      <div
        style={{ display: 'inline-block' }}
        className="text-white">
        <img
          src={src}
          alt=''
          className="mb-0 me-16 country-flag h-91 w-91"
          onClick={onSelect} />
        {selected && <i className={`fa fa-check checkmark`} />}
        <p className='text-center fs-17 mh-30'>{countryName}</p>
      </div>
    </div>
  )
}