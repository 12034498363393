import { useEffect } from "react";
import MyStuff from "../../components/mystuff/mystuff.component"
import HomeButton from "../../components/homeButton/homebutton.component"
import './home.styles.scss'
import { compose } from "redux";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { Route, useRouteMatch, useHistory, useParams } from "react-router-dom"

import { selectHomePageButtons, selectWalletButton } from '../../redux/posts/posts.selectors'

import { selectLocation } from '../../redux/locations/locations.selectors'

import GameLandingPage from '../game-landing/game-landing.component'
import MotionDiv from "../../components/motion-div/motion-div.component";

import { locationExists, basePath } from '../../utils'

const Home = (props) => {
    const { path } = useRouteMatch();
    const history = useHistory()
    const params = useParams()

    const { homePageButtons, location, walletButton } = props

    let pageButtons = homePageButtons
    let walletButtonObject = walletButton

    if (locationExists(params)) {
        const { home_page_buttons, wallet_button } = location
        pageButtons = home_page_buttons ? home_page_buttons : pageButtons
        walletButtonObject = wallet_button ? wallet_button : walletButtonObject
    }
    
    useEffect(() => {
        if (!locationExists(params)) {
            return
        }

        if (location.length === 0) {
            history.push(basePath)
            return
        }
    }, [history, location, params])

    return (
        <>
        <Route exact path={path}>
            <div className="home">
                <img
                    className="logo-img"
                    src='https://cms.vatom.com/wp-content/uploads/2022/10/JingleBall-Logo.png'
                    alt="logo"
                    style={{width: '50%'}}
                />

                {pageButtons 
                ? pageButtons.map((homeButton, index) => <HomeButton key={index} homeButton={homeButton}/>) 
                : null}

                {walletButtonObject 
                ? <MyStuff title={walletButtonObject.title} background={walletButtonObject.background}/> 
                : null}

                <img
                    src={require('./../../assets/images/motto.png')}
                    alt="motto"
                    style={{height:45, width:220, marginBottom:24}}
                />
            </div>
        </Route>
        <Route path={`${path}/:landingSlug`} component={GameLandingPage}/>
        </>
    )
}

const mapStateToProps = createStructuredSelector({
    homePageButtons: selectHomePageButtons,
    walletButton: selectWalletButton,
    location: selectLocation
});

export default compose(
    connect(mapStateToProps),
    MotionDiv
)(Home)