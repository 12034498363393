import { Text } from '@varius.io/wombo'
import { fieldPropsInterfaceOverride } from '../types';
import { mdToHtml } from "./helpers";

export const Subtitle = (props: fieldPropsInterfaceOverride) => {
  if (!props.field.subtitle) return null
  return (
    <Text
      renderAs="span"
      dangerouslySetInnerHTML={{ __html: mdToHtml(props.field.subtitle) }}
      style={{
        display: 'block',
        color: '#868E96',
        fontSize: '11px',
        marginTop: 0,
        marginBottom: '0.5rem',
      }}
    />
  )
}
