import './faq.styles.scss'

import { compose } from "redux";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { selectFaq } from '../../redux/posts/posts.selectors';

import MotionDiv from '../../components/motion-div/motion-div.component';

const FaqPage = ({faq : {header_image, questions_answers}}) => (
    <div className='faq-page'>
        <div className='faq-header image-holder' style={{backgroundImage: `url("${header_image.url}")`}}>
            <h1>FAQ</h1>
        </div>

        <div className='page-content'>
            {
            questions_answers.map((element, index) => (
                <div key={index} className="faq-element">
                    <h6>{element.question}:</h6>
                    <div dangerouslySetInnerHTML={{__html: element.answer}} />
                </div>
            ))
            }
        </div>
    </div>
)

const mapStateToProps = createStructuredSelector({   //state = the root reducer
    faq: selectFaq,
})

export default compose(
    connect(mapStateToProps),
    MotionDiv
)(FaqPage)