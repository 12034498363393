import '../prize.styles.scss'
import { useHistory } from 'react-router-dom';
import PageHeading from '../../../components/page-heading/PageHeading'


export default function ExitGame() {
    const history = useHistory()

    const handleRouting = () => {
        history.push('/b/lays/prize/exit')
    }

    return (
        <div className='landing-page'>
            <div className='page-wrapper'>
                <div className="overlay"></div>

                <PageHeading
                    title="Scratch the Card"
                    subtitle="Use your finger to reveal the card" />

                <div
                    className="gold-frame-2 front mt-51 mb-96">
                    <p className="question-mark">?</p>
                </div>


                <div className='text-center front flex-centered mt-5'>
                    <button
                        className="btn btn-gold w-100 fw-bold"
                        style={{ cursor: 'pointer' }}
                        onClick={handleRouting}>
                        <p className="fs-17 fw-bold">EXIT</p>
                    </button>
                </div>
            </div>
        </div>
    )
}
