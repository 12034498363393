import { useState } from 'react';
import './swiper-holder.styles.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import EventNavButton from '../swiper-nav-button/swiper-nav-button.component';
import SwiperButtons from './swiper-nav-buttons/swiper-nav-buttons.component';

const SwiperHolder = ({spaceBetween, slidesPerView, elements, title}) => {
    const [swiper, setSwiper] = useState(null);
    return (
        <div className='swiper-holder'>
            <h4>{title}</h4>
            <Swiper
                spaceBetween={spaceBetween}
                slidesPerView={slidesPerView}
                onSwiper={setSwiper}
                scrollbar={{ draggable: true }}
            >
                <SwiperButtons swiper={swiper}/>
                {
                elements.map((element, index) => (
                    <SwiperSlide key={index}>
                        <EventNavButton {...element} />
                    </SwiperSlide>
                ))
                }
            </Swiper>
        </div>
    )
}

export default SwiperHolder