export default function PageHeading({ title, subtitle }: any) {
  return (
    <>
      <div
        className='front text-white fs-32 fw-900 fw-bold text-center mb-10 mt-0'><h2>{title}</h2></div>
      <div
        className='front text-white fs-16 fw-900 pl-4 pr-4 text-center'><p>{subtitle}</p></div>
    </>
  )
}
