import React, { forwardRef, InputHTMLAttributes } from 'react'
import styled from 'styled-components'




export const StyledSelect = styled.select`
	display: flex;
	align-items: center;
	background-color: white;
	box-sizing: border-box;
	height: 42px;
	width: 100%;
	border-radius: 4px;
	font-family: 'Inter',sans-serif;
	border: 1px solid #C4C4C4;
	box-shadow: none;
	padding: 0 0.665rem;
	font-size: 1rem;
	line-height: 1.5rem;
	color: #343A40;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	outline: none;
`


export type typeInput = {}

export type Ref = HTMLInputElement;

export const TextField = forwardRef<Ref, InputHTMLAttributes<typeInput>>((props, ref) => {
	return <StyledInput {...props} ref={ref} />
})

const StyledInput = styled.input`
	display: flex;
	align-items: center;
	background-color: white;
	box-sizing: border-box;
	height: 42px;
	width: ${({ type }) => type === "checkbox" ? "auto" : "100%"};
	border-radius: 4px;
	font-family: 'Inter',sans-serif;
	border: 1px solid #C4C4C4;
	box-shadow: none;
	padding: 0 0.665rem;
	font-size: 1rem;
	line-height: 1.5rem;
	color: #343A40;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	outline: none;
`


export const usei18n = () => (key: string, ...args: any[]) => `i18n:${key}` // TODO

export const withErrorHandling = (onError: any, fn: any) => async (
	...args: any
) => {
	try {
		return await fn(...args)
	} catch (e) {
		onError?.(e)
	}
}

/** Converts Markdown to HTML. Supports Links. */
export const mdToHtml = (md: string) => {
	let html = ''

	while (md.length > 0) {
		const match = md.match(/^\[([^\]]*)\]\(([^)]*)\)/)
		if (match) {
			const [str, title, href] = match
			html += `<a href="${href}" target="_blank">${title}</a>`
			md = md.substring(str.length, md.length)
		} else {
			html += md[0]
			md = md.substring(1, md.length)
		}
	}

	return html
}