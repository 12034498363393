import React, { useEffect, useState } from "react";
import "./App.scss";

import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { connect } from "react-redux";
import { fetchPostsStartAsync } from "./redux/posts/posts.actions";
import { fetchPagesStartAsync } from "./redux/pages/pages.actions";
import { selectArePostsLoaded } from "./redux/posts/posts.selectors";
import {
	Switch,
	Route,
	useHistory,
	useLocation,
	Redirect,
} from "react-router-dom";

//components
// import Footer from './components/footer/footer.component';
import WithSpinner from "./components/with-spinner/with-spinner.component";
import { useBusiness } from "../../App";
import BusinessHeader from "../../routes/BusinessPage/BusinessHeader";
import Footer from "../../components/Footer";

//pages
import HomePage from "./pages/home/home.component";
import TicketsPage from "./pages/tickets/tickets.component";
import GuidePage from "./pages/guide/guide.component";
import FaqPage from "./pages/faq/faq.component";
import LineupPage from "./pages/lineup/lineup.component";
import MeetTheArtistPage from "./pages/meet-the-artist/meet-the-artist.component";
import PhotoCollectionsPage from "./pages/photo-collections/photo-collections.component";
import ArShopPage from "./pages/ar-shop/ar-shop.component";
import VideosPage from "./pages/video-page/video-page.component";
// import SpecialOfferPage from './pages/special-offers/special-offers.component';
import HighlightsPage from "./pages/highlights/highlights.component";
/* import LandingPage from '../../components/LandingPage/landingpage.component'; */
import NftPage from "./pages/nft-page/nft-page.component";

const ScrollToTop = () => {
	const history = useHistory();
	useEffect(() => {
		const unlisten = history.listen(() => window.scrollTo(0, 0));
		return () => unlisten();
	}, [history]);

	return null;
};

const AppContentHolder = () => {
	const [padd, setPadd] = useState(180);
	const business = useBusiness();
	const history = useHistory();
	const { pathname } = useLocation();

	const path = "/b/wango-tango";
	const footerProps = {
		history,
	};
	return (
		<div className="wango-tango" style={{ paddingTop: `${padd}px` }}>
			<BusinessHeader business={business} setMarginTop={setPadd} />

			{/* <Header /> */}
			<div className="app-holder">
				<ScrollToTop />
				<Switch>
					<Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
					<Route exact path={path} component={HomePage} />
					<Route path={`${path}/tickets`} component={TicketsPage} />
					<Route path={`${path}/guide`} component={GuidePage} />
					<Route path={`${path}/faq`} component={FaqPage} />
					<Route path={`${path}/lineup`} component={LineupPage} />
					<Route path={`${path}/videos`} component={VideosPage} />
					<Route
						path={`${path}/meet-the-artist`}
						component={MeetTheArtistPage}
					/>
					<Route
						path={`${path}/photo-collection`}
						component={PhotoCollectionsPage}
					/>
					<Route path={`${path}/highlights`} component={HighlightsPage} />
					<Route path={`${path}/ar-shop`} component={ArShopPage} />
					<Route path={`${path}/nft`} component={NftPage} />
					<Route
						path={`${path}/music`}
						component={() => {
							window.location.href =
								"https://www.iheart.com/playlist/wango-tango-playlist-312064750-SSzjTg8eunJsqD6mhyg5e7/";
							return null;
						}}
					/>

					{/* <Route path={`${path}/music`} component={MusicPage}/>  */}
					{/* <Route path={`${path}/landing-page-1`}>
              <LandingPage template="template-1"/>
            </Route> 
            <Route path={`${path}/landing-page-2`}>
              <LandingPage template="template-2"/>
            </Route> 
            <Route path={`${path}/landing-page-3`}>
              <LandingPage template="template-3"/>
            </Route> 
            <Route path={`${path}/landing-page-4`}>
              <LandingPage template="template-4"/>
            </Route> 
            <Route path={`${path}/landing-page-5`}>
              <LandingPage template="template-5"/>
            </Route> 
            <Route path={`${path}/landing-page-6`}>
              <LandingPage template="template-6"/>
            </Route>  */}
					{/* <Route path={`${path}/:slug`} component={SpecialOfferPage} />  */}
				</Switch>
			</div>
			{/* <Footer /> */}
			<Footer style={{ flex: "0 0" }} tab="home" bussinesId={business.id} {...footerProps} />
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	isLoading: selectArePostsLoaded,
});

const AppWithSpinner = compose(
	connect(mapStateToProps),
	WithSpinner
)(AppContentHolder);

class App extends React.Component {
	componentDidMount() {
		const { fetchPostsStartAsync, fetchPagesStartAsync } = this.props;
		fetchPagesStartAsync();
		fetchPostsStartAsync();
	}

	render() {
		return <AppWithSpinner />;
	}
}

const mapDispatchToProps = (dispatch) => ({
	fetchPostsStartAsync: () => dispatch(fetchPostsStartAsync()), //dispatch the function that starts fetching the data
	fetchPagesStartAsync: () => dispatch(fetchPagesStartAsync()), //dispatch the function that starts fetching the data
});

export default connect(null, mapDispatchToProps)(App);
