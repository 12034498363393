import App from "./App"
import { Provider } from 'react-redux';
import store from './redux/store';

const JingleBall = () => {

	return (
		<Provider store={store}>
			<App />
		</Provider>
	)
};

export default JingleBall;
