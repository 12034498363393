import ArShopSignleActionTypes from './ar-shop-single.types';

const INITIAL_STATE = {
    checked: false
};

const arShopSingleReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ArShopSignleActionTypes.CHECK:
            return{
                ...state,
                checked: !state.checked
            }
        default:
            return state
    }
};

export default arShopSingleReducer;