import React from 'react'
import { Link } from 'react-router-dom'
import './choices.scss'

const ChoicesPage = () => {
    return (
        <div className='choices-page'>
            <p className='title top-margin bottom-margin'>Your Travel <br /> Choices</p>

            <Link className='white-outline-button' to='/b/6xuDGWJ7fO/journey'>
                <p className='small-button-text'>Take the train</p>
            </Link>

            <Link className='white-outline-button' to='/b/6xuDGWJ7fO/journey'>
                <p className='small-button-text'>Delay Trip by an Hour</p>
            </Link>

            <Link className='white-outline-button' to='/b/6xuDGWJ7fO/journey'>
                <p className='small-button-text'>Carpool with TNC</p>
            </Link>
        </div>
    )
}

export default ChoicesPage;

