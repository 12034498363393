import './meet-the-artist.styles.scss'

import { compose } from "redux";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { selectArtistToMeet } from '../../redux/posts/posts.selectors';

import Button from '../../components/button/button.component';
import PageHeader from '../../components/page-header/page-header.component';
import MotionDiv from '../../components/motion-div/motion-div.component';

const MeetTheArtistPage = ({artistToMeet}) => {
    const { acf: { meet_the_artist_page } } = artistToMeet
    const { page_title, page_subtitle, page_content, page_image, page_button } = meet_the_artist_page
    return (
        <div className='meet-the-artist-page top-margin'>

            <PageHeader title={page_title} subtitle={page_subtitle}/>

            <div className='meet-the-artist-content page-content'>
                <div className='image-holder' style={{backgroundImage: `url("${page_image.url}")`}}></div>
                <p>{page_content}</p>
                <Button className="button">{page_button.text.toUpperCase()}</Button>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({   //state = the root reducer
    artistToMeet: selectArtistToMeet,
})

export default compose(
    connect(mapStateToProps),
    MotionDiv
)(MeetTheArtistPage)