import { createElement } from "react"
import { BooleanField } from "./BooleanField"
import { CountryAndRegionField } from "./CountryAndRegionField"
import { DateField } from "./DateField"
import { EmailField } from "./EmailField"
import { EnumField } from "./EnumField"
import { MultiBoolField } from "./MultiBoolField"
import { NumberField } from "./NumberField"
import { StringField } from "./StringField"
import { TelField } from "./TelField"
import { fieldPropsInterface, fieldsInterface } from "../types"
import { UnknownField } from "./UnknownField"

export const FIELDS: fieldsInterface = {
  boolean: BooleanField,
  countryAndRegion: CountryAndRegionField,
  date: DateField,
  email: EmailField,
  enum: EnumField,
  multibool: MultiBoolField,
  number: NumberField,
  string: StringField,
  tel: TelField,
}



export const Field = (props: fieldPropsInterface) => {
  const c = createElement(FIELDS[props.field.type as keyof typeof FIELDS] ?? UnknownField, props)
  // Quirk: Jingle Ball 2022
  if (props.field.name === 'jingleball_age') {
    return (
      <>
        {c}
        <span
          style={{
            display: 'block',
            fontWeight: 400,
            fontStyle: 'italic',
            fontSize: '10px',
            color: '#868E96',
            marginTop: '1rem',
          }}
        >
          {atob(
            'VGhlIGZvbGxvd2luZyBxdWVzdGlvbnMgd2lsbCBoZWxwIFBmaXplciBwcm92aWRlIHlvdSB3aXRoIHRoZSBsYXRlc3QgbmV3cyByZWdhcmRpbmcgQ09WSUQtMTkgYW5kIGEgQ09WSUQtMTkgdmFjY2luZSBvcHRpb24u',
          )}
        </span>
      </>
    )
  } else return c
}


