
import { ErrorMsg } from './ErrorMessage'
import { LabelForm } from './LabelForm'
import { StyledSelect } from "./helpers";
import { fieldPropsInterfaceOverride } from '../types';


export const EnumField = (props: fieldPropsInterfaceOverride) => {


	return (
		<div>
			<LabelForm {...props} />
			<StyledSelect
				name={props.field.name}
				required={props.field.required}
				value={props.value ?? ''}
				onChange={(e) => props.onChange(e.target.value)}
			>
				<option value="" disabled>
					{props.field.placeholder ?? "Please select"}
				</option>

				{props.field.options?.map(($: any) => (
					<option key={$.value} value={$.value}>
						{$.title}
					</option>
				))}
			</StyledSelect>
			<ErrorMsg {...props} />
		</div>
	)
}