
import { fieldPropsInterfaceOverride } from '../types';
import { ErrorMsg } from './ErrorMessage'

import { mdToHtml, TextField } from "./helpers";

export const BooleanField = (props: fieldPropsInterfaceOverride) => {


  return (
    <div>
      <div style={{ display: 'flex', marginTop: '1rem' }}>
        <TextField
          type="checkbox"
          name={props.field.name}
          required={props.field.required}
          value={props.value}
          onChange={(e: any) => props.onChange(e.target.checked)}
          style={{ width: 'auto' }}
        />
        <span className="checkmark">
          <i className="far fa-check"></i>
        </span>

        <span
          dangerouslySetInnerHTML={{
            __html: mdToHtml(props.field.title ?? props.field.name),
          }}
          style={{ flex: 1, marginLeft: '0.5rem' }}
        />
      </div>
      <ErrorMsg {...props} />
    </div>
  )
}
