/* eslint-disable react/function-component-definition */
import axios from 'axios'
import { useEffect, useState } from 'react'
import Errors from '../../../../common/Errors'
import './slides.scss'
import Select from 'react-select'
import { dates } from './dates'

type Game = {
  Date: string,
  IdMatch: string,
  IdSeason: string,
  IdStage: string,
  Home: {
    Score: number,
    Side: string,
    PictureUrl: string,
    Abbreviation: string,
    ShortClubName: string,
    IdTeam: string,
  },
  Away: {
    Score: number,
    Side: string,
    PictureUrl: string,
    Abbreviation: string,
    ShortClubName: string,
    IdTeam: string
  }
  HomeTeamPenaltyScore: string,
  AwayTeamPenaltyScore: string,
  Winner: string,
  MatchTime: string,
}

export default function Slide1() {
  const [state, setState] = useState("");
  const handleChange = (options: any) => {
    const date = new Date(options.value);
    const nextday = new Date(options.value);
    nextday.setDate(date.getDate() + 1);
    setStartDate(date);
    setEndDate(nextday);
    setState(options);
  };
  // const idCompetition = 17;

  const [startDate, setStartDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)))
  const [endDate, setEndDate] = useState(new Date(new Date().setHours(24, 0, 0, 0)))
  const [autoSelectedDay, setAutoSelectedDay] = useState({ label: `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear().toString().substr(-2)}`, value: `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}` })
  const [games, setGames] = useState<Game[]>([])
  const Color1 = "#000";
  const Color2 = "#000";
  const Color3 = "#000";

  const customStyles = {
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => ({
      ...styles,
      maxHeight: '135px',
      color: '#ffffff',
      backgroundColor: isDisabled
        ? Color1
        : isSelected
          ? Color2
          : isFocused
            ? Color3
            : undefined,
    }),
    menu: (provided: any) => ({
      ...provided,
      height: '35px',
      color: '#fff',
      background: "#353535",
      zIndex: 33,

    }),
    menuList: (provided: any) => ({
      ...provided,
      color: '#fff',
      background: "#353535",
      borderRadius: 5,
    }),

    input: (provided: any) => ({
      ...provided,
      height: '35px',
      padding: '0px',
    }),
    ValueContainer: (provided: any) => ({
      ...provided,
      height: '38px',
      padding: '0px',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: 'white',
      background: "#111111",
      height: '25px',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      background: "#111111",
      height: '25px',
      color: "#ffffff",
    }),
    IndicatorsContainer: (provided: any) => ({
      ...provided,
      height: '35px',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      padding: '0px',
      height: '27px',
    }),

    control: (base: any, state: any) => ({
      ...base,
      height: '38px',
      padding: '0 6px',
      marginLeft: '6px',
      background: "#111111",
      color: "#ffffff",

      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#111111" : "#111111",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#111111" : "#111111"
      }
    }),

  };

  const getGames = () => {
    axios.get(`https://api.fifa.com/api/v3/calendar/matches?from=${startDate.toISOString().split('.')[0] + "Z"}&to=${endDate.toISOString().split('.')[0] + "Z"}&idCompetition=17&language=en&count=20`)
      .then(res => {
        setGames([]);
        if (res.data.Results[0] !== undefined) {
          if (res.data.Results[0].Away !== null) {
            setGames(res.data.Results);
          }
        }
      })
      .catch(Errors.show)
  }
  useEffect(() => {
    getGames();
    const interval = setInterval(() => {
      getGames()
    }, 30000);
    return () => clearInterval(interval);
  }, [startDate, endDate])

  const renderMatch = (game: Game) => {
    var displayScore = false;
    var team1;
    var team2;
    var displayPenalty = false;
    const time = new Date(game.Date.toString()).toLocaleTimeString('default', { timeStyle: 'short', hour12: true });

    if (+game.AwayTeamPenaltyScore > 0 || +game.HomeTeamPenaltyScore > 0) {
      displayPenalty = true;
    }
    if (game.Winner != null) {
      if (game.Winner === game.Home.IdTeam) {
        team1 = 'winner'
      }
      else
        team2 = 'winner'
    }
    else {
      if (game.MatchTime === "0'") {
        team1 = 'winner'
        team2 = 'winner'
      }
    }
    if (game.Home.Score !== null || game.Away.Score !== null) {
      displayScore = true;
    }

    return (
      <div key={game.IdMatch}>
        <hr />
        <div className="match-information card-background">
          <div className='match'>{time}</div>
          <div className="information-container">
            <div className={`match ${team1}`}>{game.Home.ShortClubName}</div>
            {
              displayScore
                ? <div className='score'>{game.Home.Score}
                  {displayPenalty
                    ? <div>({game.HomeTeamPenaltyScore})</div>
                    : null
                  }

                </div>
                : null
            }
            <div className='match'>vs</div>
            {
              displayScore
                ? <div className='score'>
                  {game.Away.Score}
                  {displayPenalty
                    ? <div> ({game.AwayTeamPenaltyScore})</div>
                    : null
                  }
                </div>
                : null
            }
            <div className={`match ${team2}`}>{game.Away.ShortClubName}</div>
          </div>

        </div>
      </div>
    )
  }

  return (
    <div className='info-page-container'>
      <div className='date-selection'>
        <p className='date-label'>DATE: </p>
        <Select
          isSearchable={false}
          defaultValue={autoSelectedDay}
          options={dates}
          maxMenuHeight={150}
          components={{
            IndicatorSeparator: () => null
          }}
          onChange={handleChange}
          styles={customStyles} />
      </div>
      <div className='match-container'>
        <div className='ml-8 mr-8 mt-12'>
          <div className='match-information'>
            <div className='match'>TIME</div>
            <div className='match'>MATCH</div>

          </div>
          <div className='no-match'>
            {
              games.length === 0 ? <p className='no-match-message'>Coming Soon!</p> : null
            }
          </div>
          {games.map((game) => renderMatch(game))}
        </div>
      </div>
    </div>
  )
}
