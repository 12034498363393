import { ErrorMsg } from './ErrorMessage'
import { LabelForm } from './LabelForm'

import { Subtitle } from './Subtitle'
import { mdToHtml, TextField } from "./helpers";
import { fieldPropsInterfaceOverride, MultiBoolOptionType } from '../types';
import { optionFieldInterface } from '../../../common/VatomInc';

export const MultiBoolField = (props: fieldPropsInterfaceOverride) => {


	const value = props.value ?? []

	const onChangeOption = (option: string, checked: boolean) => {
		// Quirk: Jingle Ball 2022
		if (props.field.name.startsWith("jingleball_content_") && checked) {
			if (option === "undisclosed") props.onChange(["undisclosed"]);
			else
				props.onChange(
					Array.from(
						new Set([...value.filter(($: any) => $ !== "undisclosed"), option]),
					),
				);
			return;
		}

		if (checked) props.onChange(Array.from(new Set([...value, option])));
		else props.onChange(value.filter(($: any) => $ !== option));
	};


	return (
		<div>
			<LabelForm {...props} />
			<Subtitle {...props} />
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
					rowGap: '0.3rem',
				}}
			>
				{props.field.options?.map(($: optionFieldInterface, idx) => (
					<MultiBoolOption
						num={idx}
						key={$.value}
						field={props.field}
						option={$}
						checked={value.includes($.value)}
						onChange={(checked: any) => onChangeOption($.value, checked)}
					/>
				))}
			</div>
			<ErrorMsg {...props} />
		</div>
	)
}



const MultiBoolOption = (props: MultiBoolOptionType) => {

	return (
		<div style={{ display: 'flex', fontSize: '12px', alignItems: "center", marginTop: "5px" }}>
			<TextField
				type="checkbox"
				checked={props.checked}
				onChange={(e: any) => props.onChange(e.target.checked)}
				id={props.option.title + props.num}
			/>
			<span className="checkmark">
				<i className="far fa-check"></i>
			</span>

			<label
				htmlFor={props.option.title + props.num}
				dangerouslySetInnerHTML={{
					__html: mdToHtml(props.option.title ?? props.option.value),
				}}
				style={{
					flex: 1,
					marginLeft: '0.5rem',
					fontFamily: "Gesta RG",
					fontSize: "12px",
					fontWeight: "400",
					lineHeight: "1.33",
					fontStretch: "normal",
					fontStyle: "normal",
					letterSpacing: "-0.24px",
					color: "var(--color-text-normal)",
					margin: 0,
					padding: 0,
					textAlign: "left"
				}}
			/>


		</div>
	)
}
