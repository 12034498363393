import './lineup-single.styles.scss'
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import PageHeader from '../../components/page-header/page-header.component';
import MotionDiv from '../../components/motion-div/motion-div.component';

const LineupSinglePage = ({lineupElements}) => {
    const params = useParams();
    const index = lineupElements.findIndex(object => object.slug === params.slug);

    return (
        <div className='lineup-single-page'>
            
            <PageHeader title="Artist Bio"/>

            <div className='page-content'>
                <Swiper
                slideToClickedSlide={true}
                spaceBetween={23}
                slidesPerView={1}
                onSwiper={(swiper) => swiper.slideTo(index)}
                >
                {
                lineupElements.map((element) => (
                    <SwiperSlide key={element.title}>
                        <div className='image-holder' style={{backgroundImage: `url("${element.acf.large_image.url}")`}}>
                        </div>
                        <h4>{element.title}</h4>
                        <p dangerouslySetInnerHTML={{__html: element.post_content}}/>
                    </SwiperSlide>
                ))
                }
                </Swiper>
            </div>

        </div>
    )
}

export default MotionDiv(LineupSinglePage)
