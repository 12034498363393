import App from "./App"

const Deloitte = () => {

	return (
		<App />
	)
};

export default Deloitte;
