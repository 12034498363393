import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { selectTicketsCheck, selectGuideCheck, selectFaqCheck, selectArtistsCheck, selectOfficialNftCheck } from '../../redux/posts/posts.selectors';

import SwiperHolder from '../swiper/swiper-holder.component';

const EventsModule = ({ticketsCheck, guideCheck, faqCheck, artistsCheck, officialNftCheck}) => {

    const events = (title, post_thumbnail, url) => {
        return {
            type: "event",
            title,
            post_thumbnail,
            url: `/b/wango-tango${url}`
        }
    }

    const eventNavButtons = []

    if ( ticketsCheck ) {
        let ticket = events('Tickets', "http://cms.vatom.com/wp-content/uploads/2022/05/1.jpg", '/tickets')
        eventNavButtons.splice(0, 0, ticket)
    }
    if ( guideCheck ) {
        let guide = events('Guide', "http://cms.vatom.com/wp-content/uploads/2022/05/2.jpg", '/guide')
        eventNavButtons.splice(1, 0, guide)
    }
    if ( faqCheck ) {
        let faq = events('FAQ', "http://cms.vatom.com/wp-content/uploads/2022/05/4.jpg", '/faq')
        eventNavButtons.splice(3, 0, faq)
    }
    if ( artistsCheck ) {
        let arCheck = events('Lineup', "http://cms.vatom.com/wp-content/uploads/2022/05/ViPX-Button-Images-11.png", '/lineup')
        eventNavButtons.splice(2, 0, arCheck)
    }
    if ( officialNftCheck ) {
        let nftCheck = events('NFT', "http://cms.vatom.com/wp-content/uploads/2022/05/5-1.jpg", '/nft')
        eventNavButtons.splice(4, 0, nftCheck)
    }

    return (
        <div className='module' key="3">
            <SwiperHolder spaceBetween={18} slidesPerView={2} elements={eventNavButtons} title="The Event"/>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({   //state = the root reducer
    ticketsCheck: selectTicketsCheck,
    guideCheck: selectGuideCheck,
    faqCheck: selectFaqCheck,
    artistsCheck: selectArtistsCheck,
    officialNftCheck : selectOfficialNftCheck
})

export default connect(mapStateToProps)(EventsModule)