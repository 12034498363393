import { Redirect, useHistory } from "react-router";
import { useEffect, useState } from "react";
import HexButton from "../../../components/hex-button/HexButton";
import "./homenew.styles.scss";
import MotionDiv from "../../../components/motion-div/motion-div.component";
import { useCampaignUserInfo, useUpdateUserInfo, useUser } from "../../../../../auth";
import Loader from "../../../../../components/Loader";
import { useBusiness } from "../../../../../App";
import { useAutoOpen } from "../../../../../hooks/business";
import Analytics from "../../../../../common/Analytics";
import VatomInc from "../../../../../common/VatomInc";
// import { getUrlParameter } from "../../../../../common/Utils";
import { useInventory } from "../../../../../react-sdk";
import Errors from "../../../../../common/Errors";

const HomeNew = () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const business = useBusiness();
  const inventory = useInventory();

  useAutoOpen();
  const businessId = business!.id;
  const campaignId = business!.defaultCampaignId;
  const updateUserInfo = useUpdateUserInfo({ businessId, campaignId });
  const [entries, setEntries] = useState(0);
  const objectDefinitionId = 'KKu9MlPV0Q'


  const openFifa = () => {
    const url = "https://www.fifa.com/fifaplus/en/tournaments/mens/worldcup/qatar2022";

    Analytics.event("openURL", {
      url,
      businessId,
      campaignId,
    });

    window.open(url, "_blank");
  };

  const resetWizard = () => {
    updateUserInfo({ form_submitted: null, pepsi_team: null });
    localStorage.setItem("formOk", "");
    localStorage.setItem("wizardState", "0");
  }

  const handleRouting = (route: any) => {
    history.push(route);
  };

  const { data: userInfo, isValidating } = useCampaignUserInfo();

  const user = useUser();

  useEffect(() => {
    if (business) {
      VatomInc.getUserPoints(user.profile.sub, business?.defaultCampaignId).then(
        (res) => {
          setEntries(res.entries);
        }
      );
    }
  }, [])

  useEffect(() => {
    setLoading(isValidating)
  }, [isValidating])


  if (loading)
    return (
      <div className="home-page customheight">
        <div className="page-wrapper">
          <Loader isLoading={loading} />
        </div>
      </div>
    );

  let showCamera = "true";
  let showBall = "true";
  let showGames = "false";

  if (userInfo.picture) {
    showCamera = "false";
  }
  if (!userInfo?.pepsi_address?.country || userInfo?.pepsi_address?.country === "United States of America") {
    showGames = "true";
  }

  // if (!userInfo.pepsi_team)
  //   return <Redirect to="/b/lays/countries/select" />;

  //DELETE entries>1 to redirect to form always
  // if (!userInfo.form_submitted && localStorage.getItem("formOk") === "tocheck" && entries > 1)
  //   return <Redirect to={"/b/lays/form"} />;

  localStorage.setItem("wizardState", ' ')
  // const wizardState = localStorage.getItem("wizardState");
  // if (wizardState && wizardState != " ") {
  //   const wizzardSteps = [];
  //   wizzardSteps[0] = "/b/lays/countries/select";
  //   wizzardSteps[1] = "/b/lays/prize/play";
  //   wizzardSteps[2] = "/b/lays/selfie/before";
  //   wizzardSteps[3] = "/b/lays/pass/1";
  //   wizzardSteps[4] = "/b/lays/pre-daily-game";
  //   wizzardSteps[5] = "/b/lays/explore/landing";
  //   wizzardSteps[6] = "/b/lays/explore/landing";

  //   return <Redirect to={`${wizzardSteps[Number(wizardState)]}`} />;
  // }
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  const claimNFT = async () => {
    // objectDefinitionId=KKu9MlPV0Q
    console.log(user)
    const tokenToID = user.access_token
    console.error(tokenToID)

    const found = inventory.find(t => t.studioInfo?.objectDefinitionId === objectDefinitionId)
    console.log(found)
    if (found) {
      console.error("GASIT")
      history.push(`/b/lays/token/${found.id}`)
    }
    else {
      setLoading(true)
      try {
        const response = await fetch('https://distribution.api.vatominc.com/me/get-flna/ewogICAgImNhbXBhaWduSWQiOiAiOWxleHJDRmFHdiIsCiAgICAib2JqZWN0RGVmaW5pdGlvbklkIjogIktLdTlNbFBWMFEiCn0=', {
          method: "GET",
          headers: { "Authorization": `Bearer ${tokenToID}` }
        })
        if (!response.ok) {
          // Throw error
          const json = await response.json()
          Errors.show(new Error(json.error?.data?.message || "An unknown error occurred. Please try again later."))
        }
        handleRouting("/b/lays/wallet")
      } catch (err) {
        Errors.show(err)
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <div className="home-page homeCustomHeight">
      <div className="page-wrapper">
        <div className="grid-container pl-23 pr-23 mt-20 mb-50">
          {/* <HexButton
            onClick={() => handleRouting("/b/lays/explore/animation")}
            className="mt-15"
            show={showBall}
            title="FOLLOW THE BALL"
            customClass="mw-128 top-10"
            icon="follow-the-ball-bg2.png"
            width="160"
            height="167"
          /> */}
          <HexButton
            onClick={() => claimNFT()}
            className="mt-15"
            // show={showBall}
            title="CLAIM MY NFT"
            customClass="mw-128 top-10"
            icon="follow-the-ball-bg2.png"
            width="160"
            height="167"
          />

          {/* <HexButton
            className="mt-15"
            show={showCamera}
            title="JOIN THE BALL"
            customClass="negativeLeft-5 mw-130 top-17 pl-5"
            icon="camera-icon.png"
            width="160"
            height="167"
          /> */}

          {/* <HexButton
            onClick={() => handleRouting("/b/lays/pass/2")}
            className="mt-15"
            title="PASS THE BALL"
            icon="pass-the-ball-bg3.png"
            width="160"
            customClass="mw-150 top-13"
            height="167"
          /> */}

          {/* <HexButton
            onClick={() => handleRouting("/b/lays/daily-game")}
            className="mt-15"
            title="DAILY GAME"
            //Comment this line to see games regardless of location
            show={showGames}
            customClass="mw-90 top-25"
            icon="daily-game2.png"
            width="160"
            height="167"
          /> */}

          {/* <HexButton
            onClick={() => handleRouting("/b/lays/prizes")}
            className="mt-15"
            title="PRIZES"
            customClass="hex-btn-img pl-20 mw-200 top-0 negativeLeft-35"
            icon="prizes-icon.png"
            width="160"
            height="167"
          /> */}

          <HexButton
            onClick={() => handleRouting("/b/lays/faq")}
            className="mt-15"
            title="FAQ"
            customClass="mw-115 top-15"
            icon="faq-icon.png"
            width="160"
            height="167"
          />

          {/* <HexButton
            onClick={() => handleRouting("/b/lays/info")}
            className="mt-15"
            title="INFO"
            customClass="mw-100 top-22"
            icon="cup-icon2.png"
            width="160"
            height="167"
          /> */}

          <HexButton
            onClick={() => openFifa()}
            className="mt-15"
            title="FIFA+"
            customClass="mw-110 top-58 pl-5"
            icon="fifa-icon.png"
            width="160"
            height="167"
          />

        </div>
      </div>
    </div>
  );

};
export default MotionDiv(HomeNew);
